// @format
import React, { Component, Fragment } from 'react'
import { uuid } from 'uuidv4'

// Third libs
import { uniqueId } from 'lodash'
import moment from 'moment'
import swal from '@sweetalert/with-react'

// Material-UI core components
import { withStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import Receipt from '@material-ui/icons/Receipt'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import InputAdornment from '@material-ui/core/InputAdornment'
import DateRange from '@material-ui/icons/DateRange'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'

// Material-UI icons
import Backspace from '@material-ui/icons/Backspace'
import Send from '@material-ui/icons/Send'

// Custom Components
import Menu from '../../../../components/Menu'
import { formatCurrency } from '../../../../components/Helpers'
import Select from '../../../../components/Select'
import api from '../../../../config/Api'
import Acordo from './Modais/Acordo'

// Constants
import { url } from '../../../../config/Api'

const formasPagamento = [
  {
    id: 1,
    name: 'Dinheiro',
  },
  {
    id: 2,
    name: 'Crédito',
  },
  {
    id: 3,
    name: 'Débito',
  },
  {
    id: 4,
    name: 'Boleto Bancário',
  },
]

const vencimento = [
  {
    id: 0,
    name: 'Dia do vencimento',
  },
  {
    id: 1,
    name: 1,
  },
  {
    id: 5,
    name: 5,
  },
  {
    id: 10,
    name: 10,
  },
  { id: 15, name: 15 },
  { id: 20, name: 20 },
  { id: 25, name: 25 },
  { id: 30, name: 30 },
]

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#4e4e4e',
  },
  content: {
    flexGrow: 1,
    paddingLeft: 20,
    paddingRight: 20,
    height: '100vh',
    overflow: 'auto',
  },
  flex: {
    flex: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  label: {
    color: '#fff !important',
  },
  override: {
    padding: '10px !important',
  },
  pending: {
    border: '1px solid #ffc107',
  },
  paid: {
    border: '1px solid #4caf50',
  },
  canceled: {
    border: '1px solid #ff1744',
  },
  partially_paid: {
    border: '1px solid #651fff',
  },
  refunded: {
    border: '1px solid #2979ff',
  },
  expired: {
    border: '1px solid #333333',
  },
  authorized: {
    border: '1px solid #00bcd4',
  },
})

class NegociacaoView extends Component {
  state = {
    open: false,
    qtdParcela: 0,
    vencimento: 0,
    negociacao_online: false,
    valorAcordado: 0,
    qtd: [],
    debito: this.props.history.location.state,
    parcelas: [
      {
        ordem: 1,
        tipo: 'E',
        valor: 0,
        forma_pagamento: 1,
        status: 'pending',
        url: '',
        id_invoice: '',
        vencimento: moment().format('YYYY-MM-DD'),
      },
    ],
    forma_pagamento: '',
    forma_parcelas: '',
    juros: 0,
    mostraTermo: false,
    termo: '',
  }

  componentWillMount() {
    this.generateQtd()
  }

  componentDidMount() {}

  mostraForma(value) {
    switch (value) {
      case 1:
        return 'Dinheiro'
      case 2:
        return 'Crédito'
      case 3:
        return 'Débito'
      case 4:
        return 'Boleto Bancário'
      default:
        return 'Dinheiro'
    }
  }

  generateQtd() {
    for (
      var i = 0;
      i < this.state.debito.remessa.limite_parcelamento_empresa;
      i++
    ) {
      this.state.qtd.push({
        id: i + 1,
        name: i + 1,
      })
    }

    this.forceUpdate()
  }

  checaTipo = tipo => {
    if (tipo === 'E') {
      return 'Entrada'
    } else {
      return 'Parcela'
    }
  }

  handleEntrada = event => {
    let parcela = this.state.parcelas[0]
    parcela.valor = parseFloat(event.target.value)
    this.forceUpdate()
  }

  handleDataEntrada = event => {
    let parcela = this.state.parcelas[0]
    parcela.vencimento = event.target.value
    this.forceUpdate()
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleCheck = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  // Don't touch this, its magic...
  financiamento(valor_financiado, juros, parcelas) {
    if (juros === 0 || juros === '') {
      const prestacao = valor_financiado / parcelas
      return prestacao.toFixed(2)
    } else {
      const prestacao =
        valor_financiado *
        (((juros / 100) * (1 + juros / 100) ** parcelas) /
          ((1 + juros / 100) ** parcelas - 1))
      return prestacao.toFixed(2)
    }
  }

  reset() {
    this.setState({
      open: true,
      qtdParcela: 0,
      vencimento: 0,
      negociacao_online: false,
      valorAcordado: 0,
      mostraTermo: false,
      qtd: [...this.state.qtd],
      parcelas: [
        {
          ordem: 1,
          tipo: 'E',
          valor: 0,
          forma_pagamento: 1,
          status: 'pending',
          url: '',
          id_invoice: '',
          vencimento: moment().format('YYYY-MM-DD'),
        },
      ],
      forma_pagamento: 1,
      juros: 0,
    })
  }

  handleStatus(status) {
    const obj = {
      pending: 'Pendente',
      paid: 'Pago',
      canceled: 'Cancelada',
      partially_paid: 'Parcialmente Paga',
      refunded: 'Estornada',
      expired: 'Vencida',
      authorized: 'Autorizado',
    }
    return obj[status]
  }

  async handleAddSemEntrada() {
    let counter = this.state.qtdParcela
    for (var i = 0; i < counter; i++) {
      const valorAcordado = this.state.valorAcordado
      const valor = this.financiamento(
        valorAcordado,
        this.state.juros,
        this.state.qtdParcela
      )
      this.state.negociacao_online
        ? this.state.parcelas.push({
            order: i + 1 + 1,
            tipo: i === 0 ? 'E' : 'P',
            valor: valor,
            forma_pagamento: this.state.forma_parcelas,
            status: 'pending',
            url: '',
            id_invoice: '',
            vencimento: moment()
              .set('date', this.state.parcelas[0].vencimento)
              .add(i + 1, 'month')
              .format('YYYY-MM-DD'),
          })
        : this.state.parcelas.push({
            order: i + 1 + 1,
            tipo: i + 1 + 1 === 1 ? 'E' : 'P',
            valor: valor,
            forma_pagamento: this.state.forma_parcelas,
            status: 'pending',
            url: '',
            id_invoice: '',
            vencimento: moment()
              .set('date', this.state.vencimento)
              .add(i + 1, 'month')
              .format('YYYY-MM-DD'),
          })
      await this.forceUpdate()
    }
    this.state.parcelas.splice(0, 1)
    this.state.parcelas[0].tipo = 'E'
    await this.forceUpdate()
    console.log(this.state.parcelas)
    await this.updateNegociacao(this.state)
    await this.setState({ status: 'aguardando_aceite' })
  }

  handleParcelas() {
    this.state.entrada ? this.handleAdd() : this.handleAddSemEntrada()
  }

  async handleAdd() {
    let counter = this.state.qtdParcela
    for (var i = 0; i < counter; i++) {
      const valorAcordado =
        this.state.valorAcordado - this.state.parcelas[0].valor
      const valor = this.financiamento(
        valorAcordado,
        this.state.juros,
        this.state.qtdParcela
      )
      this.state.negociacao_online
        ? this.state.parcelas.push({
            order: i + 1 + 1,
            tipo: i + 1 + 1 === 1 ? 'E' : 'P',
            valor: valor,
            forma_pagamento: this.state.forma_parcelas,
            status: 'pending',
            url: '',
            id_invoice: '',
            vencimento: moment()
              .set('date', this.state.parcelas[0].vencimento)
              .add(i + 1, 'month'),
          })
        : this.state.parcelas.push({
            order: i + 1 + 1,
            tipo: i + 1 + 1 === 1 ? 'E' : 'P',
            valor: valor,
            forma_pagamento: this.state.forma_parcelas,
            status: 'pending',
            url: '',
            id_invoice: '',
            vencimento: moment()
              .set('date', this.state.vencimento)
              .add(i + 1, 'month'),
          })
      await this.forceUpdate()
    }
    await this.updateNegociacao(this.state)
    await this.setState({ status: 'aguardando_aceite' })
  }

  async salvaNegociacao(e) {
    try {
      const { limite_parcelamento_empresa } = this.state.debito.remessa

      swal(
        'Negociação Fechada!',
        'Está negociação foi fechada. Um link para acessar o termo de aceite foi gerado e enviado para o email do devedor. A negociação só vai ser considerada concretizada, após o aceite do termo e o pagamento da entrada!',
        'success'
      )
      const obj = {
        assinaturas: [
          {
            pessoa_id: this.state.debito.divida.pessoa_devedor_id,
            nome: this.state.debito.devedor.nome_razao_social,
            tipo: 'devedor',
            uuid: uuid(),
            status: 'pendente',
            assinado_dia: '',
            assinado_hora: '',
            timestamp: '',
            auditoria: {},
          },
          {
            pessoa_id: 213,
            nome: 'Pedro Paulo e Silva Freire',
            tipo: 'gestor',
            uuid: uuid(),
            status: 'assinado',
            assinado_dia: moment().format('DD/MM/YYYY'),
            assinado_hora: moment().format('HH:mm'),
            timestamp: moment().format('DD/MM/YY HH:mm'),
            auditoria: {},
          },
        ],
        criado_em: this.state.debito.negociacao.criado_em,
        criado_por: this.state.debito.negociacao.criado_por,
        divida_id: this.state.debito.divida.id,
        id: this.state.debito.negociacao.id,
        status: 'aguardando_aceite',
        pessoa_devedor_id: this.state.debito.divida.pessoa_devedor_id,
        atualizacao_divida: this.state.debito.negociacao.recurso
          .atualizacao_divida,
        negociacao: {
          uuid: this.state.debito.negociacao.recurso.negociacao.uuid,
          divida_id: this.state.debito.divida.id,
          presencial: false,
          dia_vencimento: e.vencimento,
          parcelas_limite: limite_parcelamento_empresa,
          entrada: e.entrada,
          dataEntrada: e.dataEntrada,
          formaPagamentoEntrada: e.formaPagamentoEntrada,
          formaPagamentoParcela: e.formaPagamentoParcela,
          juros: e.juros,
          parcelas: e.parcelas,
          qtdParcelas: e.qtdParcelas,
          valorAcordado: parseFloat(e.valorAcordado).toFixed(2),
        },
      }

      await api.put(
        `/api/v1/negociacoes/${this.state.debito.negociacao.id}`,
        obj
      )
      await this.setState({ modalNegociacao: false })
    } catch (err) {
      console.warn(err)
    }
    // console.log(JSON.stringify(obj));
    this.props.history.push(
      `/cliente/${this.state.debito.remessa.pessoa_cliente_id}/remessa/${
        this.state.debito.remessa.id
      }/divida/${this.state.debito.divida.id}`
    )
  }

  async updateNegociacao(e) {
    const { limite_parcelamento_empresa } = this.state.debito.remessa
    const obj = {
      criado_em: this.state.debito.negociacao.criado_em,
      criado_por: this.state.debito.negociacao.criado_por,
      divida_id: this.state.debito.divida.id,
      id: this.state.debito.negociacao.id,
      status: 'parcelas_criadas',
      pessoa_devedor_id: this.state.debito.divida.pessoa_devedor_id,
      atualizacao_divida: this.state.debito.negociacao.recurso
        .atualizacao_divida,
      negociacao: {
        uuid: this.state.debito.negociacao.recurso.negociacao.uuid,
        divida_id: this.state.debito.divida.id,
        presencial: false,
        dia_vencimento: e.vencimento,
        parcelas_limite: limite_parcelamento_empresa,
        entrada: parseFloat(e.entrada),
        dataEntrada: e.dataEntrada,
        formaPagamentoEntrada: e.formaPagamentoEntrada,
        formaPagamentoParcela: e.formaPagamentoParcela,
        juros: parseFloat(e.juros),
        parcelas: e.parcelas,
        qtdParcelas: e.qtdParcelas,
        valorAcordado: parseFloat(e.valorAcordado),
      },
    }

    // alert(JSON.stringify(obj));
    await api.put(`/api/v1/negociacoes/${this.state.debito.negociacao.id}`, obj)
    const negociacao = await api.get(
      `/api/v1/negociacoes/${this.state.debito.negociacao.id}`
    )
    this.setState(
      {
        ...this.state,
        mostraTermo: true,
        debito: {
          ...this.state.debito,
          negociacao: negociacao.data.data,
        },
      },
      () => this.getTermo()
    )
    // console.log(JSON.stringify(obj));
  }

  async getTermo() {
    try {
      const termo = await api.get(
        `/api/v1/negociacoes/${
          this.state.debito.negociacao.id
        }/termo?format=html`
      )
      this.setState({ termo: termo.data })
    } catch (error) {
      console.warn(error)
    }
  }
  render() {
    const { classes } = this.props
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} style={{ marginTop: 20 }} />
            <AppBar position="static" className={classes.appBar}>
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  Negociação -{' '}
                  {formatCurrency(
                    this.state.debito.negociacao.recurso.atualizacao_divida
                      .divida_atualizada
                  )}
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.negociacao_online}
                      onChange={this.handleCheck('negociacao_online')}
                      color="secondary"
                      value="checkedA"
                    />
                  }
                  label="Negociação Presencial?"
                  classes={{ label: classes.label }}
                />

                {this.state.mostraTermo ? (
                  <Acordo termo={this.state.termo} />
                ) : null}

                {this.state.debito.negociacao.recurso.status ===
                'parcelas_criadas' ? (
                  <Button
                    color="primary"
                    variant="contained"
                    // disabled={
                    // 	this.state.parcelas[0].valor === 0 ||
                    // 	this.state.valorAcordado === 0 ||
                    // 	this.state.qtdParcela === "" ||
                    // 	this.state.vencimento === 0 ||
                    // 	this.state.parcelas.length === 1
                    // 		? true
                    // 		: false
                    // }
                    onClick={() => this.salvaNegociacao(this.state)}
                  >
                    <Receipt style={{ marginRight: 5 }} /> Fechar Acordo
                  </Button>
                ) : null}
                {this.state.debito.negociacao.recurso.status ===
                'aguardando_aceite' ? (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={true}
                    style={{ color: '#fff' }}
                  >
                    <Receipt style={{ marginRight: 5, color: '#fff' }} />{' '}
                    Aguardando Aceite
                  </Button>
                ) : null}
                {this.state.debito.negociacao.recurso.status ===
                'acordo_aceito' ? (
                  <Button color="primary" variant="contained">
                    <Receipt style={{ marginRight: 5 }} /> Acordo Fechado
                  </Button>
                ) : null}
              </Toolbar>
            </AppBar>
            <Grid
              container
              style={{ paddingLeft: 20, paddingTop: 20, margin: 0 }}
            >
              <Grid item lg={6}>
                <Grid container spacing={24}>
                  <Grid item md={4}>
                    <TextField
                      label="Valor Acordado"
                      onChange={e => this.handleInputChange(e)}
                      value={this.state.valorAcordado}
                      type="number"
                      name="valorAcordado"
                      id="simple-start-adornment"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      fullWidth
                      name="juros"
                      value={this.state.juros}
                      onChange={e => this.handleInputChange(e)}
                      type="number"
                      label="Juros da Negociação"
                      id="simple-start-adornment"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      select
                      onChange={e =>
                        this.setState({
                          ...this.state,
                          entrada: e.target.value,
                        })
                      }
                      value={this.state.entrada}
                      label="Com entrada?"
                      fullWidth
                      margin="none"
                    >
                      <MenuItem value={true}>Sim</MenuItem>
                      <MenuItem value={false}>Não</MenuItem>
                    </TextField>
                  </Grid>

                  {this.state.entrada && (
                    <>
                      <Grid item lg={12}>
                        <Typography
                          variant="h6"
                          color="inherit"
                          className={classes.flex}
                        >
                          Entrada
                        </Typography>
                      </Grid>

                      <Grid item md={5}>
                        <TextField
                          fullWidth
                          label="Valor da entrada"
                          name="entrada"
                          type="number"
                          value={this.state.parcelas[0].valor}
                          onChange={this.handleEntrada.bind(this)}
                          id="simple-start-adornment"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item lg={5}>
                        <TextField
                          fullWidth
                          onChange={this.handleDataEntrada.bind(this)}
                          label="Data da entrada"
                          value={this.state.parcelas[0].vencimento}
                          type="date"
                          id="simple-start-adornment"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DateRange />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {this.state.negociacao_online ? (
                    <Grid item md={4}>
                      <Select
                        array={formasPagamento}
                        name="forma_pagamento"
                        helper="Formas de Pagamento"
                        default="Selecione"
                        label="Forma de Pagamento"
                        value={this.state.forma_pagamento}
                        change={e => this.handleInputChange(e)}
                      />
                    </Grid>
                  ) : null}

                  <Grid item lg={12}>
                    <Typography variant="h6" color="inherit">
                      Parcelamento
                    </Typography>
                  </Grid>
                  <Grid item lg={4}>
                    <TextField
                      select
                      onChange={e => this.handleInputChange(e)}
                      value={this.state.qtdParcela}
                      name="qtdParcela"
                      label="Quantidade de Parcelas"
                      fullWidth
                      margin="none"
                    >
                      {this.state.qtd.map(option => (
                        <MenuItem key={uniqueId()} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={4}>
                    <Select
                      array={vencimento}
                      value={this.state.vencimento}
                      label="Dia do Vencimento"
                      name="vencimento"
                      change={e => this.handleInputChange(e)}
                      margin="none"
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <Select
                      array={formasPagamento}
                      value={this.state.forma_parcelas}
                      label="Forma de Pagamento"
                      name="forma_parcelas"
                      change={e => this.handleInputChange(e)}
                      margin="none"
                    />
                  </Grid>
                  <Grid item lg={12}>
                    {this.state.parcelas.length === 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          this.state.valorAcordado === 0 ||
                          this.state.qtdParcela === '' ||
                          this.state.vencimento === 0
                            ? true
                            : false
                        }
                        onClick={this.handleParcelas.bind(this, this.state)}
                      >
                        <Send style={{ marginRight: 5 }} /> Gerar Parcelas
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={this.reset.bind(this)}
                      >
                        <Backspace style={{ marginRight: 5 }} />
                        Resetar
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={6}
                style={{
                  paddingLeft: 5,
                  borderLeft: '1px solid #e4e4e4',
                  minHeight: '80vh',
                }}
              >
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                  style={{ marginLeft: 14, marginTop: 10 }}
                >
                  Parcelas
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.override}>
                        Parcela
                      </TableCell>
                      <TableCell className={classes.override}>Valor</TableCell>
                      <TableCell className={classes.override}>Status</TableCell>
                      <TableCell align="left" className={classes.override}>
                        Vencimento
                      </TableCell>
                      <TableCell align="left" className={classes.override}>
                        Forma de Pagamento
                      </TableCell>
                      <TableCell align="left" className={classes.override}>
                        Tipo
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.parcelas.map((parcela, index) => {
                      return (
                        <TableRow key={uniqueId()}>
                          <TableCell
                            className={classes.override}
                            component="th"
                            scope="row"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            className={classes.override}
                            component="th"
                            scope="row"
                          >
                            {formatCurrency(parcela.valor)}
                          </TableCell>
                          <TableCell
                            className={classes.override}
                            component="th"
                            scope="row"
                          >
                            <Chip
                              label={this.handleStatus(parcela.status)}
                              variant="outlined"
                              className={classes[parcela.status]}
                            />
                          </TableCell>
                          <TableCell className={classes.override} align="left">
                            {moment(parcela.vencimento).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell
                            className={classes.override}
                            component="th"
                            scope="row"
                          >
                            {this.mostraForma(parcela.forma_pagamento)}
                          </TableCell>
                          <TableCell className={classes.override} align="left">
                            {this.checaTipo(parcela.tipo)}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {this.state.forma_parcelas}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </main>
        </div>
      </Fragment>
    )
  }
}

export default withStyles(styles, { withTheme: true })(NegociacaoView)
