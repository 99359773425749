import api from "../../config/Api";
import moment from "moment";
import { GET_DEVEDOR, UPDATE_DEVEDOR, SEARCH_DEVEDORES } from "./constants";

export function getDevedor(match) {
  return async dispatch => {
    try {
      const request = await api.get(
        `/api/v1/pessoas/devedores/${match.params.id}`
      );
      dispatch(
        {
          type: GET_DEVEDOR,
          payload: request.data.data
        },
        console.log(request.data)
      );
    } catch (e) {
      return e;
    }
  };
}

export function updateDevedor(match, values) {
  values.nascimento = moment(values.nascimento).format("YYYY-MM-DD");
  return dispatch => {
    api.put(`/api/v1/pessoas/devedores/${match.params.id}`, values).then(resp =>
      dispatch({
        type: UPDATE_DEVEDOR,
        payload: resp.data
      })
    );
  };
}

export function searchDevedores(match) {
  return async dispatch => {
    try {
      const request = await api.get(`/api/v1/pessoas/devedores?q=${match}`);

      dispatch(
        {
          type: SEARCH_DEVEDORES,
          payload: request.data.data
        },
        console.log(request.data.data)
      );
    } catch (e) {
      return e;
    }
  };
}
