import React, { Fragment } from "react";
// Date utils
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { DatePicker } from "material-ui-pickers";
// Material-ui
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

// Componentes da Recred
import Bar from "../../components/Appbar";
import Menu from "../../components/Menu";
import FormBar from "../../components/FormBar/FormBar";

// Máscaras
import {
  phoneMask,
  telefoneFixoMask,
  inscricaoEstadualMask,
  cpfMask,
  cnpjMask,
  rgMask
} from "../../components/Mascaras";

// Redux e ReduxForm
import { bindActionCreators } from "redux";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { getDevedor, updateDevedor } from "./actions";

// Importa os fields do ReduxForm
import {
  renderSwitchField,
  renderSelectField
} from "../../components/Fields/Fields";

import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

const sexo = [
  {
    value: "m",
    label: "Masculino"
  },
  {
    value: "f",
    label: "Feminino"
  }
];

class ClienteForm extends React.Component {
  componentDidMount() {
    let { match } = this.props;

    switch (this.props.match.path) {
      case "/editar-devedor/:id":
        this.props.getDevedor(match);
        break;
      case "/visualizar-devedor/:id":
        this.props.getDevedor(match);
        break;
      default:
        break;
    }
  }
  onSubmit = values => {
    let { match } = this.props;

    switch (match.path) {
      case "/editar-devedor/:id":
        this.props.updateDevedor(match, values);
        break;
      default:
        break;
    }

    this.props.history.push("/devedores");
  };
  renderSelectField = field => {
    const {
      meta: { touched, error }
    } = field;
    return (
      <TextField
        select
        label={field.label}
        fullWidth
        disabled={
          this.props.match.path === "/visualizar-devedor/:id" ? true : false
        }
        helpertext={touched ? error : ""}
        error={touched && error ? true : false}
        {...field.input} //capta as mudanças no input
      >
        {field.array.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={
              this.props.match.path === "/visualizar-devedor/:id" ? true : false
            }
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  };
  renderField = field => {
    const {
      meta: { touched, error }
    } = field;
    return (
      <TextField
        {...field.input}
        type={field.type}
        disabled={
          this.props.match.path === "/visualizar-devedor/:id" ? true : false
        }
        placeholder={field.placeholder}
        helperText={touched ? error : ""}
        error={touched && error ? true : false}
        label={field.label}
        fullWidth
      />
    );
  };

  renderDataField = field => {
    const {
      meta: { touched, error }
    } = field;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          error={touched && error ? true : false}
          keyboard
          helperText={touched ? error : ""}
          placeholder="Data"
          disabled={
            this.props.match.path === "/visualizar-devedor/:id" ? true : false
          }
          format="DD/MM/YYYY"
          label={field.label}
          {...field.input}
        />
      </MuiPickersUtilsProvider>
    );
  };

  render() {
    const { handleSubmit, classes, nome, pessoaFisica } = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <div style={{ marginBottom: 100 }}>
              <form onSubmit={handleSubmit(this.onSubmit)}>
                <Bar
                  title={
                    this.props.match.path === "/editar-cliente/:id"
                      ? `Editar Informações - ${nome}`
                      : nome
                        ? `Cadastrar Cliente - ${nome}`
                        : `Cadastrar Cliente`
                  }
                />
                <Paper elevation={1} style={{ borderRadius: "0" }}>
                  <FormBar nome="Informações Básicas" />
                  <Grid container spacing={24} style={{ padding: 20 }}>
                    <Grid item lg={12}>
                      <Field
                        label="Pessoa Física?"
                        name="tipo"
                        component={renderSwitchField}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        name="cpf_cnpj"
                        type="text"
                        component={this.renderField}
                        placeholder="Insira o CPF"
                        label={pessoaFisica ? "CPF" : "CNPJ"}
                        {...(pessoaFisica ? { ...cpfMask } : { ...cnpjMask })}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        name="rg_inscricao_estadual"
                        type="text"
                        component={this.renderField}
                        placeholder={
                          pessoaFisica
                            ? "Insira o RG"
                            : "Insira o número da Inscrição Estadual"
                        }
                        label={pessoaFisica ? "RG" : "Inscrição Estadual"}
                        {...(pessoaFisica
                          ? { ...rgMask }
                          : { ...inscricaoEstadualMask })}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        name="nome_razao_social"
                        type="text"
                        component={this.renderField}
                        label={pessoaFisica ? "Nome Completo" : "Razão Social"}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        name="apelido_nome_fantasia"
                        type="text"
                        component={this.renderField}
                        label={pessoaFisica ? "Apelido" : "Nome Fantasia"}
                      />
                    </Grid>

                    <Grid item lg={4}>
                      <Field
                        name="email"
                        type="text"
                        component={this.renderField}
                        label="Email"
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        name="telefone_celular"
                        type="text"
                        component={this.renderField}
                        label="Celular"
                        {...phoneMask}
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        name="telefone_fixo"
                        type="text"
                        component={this.renderField}
                        label="Telefone Fixo"
                        {...telefoneFixoMask}
                      />
                    </Grid>
                    {pessoaFisica ? (
                      <Grid item lg={4}>
                        <Field
                          name="sexo"
                          type="text"
                          component={renderSelectField}
                          array={sexo}
                          label="Sexo"
                        />
                      </Grid>
                    ) : null}
                    <Grid item lg={4}>
                      <Field
                        name="nascimento"
                        type="text"
                        component={this.renderDataField}
                        placeholder={
                          pessoaFisica
                            ? "Insira a data de nascimento"
                            : "Insira a data da fundação"
                        }
                        label={
                          pessoaFisica
                            ? "Data de Nascimento"
                            : "Data da Fundação"
                        }
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <Paper
                  elevation={1}
                  style={{ padding: 0, borderRadius: "0", marginTop: 50 }}
                >
                  <FormBar nome="Endereço" />
                  <Grid container spacing={24} style={{ padding: 20 }}>
                    <Grid item lg={4}>
                      <Field
                        name="endereco.cep"
                        type="text"
                        component={this.renderField}
                        label="CEP"
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        name="endereco.logradouro"
                        type="text"
                        component={this.renderField}
                        label="Rua"
                      />
                    </Grid>
                    <Grid item lg={2}>
                      <Field
                        name="endereco.numero"
                        type="text"
                        component={this.renderField}
                        label="Número"
                      />
                    </Grid>
                    <Grid item lg={2}>
                      <Field
                        name="endereco.complemento"
                        type="text"
                        component={this.renderField}
                        label="Complemento"
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        name="endereco.bairro"
                        type="text"
                        component={this.renderField}
                        label="Bairro"
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        name="endereco.localidade"
                        type="text"
                        component={this.renderField}
                        label="Cidade"
                      />
                    </Grid>
                    <Grid item lg={4}>
                      <Field
                        name="endereco.uf"
                        type="text"
                        component={this.renderField}
                        label="Estado"
                      />
                    </Grid>
                    <Grid />
                  </Grid>
                </Paper>

                <Paper
                  elevation={1}
                  style={{ padding: 0, borderRadius: "0", marginTop: 50 }}
                >
                  <Grid container spacing={24} style={{ padding: 30 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={
                        this.props.match.path === "/visualizar-devedor/:id"
                          ? true
                          : false
                      }
                    >
                      Salvar
                    </Button>
                    <Link
                      to="/devedores"
                      style={{ textDecoration: "none", marginLeft: 10 }}
                    >
                      <Button variant="contained" color="secondary">
                        Voltar
                      </Button>
                    </Link>
                  </Grid>
                </Paper>
              </form>
            </div>
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  }
});

function validate(values) {
  const errors = {};

  if (!values.cpf_cnpj) {
    values.pessoa_fisica
      ? (errors.cpf_cnpj = "É necessário inserir o CPF")
      : (errors.cpf_cnpj = "É necessário inserir o CNPJ");
  }

  // if (!values.rg_inscricao_estadual) {
  //   values.pessoa_fisica
  //     ? (errors.rg_inscricao_estadual = "É necessário inserir o RG ")
  //     : (errors.rg_inscricao_estadual =
  //         "É necessário inserir a Inscrição Estadual");
  // }

  // if (!values.nome_razao_social) {
  //   values.pessoa_fisica
  //     ? (errors.nome_razao_social = "É necessário inserir o Nome")
  //     : (errors.nome_razao_social = "É necessário inserir a Razão Social");
  // }

  // if (!values.apelido_nome_fantasia) {
  //   values.pessoa_fisica
  //     ? (errors.apelido_nome_fantasia = "É necessário inserir o Apelido")
  //     : (errors.apelido_nome_fantasia = "É necessário inserir o Nome Fantasia");
  // }

  // if (!values.email) {
  //   errors.email = "É necessário inserir o Email";
  // }

  // if (!values.telefone_celular) {
  //   errors.celular = "É necessário inserir o número do Celular";
  // }

  // if (!values.telefone_fixo) {
  //   errors.telefone_fixo = "É necessário inserir o número do Telefone Fixo";
  // }

  // if (!values.cep) {
  //   errors.cep = "É necessário inserir o número do CEP";
  // }

  // if (!values.logradouro) {
  //   errors.rua = "É necessário inserir o nome da Rua";
  // }
  // if (!values.bairro) {
  //   errors.bairro = "É necessário inserir o nome do Bairro";
  // }
  // if (!values.numero) {
  //   errors.numero = "É necessário inserir o número do enedereço";
  // }

  // if (!values.uf) {
  //   errors.estado = "É necessário inserir o nome do Estado";
  // }
  // if (!values.localidade) {
  //   errors.cidade = "É necessário inserir o nome da Cidade";
  // }

  return errors;
}

const selector = formValueSelector("devedorForm"); // <-- same as form name
function mapStateToProps(state) {
  return {
    cliente: state.clientes.cliente,
    initialValues: state.devedores.devedor,
    nome: selector(state, "nome_razao_social"),
    pessoaFisica: selector(state, "tipo"),
    enableReinitialize: true
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getDevedor, updateDevedor }, dispatch);
}

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      reduxForm({
        form: "devedorForm",
        destroyOnUnmount: true,
        validate
      })(ClienteForm)
    )
  )
);
