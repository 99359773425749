// @format

import React from 'react'
import Dropzone from 'react-dropzone'
import Api, { rota } from '../../../../../config/Api'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Attachment from '@material-ui/icons/Attachment'
import Slide from '@material-ui/core/Slide'
import swal from '@sweetalert/with-react'
import LinearProgress from '@material-ui/core/LinearProgress'

import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DeleteIcon from '@material-ui/icons/Delete'
import Avatar from '@material-ui/core/Avatar'
import CloudDownload from '@material-ui/icons/CloudDownload'
import { DropContainer, UploadMessage } from './styles'

const styles = {
	appBar: {
		position: 'relative',
		backgroundColor: '#4e4e4e',
	},
	flex: {
		flex: 1,
	},
}

function Transition(props) {
	return <Slide direction="up" {...props} />
}

class FullScreenDialog extends React.Component {
	state = {
		open: false,
		progresso: '',
		arquivos: [],
	}

	componentDidMount() {
		this.getArquivos()
		console.log('mounted')
	}

	renderDragMessage = (isDragActive, isDragReject) => {
		if (!isDragActive) {
			return <UploadMessage>Arraste arquivos aqui</UploadMessage>
		}
		if (isDragReject) {
			return <UploadMessage type="error">Arquivo não suportado</UploadMessage>
		}
		return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>
	}

	salvaAnexo() {
		let formData = new FormData()
		formData.append(`arquivo`, this.state.arquivo.arquivo)
		formData.append(`origem`, 'contrato')
		formData.append(`nome`, this.state.arquivo.nome)
		formData.append(`id_origem`, this.props.contratoId)
		console.log(...formData)
		Api.post(`/api/v1/arquivos`, formData).then(resp =>
			Api.get(
				`/api/v1/arquivos?origem=contrato&id_origem=${this.props.contratoId}`
			)
				.then(resp =>
					this.setState({ ...this.state, arquivos: resp.data.data })
				)
				.then(resp =>
					this.setState({
						...this.state,
						arquivo: { nome: '', arquivo: '', objectUrl: '' },
					})
				)
		)
	}

	async getArquivos() {
		try {
			const arquivos = await Api.get(
				`/api/v1/arquivos?origem=contrato&id_origem=${this.props.contratoId}`
			)
			this.setState({ ...this.state, arquivos: [...arquivos.data.data] })
			console.log(this.props.contratoId + 'contrato')
		} catch (err) {
			console.warn(err)
		}
	}

	handleClickOpen = () => {
		this.setState({ open: true })
	}

	handleClose = () => {
		this.setState({ open: false })
	}

	handleUploads = files => {
		let formData = new FormData()
		formData.append(`arquivo`, files[0])
		formData.append(`origem`, 'contrato')
		formData.append(`nome`, files[0].name)
		formData.append(`id_origem`, this.props.contratoId)
		console.log(...formData)
		Api.post(`/api/v1/arquivos`, formData, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100) / e.total))
				this.setState({ progresso: progress })
			},
		}).then(resp =>
			Api.get(
				`/api/v1/arquivos?origem=contrato&id_origem=${this.props.contratoId}`
			)
				.then(resp =>
					this.setState({ ...this.state, arquivos: resp.data.data })
				)
				.then(resp =>
					this.setState({
						...this.state,
						arquivo: { nome: '', arquivo: '', objectUrl: '' },
						progresso: 0,
					})
				)
		)
	}

	teste(index) {
		swal({
			title: 'Você tem certeza?',
			text: `Tem certeza que deseja deletar o arquivo ${
				this.state.arquivos[index].nome
			} ?`,
			buttons: ['Cancelar', 'Deletar'],
			icon: 'warning',
			dangerMode: true,
		}).then(willDelete => {
			if (willDelete) {
				const arquivos = [...this.state.arquivos]
				Api.delete(`/api/v1/arquivos/${this.state.arquivos[index].id}`)
				arquivos.splice(index, 1)
				this.setState({ ...this.state, arquivos: arquivos })
				swal(`Poof! O arquivo ${index} foi deletado com sucesso!`, {
					icon: 'success',
				})
			} else {
				swal('O arquivo não foi deletado!')
			}
		})
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<Button onClick={this.handleClickOpen}>
					<Attachment />
				</Button>
				<Dialog
					fullScreen
					open={this.state.open}
					onClose={this.handleClose}
					TransitionComponent={Transition}
				>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<IconButton
								color="inherit"
								onClick={this.handleClose}
								aria-label="Close"
							>
								<CloseIcon />
							</IconButton>
							<Typography variant="h6" color="inherit" className={classes.flex}>
								Pasta de Anexos
							</Typography>
							<Button color="inherit" onClick={this.handleClose}>
								Fechar
							</Button>
						</Toolbar>
					</AppBar>
					<Grid container>
						<Grid
							item
							lg={6}
							style={{ borderRight: '1px solid #e4e4e4', minHeight: '90vh' }}
						>
							<Typography variant="h5" gutterBottom style={{ padding: 20 }}>
								Adicionar Anexos
							</Typography>
							<Dropzone
								accept="application/pdf"
								onDropAccepted={this.handleUploads}
							>
								{({
									getRootProps,
									getInputProps,
									isDragActive,
									isDragReject,
								}) => (
									<DropContainer
										{...getRootProps()}
										isDragActive={isDragActive}
										isDragReject={isDragReject}
									>
										<input {...getInputProps()} />
										{this.renderDragMessage(isDragActive, isDragReject)}
									</DropContainer>
								)}
							</Dropzone>
							<Typography variant="h5" gutterBottom style={{ padding: 20 }}>
								{this.state.progresso}%
							</Typography>
							<LinearProgress
								variant="determinate"
								value={this.state.progresso}
							/>
						</Grid>
						<Grid item lg={6}>
							<Grid item lg={12}>
								<Typography variant="h5" gutterBottom style={{ padding: 20 }}>
									Lista de Anexos
								</Typography>
							</Grid>
							<List>
								{this.state.arquivos !== undefined ? (
									this.state.arquivos.map((arquivo, index) => {
										return (
											<ListItem button key={arquivo.id}>
												<ListItemAvatar>
													<Avatar>
														<Attachment />
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													primary={arquivo.nome}
													secondary={arquivo.cadastrado_por ? 'Nome' : ''}
												/>
												<ListItemSecondaryAction>
													<a
														href={`${rota}/${arquivo.arquivo}`}
														target="_blank"
														rel="noopener noreferrer"
													>
														<IconButton aria-label="Baixar">
															<CloudDownload />
														</IconButton>
													</a>
													<IconButton
														onClick={this.teste.bind(this, index)}
														aria-label="Delete"
														color="secondary"
													>
														<DeleteIcon />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										)
									})
								) : (
									<p>Nenhum anexo cadastrado!</p>
								)}
								<Divider />
							</List>
						</Grid>
					</Grid>
				</Dialog>
			</div>
		)
	}
}

FullScreenDialog.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FullScreenDialog)
