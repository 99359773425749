// @format
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import api from '../../config/Api'
import Primeiro from './Primeiro'
import Segundo from './Segundo'
import Terceiro from './Terceiro'
import Menu from '../../components/Menu'

const styles = theme => ({
  root: {
    display: 'flex',
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
})

function getSteps() {
  return ['Pesquisar devedor', 'Cadastro do Contrato', 'Cadastro da Dívida']
}

class Teste extends React.Component {
  state = {
    activeStep: 0,
    devedores: [],
    searchTerm: '',
    pessoa_devedor_id: '',
    contrato_id: '',
    numero: null,
    valorTotal: 0,
    mostraForm: false,
    arquivos: [],
  }

  componentDidMount() {
    let { match } = this.props
    switch (match.path) {
      case '/cliente/:clienteId/remessa/:remessaId/nova-divida':
        return null
      case '/cliente/:clienteId/remessa/:remessaId/editar-divida/:dividaId':
        return this.getDivida()
      default:
        break
    }
  }

  getDivida() {
    let { match } = this.props
    api
      .get(
        `/api/v1/pessoas/clientes/${match.params.clienteId}/remessas/${
          match.params.remessaId
        }/dividas/${match.params.dividaId}`
      )
      .then(
        resp =>
          this.setState(
            {
              ...this.state,
              ...resp.data.data,
              activeStep: 1,
            },
            () =>
              api
                .get(
                  `/api/v1/pessoas/clientes/${
                    match.params.clienteId
                  }/contratos/${this.state.contrato_id}`
                )
                .then(resp =>
                  this.setState(
                    {
                      ...this.state,
                      numero: resp.data.data.numero,
                    },
                    () => {
                      api
                        .get(
                          `/api/v1/arquivos?origem=contrato&id_origem=${
                            this.state.contrato_id
                          }`
                        )
                        .then(resp =>
                          this.setState({
                            ...this.state,
                            arquivos: resp.data.data,
                          })
                        )
                    }
                  )
                )
          )

        // console.log(this.state)
      )
  }

  selecionaDevedor(e, index) {
    console.log('Clicou')
    console.log(e)
    this.setState({
      ...this.state,
      pessoa_devedor_id: e,
      activeStep: 1,
      contrato_id: null,
      numero: null,
    })
  }

  mostraEstado() {
    console.log(this.state)
  }
  // Pega os valores do componente filho que tem no form e envia para o componente pai
  // e depois para a request pela variável e
  envia(e, index) {
    console.log(e)
    e.tipo = 2
    e.cpf_cnpj = e.cpf_cnpj.replace(/\.|-/gm, '')
    console.log('envia')
    api.post('/api/v1/pessoas/devedores', e).then(resp =>
      this.setState({
        ...this.state,
        devedor: resp.data.data,
        pessoa_devedor_id: resp.data.data.id,
        activeStep: this.state.activeStep + 1,
      })
    )
  }

  salvaDivida(e, index) {
    this.setState(
      { ...this.state, parcelas: e.parcelas, valorTotal: e.valorTotal },
      () => {
        let { match } = this.props
        let divida = {
          contrato_id: this.state.contrato_id,
          remessa_id: parseInt(match.params.remessaId),
          pessoa_cliente_id: parseInt(match.params.clienteId),
          pessoa_devedor_id: this.state.pessoa_devedor_id,
          descricao: this.state.descricao,
          parcelas: this.state.parcelas,
          status: 0,
          valor: this.state.valorTotal,
        }
        api
          .post(
            `/api/v1/pessoas/clientes/${match.params.clienteId}/remessas/${
              match.params.remessaId
            }/dividas`,
            divida
          )
          .then(resp =>
            this.props.history.push(
              `/cliente/${match.params.clienteId}/remessa/${
                match.params.remessaId
              }`
            )
          )
      }
    )

    // api
    //   .post(
    //     `/api/v1/pessoas/clientes/${match.params.clienteId}/remessas/${
    //       match.params.remessaId
    //     }/dividas`,
    //     divida
    //   )
    //   .then(resp => alert(JSON.stringify(resp.data.data)));
  }

  mandaParcelas(e, index) {
    console.log(e)
    console.log('Manda parcela')
    this.setState({ ...this.state, parcelas: e })
  }

  salvaContrato(e, index) {
    let { match } = this.props
    let dados = {
      ano: e.ano,
      pessoa_cliente_id: parseInt(match.params.clienteId),
      pessoa_devedor_id: this.state.pessoa_devedor_id,
    }
    api
      .post(
        `/api/v1/pessoas/clientes/${match.params.clienteId}/contratos`,
        dados
      )
      .then(resp =>
        this.setState({
          ...this.state,
          contrato_id: resp.data.data.contrato_id,
          numero: resp.data.data.numero,
        })
      )
  }

  escondeForm() {
    this.setState({ ...this.state, mostraForm: false })
  }

  searchDevedores(e, event, index, devedor = '') {
    event.preventDefault()
    this.setState({
      searchTerm: e.termo,
      pessoa_devedor_id: null,
      contrato_id: null,
      numero: null,
    })
    api.get(`/api/v1/pessoas/devedores?q=${e.termo}`).then(resp =>
      this.setState(
        { ...this.state, devedor, devedores: resp.data.data },
        () => {
          if (this.state.devedores.length === 0) {
            this.setState(
              { ...this.state, mostraForm: true },
              console.log(this.state)
            )
            console.log('Não achou nada')
          }
        }
      )
    )
  }

  getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Primeiro
            clique={this.selecionaDevedor.bind(this)}
            change={this.searchDevedores.bind(this)}
            termo={this.state.searchTerm}
            mostraForm={this.state.mostraForm}
            escondeForm={this.escondeForm.bind(this)}
            devedores={this.state.devedores}
            devedorId={this.state.pessoa_devedor_id}
            envia={this.envia.bind(this)}
            mostraEstado={this.mostraEstado.bind(this)}
          />
        )
      case 1:
        return (
          <Segundo
            numero={this.state.numero}
            rota={this.props.match.path}
            contratoId={this.state.contrato_id}
            salvaContrato={this.salvaContrato.bind(this)}
            arquivos={this.state.arquivos}
          />
        )
      case 2:
        return (
          <Terceiro
            clienteId={this.props.match.params.clienteId}
            remessaId={this.props.match.params.remessaId}
            valor={this.state.valor}
            dividaId={this.props.match.params.dividaId}
            manda={this.mandaParcelas.bind(this)}
            salvaDivida={this.salvaDivida.bind(this)}
          />
        )
      default:
        return 'Unknown stepIndex'
    }
  }

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }))
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleReset = () => {
    this.setState({
      activeStep: 0,
    })
  }

  render() {
    const { classes } = this.props
    const steps = getSteps()
    const { activeStep } = this.state

    return (
      <div className={classes.root}>
        <Menu />
        <main className={classes.content}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ marginTop: 50 }}
          >
            {steps.map(label => {
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
          <div>
            {this.state.activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
                <Button onClick={this.handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                {this.getStepContent(activeStep)}
                <div style={{ marginTop: 30 }}>
                  {activeStep === 0 ? null : (
                    <Button
                      disabled={activeStep === 0}
                      onClick={this.handleBack}
                      className={classes.backButton}
                    >
                      Voltar
                    </Button>
                  )}
                  {activeStep === 0 ? (
                    this.state.pessoa_devedor_id ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={
                          activeStep === steps.length - 1
                            ? null
                            : this.handleNext.bind(this)
                        }
                      >
                        {activeStep === steps.length - 1 ? 'Salvar' : 'Próximo'}
                      </Button>
                    ) : null
                  ) : null}
                  {activeStep === 1 ? (
                    this.state.contrato_id ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={
                          activeStep === steps.length - 1
                            ? null
                            : this.handleNext.bind(this)
                        }
                      >
                        {activeStep === steps.length - 1 ? 'Salvar' : 'Próximo'}
                      </Button>
                    ) : null
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </main>
      </div>
    )
  }
}

Teste.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(Teste)
