import React, { Component, Fragment } from "react";
import Menu from "../../components/Menu";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Appbar from "../../components/Appbar";
import List from "../../components/List";
import api from "../../config/Api";

class Grupos extends Component {
	state = {
		grupos: []
	};

	componentDidMount() {
		this.getGrupos();
	}

	async getGrupos() {
		try {
			const grupos = await api.get(`/api/v1/grupo`);
			this.setState({ ...this.state, grupos: grupos.data.data });
		} catch (err) {
			console.warn(err);
		}
	}
	render() {
		const { classes } = this.props;
		return (
			<Fragment>
				<div className={classes.root}>
					<div className={classes.root}>
						<Menu />
						<main className={classes.content}>
							<div className={classes.appBarSpacer} />
							<Appbar title="Grupos de Acesso" link="adicionar-grupo" />
							<Grid container style={{ marginTop: 20 }}>
								{this.state.grupos.map((grupo, index) => {
									return (
										<List
											lista={[{ key: "Nome", value: grupo.nome }]}
											edit={`/editar-grupo/${grupo.id}`}
										/>
									);
								})}
							</Grid>
						</main>
					</div>
				</div>
			</Fragment>
		);
	}
}

const styles = theme => ({
	root: {
		display: "flex",
		flexGrow: 1,
		minHeight: "100vh"
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing.unit * 3,
		height: "100vh",
		overflow: "auto"
	}
});

export default withStyles(styles)(Grupos);
