import React, { Component, Fragment } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import FormPaper from "../../../components/FormPaper/";
import Menu from "../../../components/Menu";
import api from "../../../config/Api";

// Material-UI
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

class RemessaForm extends Component {
	state = {
		limite_parcelamento_empresa: "",
		limite_desconto_empresa: "",
		limite_parcelamento_cliente: "",
		limite_desconto_cliente: "",
		dt_recebimento: moment().format("YYYY-MM-DD"),
		descricao: ""
	};

	componentDidMount() {
		if (this.props.match.params.remessaId) {
			this.getRemessas();
		}
	}

	async getRemessas() {
		const { clienteId, remessaId } = this.props.match.params;
		try {
			const remessas = await api.get(
				`/api/v1/pessoas/clientes/${clienteId}/remessas/${remessaId}`
			);
			this.setState({ ...this.state, ...remessas.data.data });
		} catch (err) {
			console.warn(err);
		}
	}

	async salvaRemessa(e) {
		e.preventDefault();

		try {
			const { clienteId, remessaId } = this.props.match.params;
			const obj = {
				...this.state,
				pessoa_cliente_id: parseInt(clienteId),
				limite_parcelamento_empresa: parseInt(
					this.state.limite_parcelamento_empresa
				),
				limite_desconto_empresa: parseInt(this.state.limite_desconto_empresa),
				limite_parcelamento_cliente: parseInt(
					this.state.limite_parcelamento_cliente
				),
				limite_desconto_cliente: parseInt(this.state.limite_desconto_cliente)
			};
			// Se houver um remessaId na url, enviar um PUT, caso contrário enviar
			// o POST
			if (remessaId) {
				await api.put(
					`/api/v1/pessoas/clientes/${clienteId}/remessas/${remessaId}`,
					obj
				);
				await this.props.history.push(`/cliente/${clienteId}`);
			} else {
				await api.post(`/api/v1/pessoas/clientes/${clienteId}/remessas`, obj);
				await this.props.history.push(`/cliente/${clienteId}`);
			}
		} catch (err) {
			console.warn(err);
		}
	}

	handleChange = event => {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	};

	render() {
		const { classes } = this.props;
		return (
			<Fragment>
				<div className={classes.root}>
					<div className={classes.root}>
						<Menu />
						<main className={classes.content}>
							<div className={classes.appBarSpacer} />
							<form onSubmit={this.salvaRemessa.bind(this)}>
								<FormPaper title="Informações">
									<Grid container spacing={24}>
										<Grid item lg={4}>
											<TextField
												fullWidth
												label="Descrição"
												name="descricao"
												value={this.state.descricao}
												helperText="Descrição da Remessa"
												onChange={this.handleChange}
											/>
										</Grid>
										<Grid item lg={3}>
											<TextField
												fullWidth
												type="date"
												label="Data de Recebimento"
												helperText="Dia que a remessa foi recebida"
												name="dt_recebimento"
												value={this.state.dt_recebimento}
												onChange={this.handleChange}
											/>
										</Grid>
									</Grid>
								</FormPaper>
								<FormPaper title="Recred - Opções de Negociação">
									<Grid container spacing={24}>
										<Grid item lg={3}>
											<TextField
												fullWidth
												label="Limite de Parcelamento"
												type="number"
												name="limite_parcelamento_empresa"
												helperText="Número máximo de parcelas"
												value={this.state.limite_parcelamento_empresa}
												onChange={this.handleChange}
											/>
										</Grid>
										<Grid item lg={3}>
											<TextField
												fullWidth
												label="Limite de Desconto"
												type="number"
												name="limite_desconto_empresa"
												helperText="Percentual máximo de desconto"
												value={this.state.limite_desconto_empresa}
												onChange={this.handleChange}
											/>
										</Grid>
									</Grid>
								</FormPaper>
								<FormPaper title="Cliente - Opções de Negociação">
									<Grid container spacing={24}>
										<Grid item lg={3}>
											<TextField
												fullWidth
												label="Limite de Parcelamento"
												type="number"
												name="limite_parcelamento_cliente"
												helperText="Número máximo de parcelas"
												value={this.state.limite_parcelamento_cliente}
												onChange={this.handleChange}
											/>
										</Grid>
										<Grid item lg={3}>
											<TextField
												fullWidth
												label="Limite de Desconto"
												type="number"
												name="limite_desconto_cliente"
												helperText="Percentual máximo de desconto"
												value={this.state.limite_desconto_cliente}
												onChange={this.handleChange}
											/>
										</Grid>
									</Grid>
								</FormPaper>
								<Grid container spacing={24} style={{ marginTop: 20 }}>
									<Grid item lg={12}>
										<Link
											to={`/cliente/${this.props.match.params.clienteId}`}
											style={{ textDecoration: "none" }}
										>
											<Button
												variant="contained"
												color="secondary"
												style={{ marginRight: 5 }}
											>
												Voltar
											</Button>
										</Link>
										<Button color="primary" type="submit" variant="contained">
											Salvar
										</Button>
									</Grid>
								</Grid>
							</form>
						</main>
					</div>
				</div>
			</Fragment>
		);
	}
}

const styles = theme => ({
	root: {
		display: "flex",
		flexGrow: 1,
		minHeight: "100vh"
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing.unit * 3,
		height: "100vh",
		overflow: "auto"
	}
});

export default withStyles(styles)(RemessaForm);
