import React, {Component, Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import axios from 'axios';
// import TermoItem from "./TermoItem";
import {formatCurrency} from '../../components/Helpers';
import {MeusAcordos, Body} from './styles';
import AcordoList from '../../components/AcordoList/';
import AcordoMenu from './AcordoMenu';

class nome extends Component {
  state = {
    termos: [],
    semTermo: false,
  };
  async componentDidMount() {
    await this.getTermos();
  }
  async getTermos() {
    const {match} = this.props;
    const termos = await axios.get(`/api/v1/negociacao/${match.params.cpf}`);
    if (termos.data.data) {
      await this.setState({...this.state, termos: termos.data.data});
    } else {
      this.setState({semTermo: true});
    }
  }
  render() {
    const {classes} = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <div className={classes.root}>
            <AcordoMenu />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Body>
                <MeusAcordos>Meus Acordos</MeusAcordos>{' '}
                {this.state.semTermo && <p>Nenhum acordo disponível.</p>}
                {this.state.termos.map((termo, index) => {
                  return (
                    <AcordoList
                      view={`/aceitar-termo`}
                      state={termo}
                      lista={[
                        {key: 'Status', value: termo.recurso.status},
                        {key: 'Origem', value: 'FAAO'},
                        {
                          key: 'Valor Acordado',
                          value: formatCurrency(
                            termo.recurso.negociacao.valorAcordado,
                          ),
                        },
                        {
                          key: 'Valor da Entrada',
                          value: formatCurrency(
                            termo.recurso.negociacao.parcelas[0].valor,
                          ),
                        },
                        {
                          key: 'Parcelas',
                          value: termo.recurso.negociacao.parcelas.length,
                        },
                      ]}
                    />
                  );
                })}
              </Body>
            </main>
          </div>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '100vh',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
  },
});

export default withStyles(styles)(nome);
