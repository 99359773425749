import React, { Component } from "react";
import Routes from "./routes";

export const Erro = () => {
  return <div>oi</div>;
};

class App extends Component {
  render() {
    return <Routes />;
  }
}

export default App;
