import React, { useState, useRef, useEffect } from 'react'
import { useForm, Controller, ErrorMessage } from 'react-hook-form'
import InputMask from 'react-input-mask'

// Services
import api from '../../config/Api'

// Custom Components
import Main from '../../components/Main'
import FormPaper from '../../components/FormPaper'

// Material-UI
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'

// Icons
import AddAPhoto from '@material-ui/icons/AddAPhoto'

import swal from '@sweetalert/with-react'

export default function UsuariosForm(props) {
	const [grupos, setGrupos] = useState([])
	const [empresas, setEmpresas] = useState([])
	// Cria instância do react-hook-form
	const { handleSubmit, setValue, watch, control, errors } = useForm({
		initialValues: { empresa: 0 },
	})
	const [assinatura, setAssinatura] = useState('')

	const [url, setUrl] = useState('')
	const senha = useRef({})
	senha.current = watch('senha', '')

	useEffect(() => {
		getGrupos()
		getEmpresas()
	}, [])

	useEffect(() => {
		getUsuario()
	}, [])

	const onSubmit = async data => {
		const { email, nome, sobrenome, cpf, grupo, empresa } = data
		const obj = {
			email,
			nome,
			sobrenome,
			cpf,
			senha: senha.current,
			//ativo: ativo,
			grupo_id: grupo,
			empresa_id: empresa,
			assinatura,
		}

		const { path } = props.match
		if (path === '/novo-usuario') {
			if (data.senha === '') {
				delete obj.senha
			}

			await api.post(`/api/v1/usuario`, obj)
			swal('Usuário Cadastrado!', 'Você cadastrou o usuário.', 'success')
			props.history.push('/usuarios')
			//alert(JSON.stringify(obj));
		} else {
			if (data.senha === '') {
				delete obj.senha
			}
			if (data.assinatura === '') {
				delete obj.assinatura
			}
			const { id } = props.match.params
			await api.put(`/api/v1/usuario/${id}`, obj)
			swal('Usuário atualizado!', 'Você atualizou o usuário.', 'success')
			props.history.push('/usuarios')
		}

		//	await api.post('/api/v1/usuario', obj);
	}

	const getUsuario = async () => {
		const { path } = props.match
		const { id } = props.match.params
		if (path === '/editar-usuario/:id') {
			const request = await api.get(`/api/v1/usuario/${id}`)
			setValue([
				{ nome: request.data.data.nome },
				{ sobrenome: request.data.data.sobrenome },
				{ email: request.data.data.email },
				{ grupo: request.data.data.grupo_id },
				{ empresa: request.data.data.empresa_id },
				{ cpf: request.data.data.cpf },
			])
			setAssinatura(request.data.data.assinatura)
		}
	}

	const toBase64 = file =>
		new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onload = () => resolve(reader.result)
			reader.onerror = error => reject(error)
		})

	const handleFileChange = async e => {
		let img = { ...assinatura }
		console.log('value')
		console.log(e.target.value)
		const imagem = e.target.files[0]
		img = await toBase64(e.target.files[0])
		let preview = window.URL.createObjectURL(imagem)
		console.log(preview)
		console.log(img)
		setAssinatura(img)
		setUrl(preview)
	}

	const getGrupos = async () => {
		try {
			const grupos = await api.get(`/api/v1/grupo`)
			setGrupos(grupos.data.data)
		} catch (err) {
			console.warn(err)
		}
	}

	const getEmpresas = async () => {
		try {
			const empresas = await api.get(`/api/v1/pessoas/clientes`)
			setEmpresas(empresas.data.data)
		} catch (err) {
			console.warn(err)
		}
	}

	return (
		<Main>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormPaper title="Informações Básicas">
					<Grid container spacing={24}>
						<Grid item lg={3}>
							<Controller
								as={InputMask}
								control={control}
								name="cpf"
								fullWidth
								rules={{ required: true }}
								mask="999.999.999-99"
								defaultValue=""
							>
								{() => (
									<TextField
										error={errors.cpf ? true : false}
										helperText={errors.cpf && ` CPF é um campo obrigatório`}
										rules={{ required: true }}
										fullWidth
										label="CPF"
									/>
								)}
							</Controller>
						</Grid>
						<Grid item lg={3}>
							<Controller
								as={TextField}
								fullWidth
								error={errors.email ? true : false}
								label={'Email'}
								helperText={errors.email && `Email é um campo obrigatório`}
								name="email"
								control={control}
								rules={{ required: true }}
								defaultValue=""
							/>
						</Grid>
						<Grid item lg={3}>
							<Controller
								as={TextField}
								fullWidth
								error={errors.nome ? true : false}
								label={'Nome'}
								helperText={errors.nome && `Nome é um campo obrigatório`}
								name="nome"
								control={control}
								rules={{ required: true }}
								defaultValue=""
							/>
						</Grid>
						<Grid item lg={3}>
							<Controller
								as={TextField}
								fullWidth
								error={errors.nome ? true : false}
								label={'Sobrenome'}
								helperText={errors.nome && `Sobrenome é um campo obrigatório`}
								name="sobrenome"
								control={control}
								rules={{ required: true }}
								defaultValue=""
							/>
						</Grid>
					</Grid>
				</FormPaper>
				<FormPaper title="Senha">
					<Grid container spacing={24}>
						<Grid item lg={3}>
							<Controller
								as={TextField}
								fullWidth
								error={errors.senha ? true : false}
								label={'Senha'}
								helperText={errors.senha && `Senha é um campo obrigatório`}
								name="senha"
								type="password"
								control={control}
								rules={{
									required: props.match.path === '/novo-usuario' ? true : false,
								}}
								rules={{ required: false }}
								defaultValue=""
							/>
						</Grid>
						<Grid item lg={3}>
							<Controller
								as={TextField}
								fullWidth
								error={errors.confirma_senha ? true : false}
								label={'Confirme a Senha'}
								name="confirma_senha"
								type="password"
								control={control}
								rules={{
									required: false,
									validate: value =>
										value === senha.current || 'As senhas não são iguais.',
								}}
								helperText={
									<ErrorMessage errors={errors} name="confirma_senha" />
								}
								defaultValue=""
							/>
						</Grid>
					</Grid>
				</FormPaper>
				<FormPaper title="Permissão">
					<Grid container spacing={24}>
						<Grid item lg={3}>
							<FormControl
								style={{ minWidth: 300 }}
								error={Boolean(errors.grupo)}
							>
								<InputLabel>Grupo de Permissão</InputLabel>
								<Controller
									as={
										<Select>
											<MenuItem value="">Selectione o grupo</MenuItem>
											{grupos.map(grupo => {
												return (
													<MenuItem value={grupo.id}>{grupo.nome}</MenuItem>
												)
											})}
										</Select>
									}
									fullWidth
									control={control}
									name="grupo"
									rules={{ required: 'Campo Obrigratório' }}
									defaultValue=""
								/>
								<FormHelperText>
									{errors.grupo && errors.grupo.message}
								</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
				</FormPaper>
				<FormPaper title="Empresa">
					<Grid container spacing={24}>
						<Grid item lg={4}>
							<FormControl
								style={{ minWidth: 300 }}
								error={Boolean(errors.empresa)}
							>
								<InputLabel>Empresa</InputLabel>
								<Controller
									as={
										<Select>
											<MenuItem value={0}>Selecione a empresa</MenuItem>
											{empresas.map(empresa => {
												return (
													<MenuItem value={empresa.id}>
														{empresa.nome_razao_social}
													</MenuItem>
												)
											})}
										</Select>
									}
									fullWidth
									control={control}
									name="empresa"
									rules={{ required: false }}
									defaultValue={0}
								/>
								<FormHelperText>
									{errors.empresa && errors.empresa.message}
								</FormHelperText>
							</FormControl>
						</Grid>
					</Grid>
				</FormPaper>
				<FormPaper title="Assinatura">
					<input
						accept="image/*"
						id="image-upload"
						type="file"
						style={{ display: 'none' }}
						onChange={e => handleFileChange(e)}
					/>
					<label htmlFor="image-upload">
						<Button variant="contained" component="span">
							<AddAPhoto />
						</Button>
					</label>
					{assinatura && <img src={assinatura} width="200" />}
				</FormPaper>
				<Grid item lg={12}>
					<Button type="submit">Submit</Button>
				</Grid>
			</form>
		</Main>
	)
}
