import React from 'react'
import { Container, BottomNav, Content } from './styles'
import { useStateMachine } from 'little-state-machine'
import updateAction from './updateAction'
import ProgressBar from '../../components/ProgressBar'
import SideContainer from './SideContainer'

export default function CadastroContainer({ children }) {
	const { state } = useStateMachine(updateAction)
	return (
		<Container>
			<SideContainer />
			<Content>
				<ProgressBar bgcolor="#1ed9fd" completed={state.data.progress} />
				{children}
			</Content>
		</Container>
	)
}
