import styled from 'styled-components'

export const Bar = styled.div`
	width: 100%;
	height: 40px;
	background-color: ${props => (props.color ? props.color : 'rgb(78, 78, 78)')};
	padding: 0;
	margin-top: 20px;
	border-radius: 5px 5px 0px 0px;
`

export const TitleBar = styled.h3`
	color: #fff;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 1.1em;
	padding: 10px 16px 10px 16px;
`
