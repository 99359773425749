import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Create from "@material-ui/icons/Create";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const styles = theme => ({
  listItem: {
    width: "100%",
    height: 64,
    background: "#fff",
    borderRadius: 5,
    marginTop: 20,
    padding: 15
  }
});

export const DevedorList = props => {
  const { classes } = props;
  return (
    <Fragment>
      <Grid container className={classes.listItem}>
        <Grid item lg={4}>
          <Typography variant="caption">Nome</Typography>
          <Typography variant="body1">{props.nome}</Typography>
        </Grid>
        <Grid item lg={2}>
          <Typography variant="caption">CPF</Typography>
          <Typography variant="body1">{props.cpf}</Typography>
        </Grid>
        <Grid item lg={2}>
          <Typography variant="caption">Celular</Typography>
          <Typography variant="body1">{props.celular}</Typography>
        </Grid>
        <Grid item lg={2}>
          <Typography variant="caption">Email</Typography>
          <Typography variant="body1">{props.email}</Typography>
        </Grid>
        <Grid item lg={2} align="right">
          <Link to={`${props.link}`}>
            <Button
              mini
              color="primary"
              aria-label="Add"
              style={{ marginRight: 5 }}
            >
              <VisibilityIcon fontSize="small" />
            </Button>
          </Link>
          <Link to={`${props.editar}`}>
            <Button
              mini
              color="primary"
              aria-label="Add"
              style={{ marginRight: 5 }}
            >
              <Create fontSize="small" />
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(DevedorList);
