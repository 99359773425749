import styled, { css } from 'styled-components'
import Warning from '@material-ui/icons/Warning'
import Button from '@material-ui/core/Button'

export const Container = styled.div`
	display: flex;
`

export const BottomNav = styled.div`
	//background: rgba(159, 173, 224, 1);
	background: #d5dbeb;
	width: 80%;
	height: 70px;
	position: fixed;
	bottom: 0;
	left: 20%;
	background-attachment: scroll;
	z-index: 2;
`

export const SideBar = styled.div`
	width: 20%;
	height: 100vh;
	//background: #596279;
	background: #34a79d;
	position: fixed;
`

export const SideFlex = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 20px;
	margin-top: 20px;
`

export const InfoContainer = styled.div`
	display: flex;
	padding: 20px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	color: #fff;
	font-family: sans-serif;
	h4 {
		margin: 5px;
		text-align: left;
	}
`

export const Content = styled.div`
	display: block;
	padding: 60px;
	width: 80%;
	margin-left: 20%;
	justify-content: center;
	position: relative;
	height: 100vh;
	box-sizing: border-box;
`

export const SearchContainer = styled.div`
	width: 100%;
	display: flex;
	margin-top: 30px;
	margin-bottom: 30px;
	flex-direction: column;
	align-items: center;
	font-family: sans-serif;
	justify-content: center;
`

export const SearchTitle = styled.h2`
	font-size: 2em;
	font-family: sans-serif;
	margin-bottom: 0;
`

export const SearchSubTitle = styled.h2`
	font-size: 0.8em;
	margin-bottom: 20px;
	color: rgba(159, 173, 224, 0.9);
	font-family: sans-serif;
`

export const ProgressContainer = styled.div`
	width: 100%;
	background: rgba(159, 173, 224, 0.2);
	border-radius: 10px;
	height: 20px;
`

export const SearchInput = styled.input`
	width: 60%;
	height: 50px;
	font-size: 20px;
	background: rgba(159, 173, 224, 0.2);
	color: rgba(0, 0, 0, 0.5);
	font-weight: bold;
	padding-left: 10px;
	border: 1px solid rgba(159, 173, 224, 0.7);
	border-radius: 5px;
	&:focus {
		outline: 1px solid rgba(159, 173, 223, 1);
	}
`

export const Form = styled.form`
	padding: 40px;
`
// StepTwo - File upload
const dragActive = css`
	border-color: #78e5d5;
`

const dragReject = css`
	border-color: #e57878;
`

export const DropContainer = styled.div.attrs({
	className: 'dropzone',
})`
	border: 1px dashed #ddd;
	border-radius: 4px;
	cursor: pointer;

	transition: height 0.2s ease;

	${props => props.isDragActive && dragActive};
	${props => props.isDragReject && dragReject};
`

const messageColors = {
	default: '#999',
	error: '#e57878',
	success: '#78e5d5',
}

export const UploadMessage = styled.p`
	display: flex;
	width: 100%;
	color: ${props => messageColors[props.type || 'default']};
	justify-content: center;
	align-items: center;
	padding: 15px 0;
`

export const ContratoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: sans-serif;
	text-align: center;
`

export const WarningContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
`

export const WarningButton = styled(Button)`
	&& {
		background: #ffbc00;
		padding: 10px;
		&:hover {
			background: #ffbc00;
		}
	}
`

export const WarningText = styled.p`
	color: #777777;
	font-family: sans-serif;
	font-size: 1.5em;
`

export const WarningIcon = styled(Warning)`
	&& {
		color: #ffbc00;
		font-size: 70px;
	}
`

export const Logo = styled.img`
	width: 150px;
`

export const GeraParcelas = styled(Button)`
	&& {
		background: #596279;
		color: #fff;
		&:hover {
			background: #596279;
			color: #fff;
		}
	}
`

export const SaveButton = styled(Button)`
	&& {
		margin-top: 10px;
		background: #34a79d;
		color: #fff;
		&:hover {
			background: #34a79d;
			color: #fff;
		}
	}
`

export const GreyButton = styled(Button)`
	&& {
		background: #596279;
		color: #fff;
		&:hover {
			background: #596279;
			color: #fff;
		}
	}
`
