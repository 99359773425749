import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

// Styles
import {
  FlexContainer,
  ImageContainer,
  InfoContainer,
  Paleta,
  Title,
  SubTitle,
  InputContainer,
  CpfButton,
} from './styles';

// Material-UI
import TextField from '@material-ui/core/TextField';

export const CpfBox = props => {
  return (
    <Fragment>
      <FlexContainer>
        <ImageContainer />
        <InfoContainer>
          <Paleta />
          <Title>Sua solução está aqui!</Title>
          <SubTitle>
            Insira seu cpf e conheça nossa proposta feita especialmente para
            você.
          </SubTitle>
          <InputContainer>
            <InputMask
              mask="999.999.999-99"
              fullWidth
              label="CPF"
              placeholder="Insira seu CPF aqui"
              value={props.cpf}
              onChange={props.change}>
              {() => <TextField name="cpf" />}
            </InputMask>
            <Link
              to={`/termo-de-acordo/${props.cpf
                .toString()
                .replace(/\.|-/gm, '')}`}
              style={{alignSelf: 'center'}}>
              <CpfButton>Pesquisar</CpfButton>
            </Link>
          </InputContainer>
        </InfoContainer>
        {props.cpf}
      </FlexContainer>
    </Fragment>
  );
};

CpfBox.propTypes = {
  change: PropTypes.func.isRequired,
  cpf: PropTypes.string,
};

CpfBox.defaultProps = {
  name: '',
};

export default CpfBox;
