// @format
import React from 'react'
import swal from 'sweetalert'
import './Login.css'
import api from '../../config/Api'

// Material-ui
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Lock from '@material-ui/icons/Lock'
import { login } from '../../config/Auth'

class Login extends React.Component {
  state = {
    email: '',
    senha: '',
  }

  salvaDados(dados) {
    localStorage.setItem('usuario', dados)
  }

  // Envia o objeto auth com as credenciais do login
  efetuaLogin = async event => {
    event.preventDefault()
    const obj = {
      email: this.state.email,
      senha: this.state.senha,
    }
    try {
      const request = await api.post('/api/v1/auth', obj)
      login(request.data.access_token, request.data.refresh_token)
      const usuario = await api.get('/api/v1/auth/me')
      if (
        usuario.data.me.grupo_nome === 'cliente' ||
        usuario.data.me.grupo_nome === 'devedor'
      ) {
        swal('Erro!', 'Usuário não tem permissão de acesso!', 'warning')
      } else {
        this.salvaDados(JSON.stringify(usuario.data))
        this.props.history.push('/clientes')
      }
    } catch (err) {
      console.warn(err)
    }
  }
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    })
  }
  render() {
    return (
      <form onSubmit={this.efetuaLogin} style={{ display: 'block' }}>
        <div className="login-container">
          <div className="login__panel" />
          <div className="login__content">
            <TextField
              id="email"
              className="login__email"
              label="Email"
              onChange={this.handleChange('email')}
              name="email"
              placeholder="Seu email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id="input-with-icon-textfield"
              type="password"
              label="Senha"
              placeholder="Digite sua senha"
              value={this.state.senha}
              name="senha"
              onChange={this.handleChange('senha')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              className="login__button"
            >
              Entrar
            </Button>
          </div>
        </div>
      </form>
    )
  }
}

export default Login
