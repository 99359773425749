import React, { useState, useEffect } from 'react'
import api, { rota } from '../../config/Api'
import Dropzone from 'react-dropzone'
import { useStateMachine } from 'little-state-machine'
import swal from '@sweetalert/with-react'

import updateAction from './updateAction'

// @material-ui/core
import Grid from '@material-ui/core/Grid'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Attachment from '@material-ui/icons/Attachment'
import LinearProgress from '@material-ui/core/LinearProgress'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import TextField from '@material-ui/core/TextField'

// @material-ui/icons
import DeleteIcon from '@material-ui/icons/Delete'
import Avatar from '@material-ui/core/Avatar'
import CloudDownload from '@material-ui/icons/CloudDownload'
import ArrowRightAlt from '@material-ui/icons/ArrowRightAlt'

// Styled Components
import {
	DropContainer,
	UploadMessage,
	ContratoContainer,
	GreyButton,
} from './styles'

// Custom Components
import CadastroContainer from './CadastroContainer'
import { Button } from '@material-ui/core'

const renderDragMessage = (isDragActive, isDragReject) => {
	if (!isDragActive) {
		return <UploadMessage>Arraste arquivos aqui</UploadMessage>
	}
	if (isDragReject) {
		return <UploadMessage type="error">Arquivo não suportado</UploadMessage>
	}
	return <UploadMessage type="success">Solte os arquivos aqui</UploadMessage>
}

export default function StepTwo({ match, history }) {
	const [localState, setState] = useState({
		contrato_id: '',
		progresso: '',
		arquivos: [],
		numero: '',
		ano: '',
	})

	const { action, state } = useStateMachine(updateAction)

	useEffect(() => {
		getArquivos()
	}, [])

	const salvaContrato = e => {
		let obj = {
			ano: localState.ano,
			pessoa_cliente_id: state.data.pessoa_cliente_id,
			pessoa_devedor_id: state.data.pessoa_devedor_id,
		}
		api
			.post(
				`/api/v1/pessoas/clientes/${state.data.pessoa_cliente_id}/contratos`,
				obj
			)
			.then(resp => {
				setState({
					...localState,
					contrato_id: resp.data.data.contrato_id,
					numero: resp.data.data.numero,
				})
				console.log(localState)
				action({
					contrato_id: resp.data.data.contrato_id,
					contratoNumero: resp.data.data.numero,
					progress: 50,
				})
			})
	}

	const proximo = () => {
		history.push('/cadastrar-divida/cadastrar-parcelas')
	}

	const getArquivos = async () => {
		try {
			const arquivos = await api.get(
				`/api/v1/arquivos?origem=contrato&id_origem=${state.data.contrato_id}`
			)
			setState({ ...localState, arquivos: arquivos.data.data })
		} catch (err) {
			console.warn(err)
		}
	}

	const handleUploads = async files => {
		let formData = new FormData()
		formData.append(`arquivo`, files[0])
		formData.append(`origem`, 'contrato')
		formData.append(`nome`, files[0].name)
		formData.append(`id_origem`, state.data.contrato_id)
		const arquivo = await api.post(`/api/v1/arquivos`, formData, {
			onUploadProgress: e => {
				const progress = parseInt(Math.round((e.loaded * 100) / e.total))
				setState({ progresso: progress })
			},
		})
		setState({
			arquivos: [...localState.arquivos, { ...arquivo.data.arquivo }],
		})
	}

	const deleteArquivo = async index => {
		swal({
			title: 'Remover arquivo',
			text: 'Você deseja remover o arquivo selecionado?',
			buttons: ['Cancelar', 'Remover'],
			icon: 'warning',
			dangerMode: true,
		}).then(willDelete => {
			if (willDelete) {
				const arquivos = [...localState.arquivos]
				api.delete(`/api/v1/arquivos/${arquivos[index].id}`)
				arquivos.splice(index, 1)
				setState({ arquivos: arquivos })
				swal(`Poof! O arquivo foi removido com sucesso!`, {
					icon: 'success',
				})
			} else {
				swal('O arquivo não foi removido!')
			}
		})
	}

	return (
		<CadastroContainer>
			{state.data.contrato_id && <h2>Contrato: {state.data.contratoNumero}</h2>}
			{state.data.contrato_id}

			{!state.data.contrato_id && (
				<ContratoContainer>
					<h2>Cadastrar Contrato</h2>
					<TextField
						style={{ width: 300 }}
						onChange={e => setState({ ...localState, ano: e.target.value })}
						label="Insira o Ano do Contrato"
					/>
					<Button
						onClick={salvaContrato}
						variant="contained"
						color="primary"
						style={{ marginTop: 10 }}
					>
						Cadastrar
					</Button>
				</ContratoContainer>
			)}
			{state.data.contrato_id && (
				<Grid container style={{ marginTop: 50 }}>
					<Grid item lg={6} style={{ borderRight: '1px solid #e4e4e4' }}>
						<Typography variant="h5" gutterBottom style={{ padding: 20 }}>
							Adicionar Anexos
						</Typography>
						<Dropzone accept="application/pdf" onDropAccepted={handleUploads}>
							{({
								getRootProps,
								getInputProps,
								isDragActive,
								isDragReject,
							}) => (
								<DropContainer
									{...getRootProps()}
									isDragActive={isDragActive}
									isDragReject={isDragReject}
								>
									<input {...getInputProps()} />
									{renderDragMessage(isDragActive, isDragReject)}
								</DropContainer>
							)}
						</Dropzone>
						<Typography variant="h5" gutterBottom style={{ padding: 20 }}>
							{localState.progresso}%
						</Typography>
						<LinearProgress
							variant="determinate"
							value={localState.progresso}
						/>
					</Grid>
					<Grid item lg={6}>
						<Grid item lg={12}>
							<Typography variant="h5" gutterBottom style={{ padding: 20 }}>
								Lista de Anexos
							</Typography>
						</Grid>
						<List>
							{localState.arquivos
								? localState.arquivos.map((arquivo, index) => {
										return (
											<ListItem button key={arquivo.id}>
												<ListItemAvatar>
													<Avatar>
														<Attachment />
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													primary={arquivo.nome}
													secondary={arquivo.cadastrado_por ? 'Nome' : ''}
												/>
												<ListItemSecondaryAction>
													<a
														href={`${rota}/${arquivo.arquivo}`}
														target="_blank"
														rel="noopener noreferrer"
													>
														<IconButton aria-label="Baixar">
															<CloudDownload />
														</IconButton>
													</a>
													<IconButton
														onClick={() => deleteArquivo(index)}
														aria-label="Delete"
														color="secondary"
													>
														<DeleteIcon />
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										)
								  })
								: null}

							<Divider />
						</List>
					</Grid>
					{state.data.contrato_id && (
						<GreyButton
							style={{ marginTop: 30 }}
							fullWidth
							variant="contained"
							color="primary"
							onClick={proximo}
						>
							Proximo
							<ArrowRightAlt style={{ marginLeft: 10 }} />
						</GreyButton>
					)}
				</Grid>
			)}
		</CadastroContainer>
	)
}
