// @format
import React, { Fragment } from "react";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import AppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DateRange from "@material-ui/icons/DateRange";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import MomentUtils from "@date-io/moment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import AddBox from "@material-ui/icons/AddBox";
import Clear from "@material-ui/icons/Clear";
import DeleteForever from "@material-ui/icons/DeleteForever";
import moment from "moment";
import api from "../../config/Api";
import { formatCurrency } from "../../components/Helpers";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "moment/locale/pt-br";
moment.locale("pt-br");

const styles = {
  appBar: {
    position: "relative",
    backgroundColor: "#4e4e4e"
  },
  flex: {
    flex: 1
  },
  label: {
    color: "#fff !important"
  }
};
class Terceiro extends React.Component {
  state = {
    parcelaUnica: false,
    vencimento: "",
    valor: 0,
    valorTotal: 0,
    parcelas: [],
    dataIni: moment().format("YYYY-MM-DD"),
    dataFim: moment().format("YYYY-MM-DD")
  };

  componentDidMount() {}

  mostraEstado() {
    console.log(this.state.parcelas[0].id);
  }

  getParcelas() {
    api
      .get(
        `api/v1/pessoas/clientes/${this.props.clienteId}/remessas/${
          this.props.remessaId
        }/dividas/${this.props.dividaId}/parcelas`
      )
      .then(resp =>
        this.setState({
          ...this.state,
          parcelas: resp.data.data,
          valorTotal: this.props.valor
        })
      );
  }

  envia(event) {
    if (this.state.dataIni === undefined) {
      alert("Adicione o valor inicial");
    } else {
      // Pega a data inicial e a data final
      let ini = moment(this.state.dataIni);
      let fim = moment(this.state.dataFim);

      // Calcula a quantidade de meses
      // let resultado = fim.diff(ini, "months");
      // let meses = parseInt(resultado) + 1;
      while (fim > ini || ini.format("M") === fim.format("M")) {
        this.state.parcelas.push({
          vencimento: ini.format("YYYY-MM-DD"),
          // mesPuro: ini.format("YYYY-MM-DD"),
          valor: parseFloat(this.state.valor)
        });
        // Soma o valor total das parcelas
        let counter = this.state.parcelas.length;
        for (var i = 0, a = 0; i < counter; i++) {
          a = parseFloat(a) + parseFloat(this.state.parcelas[i].valor);
        }
        this.setState({ valorTotal: parseFloat(a) });
        ini.add(1, "month");
      }
      this.forceUpdate();
      this.setState({
        nome: "",
        valor: 0,
        dataIni: moment().format("YYYY-MM-DD"),
        dataFim: moment().format("YYYY-MM-DD")
      });
    }
  }

  handleCheck = name => event => {
    this.resetar();
    this.setState({ [name]: event.target.checked });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  resetar() {
    this.setState({
      ...this.state,
      parcelas: [{ valor: 0, vencimento: moment().format("YYYY-MM-DD") }],
      valorTotal: 0.0
    });
  }

  handleNew(e, index) {
    const parcelas = this.state.parcelas;
    parcelas[e].nome = index.target.value;
    this.forceUpdate();
  }

  handleDate(e, index) {
    const parcelas = this.state.parcelas;
    parcelas[e].mesPuro = index.target.value;
    parcelas[e].mes = index.target.value;
    parcelas[e].vencimento = index.target.value;
    this.forceUpdate();
  }

  removeItem(e, index) {
    let { manda } = this.props;
    const array = [...this.state.parcelas]; // make a separate copy of the array
    if (index !== -1) {
      api.delete(
        `/api/v1/pessoas/clientes/${this.props.clienteId}/remessas/${
          this.props.remessaId
        }/dividas/${this.props.dividaId}/parcelas/${this.state.parcelas[e].id}`
      );
      array.splice(index, 1);

      console.log(e + " e");
      console.log(this.state.parcelas[e].id);
      this.setState({ parcelas: array }, () => {
        let counter = this.state.parcelas.length;
        for (var i = 0, a = 0; i < counter; i++) {
          a = parseFloat(a) + parseFloat(this.state.parcelas[i].valor);
        }
        this.setState({ valorTotal: a });
      });

      manda.bind(this, this.state.parcelas);
    }
  }

  handleValue = (e, index) => {
    const parcelas = this.state.parcelas;
    parcelas[e].valor = index.target.value;
    let counter = this.state.parcelas.length;
    for (var i = 0, a = 0; i < counter; i++) {
      a = parseFloat(a) + parseFloat(this.state.parcelas[i].valor);
    }
    this.setState({ valorTotal: a });
    this.props.manda.bind(this, this.state.parcelas);
    this.forceUpdate();
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <div>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.flex}
                >
                  Total: {formatCurrency(this.state.valorTotal)}
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.negociacao_online}
                      onChange={this.handleCheck("parcelaUnica")}
                      color="secondary"
                      value="true"
                    />
                  }
                  label="Parcela Única?"
                  classes={{ label: classes.label }}
                />
              </Toolbar>
            </AppBar>
            <Paper style={{ padding: 20, minHeight: "40vh" }}>
              <Grid container spcaing={24}>
                {this.state.parcelaUnica ? (
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "1px solid #e4e4e4",
                      minHeight: "40vh"
                    }}
                  >
                    <Grid container spacing={24}>
                      <Grid item lg={4}>
                        <TextField
                          label="Valor da Parcela"
                          type="number"
                          required
                          name="valor"
                          onChange={this.handleValue.bind(this, 0)}
                          helperText="Inicio da Dívida"
                          value={this.state.parcelas[0].valor}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item lg={4}>
                        <TextField
                          type="date"
                          onChange={this.handleDate.bind(this, 0)}
                          name="vencimento"
                          label="Vencimento"
                          helperText="Data do vencimento"
                          value={this.state.parcelas[0].vencimento}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DateRange />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    item
                    lg={6}
                    style={{
                      borderRight: "1px solid #e4e4e4",
                      minHeight: "40vh"
                    }}
                  >
                    <Grid container spacing={24}>
                      <Grid item lg={5}>
                        <TextField
                          id="dataIni"
                          label="Data Inicial"
                          type="date"
                          required
                          name="dataIni"
                          onChange={this.handleInputChange.bind(this)}
                          helperText="Inicio da Dívida"
                          value={this.state.dataIni}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DateRange />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item lg={5}>
                        <TextField
                          id="dataFim"
                          label="Data Final"
                          required
                          type="date"
                          helperText="Final da Dívida"
                          name="dataFim"
                          onChange={this.handleInputChange.bind(this)}
                          value={this.state.dataFim}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DateRange />
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>
                      <Grid item lg={12}>
                        <FormControl>
                          <InputLabel htmlFor="adornment-amount">
                            Valor da Parcela
                          </InputLabel>
                          <Input
                            id="adornment-amount"
                            value={this.state.valor}
                            name="valor"
                            type="number"
                            onChange={this.handleInputChange.bind(this)}
                            startAdornment={
                              <InputAdornment position="start">
                                R$
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Button
                      style={{ marginTop: 20, marginRight: 10 }}
                      variant="contained"
                      color="primary"
                      margin="normal"
                      onClick={this.envia.bind(this)}
                    >
                      <AddBox style={{ marginRight: 5 }} /> Gerar Parcelas
                    </Button>
                    {this.state.parcelas.length > 0 ? (
                      <Button
                        style={{ marginTop: 20 }}
                        variant="contained"
                        color="primary"
                        margin="normal"
                        onClick={this.resetar.bind(this)}
                      >
                        <Clear /> Resetar
                      </Button>
                    ) : null}
                  </Grid>
                )}
                <Grid item lg={6} style={{ paddingLeft: 10 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Valor</TableCell>
                        <TableCell align="left">Vencimento</TableCell>
                        <TableCell align="left">Deletar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.parcelas.map((parcela, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              <FormControl>
                                <Input
                                  id="adornment-amount"
                                  value={parcela.valor}
                                  name="valor"
                                  type="number"
                                  onChange={this.handleValue.bind(this, index)}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      R$
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                            </TableCell>
                            <TableCell align="left">
                              <TextField
                                type="date"
                                onChange={this.handleDate.bind(this, index)}
                                name="vencimento"
                                value={parcela.vencimento}
                              />
                            </TableCell>
                            <TableCell align="left">
                              <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={this.removeItem.bind(this, index)}
                              >
                                <DeleteForever size="small" />
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Paper>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: 60
              }}
            >
              <Button
                variant="contained"
                onClick={this.props.salvaDivida.bind(this, this.state)}
                disabled={this.state.parcelas.length === 0 ? true : false}
                size="small"
              >
                <SaveIcon />
                SALVAR DÍVIDA
              </Button>
            </div>
          </div>
        </MuiPickersUtilsProvider>
      </Fragment>
    );
  }
}

export default withStyles(styles)(Terceiro);
