import React from 'react'
import { withRouter } from 'react-router-dom'
import { Logo, SideBar, SideFlex, InfoContainer } from './styles'
import { useStateMachine } from 'little-state-machine'
import { cpfMask, telefoneMask } from '../../components/Helpers'
import PositiveLogo from '../../assets/logo.png'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'

export function SideContainer({ history }) {
	const { state } = useStateMachine()
	return (
		<SideBar>
			<SideFlex>
				<div style={styles.container} onClick={() => history.goBack()}>
					<KeyboardBackspace style={styles.iconColor} />
					<p style={styles.voltar}>Voltar</p>
				</div>
				<Logo src={PositiveLogo} alt="" />
			</SideFlex>
			<InfoContainer>
				<h4>Devedor: {state.data.devedor.nome_razao_social}</h4>
				<h4>CPF: {cpfMask(state.data.devedor.cpf_cnpj)}</h4>
				<h4>Celular: {telefoneMask(state.data.devedor.telefone_celular)}</h4>
				<h4>Contrato: {state.data.contratoNumero}</h4>
			</InfoContainer>
		</SideBar>
	)
}

const styles = {
	container: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		margin: 0,
		padding: 0,
	},
	voltar: {
		margin: 0,
		color: '#fff',
		fontSize: '18px',
		fontFamily: 'sans-serif',
	},
	iconColor: {
		color: '#fff',
		marginRight: 5,
	},
}

export default withRouter(SideContainer)
