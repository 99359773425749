// @format
import React, {useState, useEffect} from 'react';
import {useForm, Controller} from 'react-hook-form';
import axios from 'axios';
import api from '../../config/Api';

// Material-ui
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';

// Icons
import Delete from '@material-ui/icons/Delete';
import AddAPhoto from '@material-ui/icons/AddAPhoto';

// Componentes da Recred
//import Bar from '../../components/Appbar';
import FormPaper from '../../components/FormPaper/index';
import {Avatar} from './styles';

import moment from 'moment';
import 'moment/locale/pt-br';
import Main from '../../components/Main';

moment.locale('pt-br');

export default function ClienteForm(props) {
  const {handleSubmit, setValue, control, errors} = useForm({
    initialValues: {},
  });

  const [pessoaFisica, setPessoaFisica] = useState(1);
  //bancos
  const [bancos, setBancos] = useState([]);
  const [banco_nome, setBancoNome] = useState('');
  const [banco_agencia, setBancoAgencia] = useState('');
  const [banco_conta, setBancoConta] = useState('');
  const [banco_tipo_conta, setBancoTipoConta] = useState('');
  const [endereco, setEndereco] = useState({
    cep: '',
    bairro: '',
    numero: '',
    complemento: '',
    logradouro: '',
    localidade: '',
    uf: '',
  });

  const [responsaveis, setResponsaveis] = useState([]);
  const [responsavel_nome, setResponsavelNome] = useState('');
  const [responsavel_cpf, setResponsavelCpf] = useState('');
  const [responsavel_email, setResponsavelEmail] = useState('');
  const [responsavel_telefone, setResponsavelTelefone] = useState('');

  const [avatar, setAvatar] = useState('');
  const [url, setUrl] = useState('');

  const [enderecoLoading, setEnderecoLoading] = useState(false);

  const onCepChange = async e => {
    setEndereco({...endereco, cep: e});
  };

  const getCep = async () => {
    if (endereco.cep) {
      if (endereco.cep.length === 8) {
        setEnderecoLoading(true);
        const {data} = await axios.get(
          `https://viacep.com.br/ws/${endereco.cep}/json`,
        );
        const {logradouro, localidade, bairro, uf} = data;
        setEndereco({
          ...endereco,
          logradouro,
          localidade,
          bairro,
          uf,
          numero: endereco.numero,
        });
        setEnderecoLoading(false);
      }
    }
  };

  const getCliente = async () => {
    const {match} = props;
    if (match.path === '/editar-cliente/:id') {
      const request = await api.get(
        `/api/v1/pessoas/clientes/${match.params.id}`,
      );
      setValue([
        {nome_razao_social: request.data.data.nome_razao_social},
        {cpf_cnpj: request.data.data.cpf_cnpj},
        {rg_inscricao_estadual: request.data.data.rg_inscricao_estadual},
        {apelido_nome_fantasia: request.data.data.apelido_nome_fantasia},
        {email: request.data.data.email},
        {telefone_celular: request.data.data.telefone_celular},
        {telefone_fixo: request.data.data.telefone_fixo},
        {nascimento: request.data.data.nascimento},
      ]);
      setPessoaFisica(request.data.data.tipo);
      setBancos([...request.data.data.bancos]);
      if (request.data.data.responsaveis !== null) {
        setResponsaveis([...request.data.data.responsaveis]);
      }
      setEndereco(request.data.data.endereco);
      setAvatar(request.data.data.avatar);
    }
  };

  useEffect(() => {
    getCliente();
  }, []);

  useEffect(() => {
    console.log(endereco.cep);
    getCep();
  }, [endereco.cep]);

  const handleAddBancos = () => {
    let bancosCopy = [...bancos];
    const banco = {
      nome: banco_nome,
      agencia: banco_agencia,
      conta: banco_conta,
      tipo_conta: banco_tipo_conta,
    };
    bancosCopy.push(banco);
    setBancos(bancosCopy);
    setBancoNome('');
    setBancoAgencia('');
    setBancoConta('');
    setBancoTipoConta('');
    console.log(bancosCopy);
  };

  const handleChangeBanco = (index, param, value) => {
    let bancosCopy = [...bancos];
    bancosCopy[index][param] = value;
    setBancos(bancosCopy);
  };

  const handleDelete = index => {
    let clone = [...bancos];
    clone.splice(index, 1);
    setBancos(clone);
  };

  // Responsáveis

  const handleAddResponsaveis = () => {
    let responsaveisCopy = [...responsaveis];
    const responsavel = {
      nome: responsavel_nome,
      cpf: responsavel_cpf,
      email: responsavel_email,
      telefone: responsavel_telefone,
    };
    responsaveisCopy.push(responsavel);
    setResponsaveis(responsaveisCopy);
    setResponsavelNome('');
    setResponsavelCpf('');
    setResponsavelEmail('');
    setResponsavelTelefone('');
  };

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const handleFileChange = async e => {
    let img = {...avatar};
    console.log('value');
    console.log(e.target.value);
    const imagem = e.target.files[0];
    img = await toBase64(e.target.files[0]);
    let preview = window.URL.createObjectURL(imagem);
    console.log(preview);
    console.log(img);
    setAvatar(img);
    setUrl(preview);
  };

  const handleChangeResponsavel = (index, param, value) => {
    let responsaveisCopy = [...responsaveis];
    responsaveisCopy[index][param] = value;
    setResponsaveis(responsaveisCopy);
  };

  const handleResponsavelDelete = index => {
    let clone = [...responsaveis];
    clone.splice(index, 1);
    setResponsaveis(clone);
  };

  const onSubmit = async data => {
    const obj = {
      ...data,
      endereco,
      bancos,
      responsaveis,
      tipo: 1,
      sexo: 'm',
      avatar,
    };

    const {
      match: {path},
    } = props;
    const {
      match: {
        params: {id},
      },
    } = props;
    if (path === '/novo-cliente') {
      await api.post(`/api/v1/pessoas/clientes`, obj);
      props.history.push('/clientes');
    } else {
      await api.put(`/api/v1/pessoas/clientes/${id}`, obj);
      props.history.push('/clientes');
    }
  };

  return (
    <Main>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormPaper title="Informações Básicas">
          <Grid container spacing={24}>
            <Grid item lg={12}>
              <FormControlLabel
                control={
                  <Switch
                    value={pessoaFisica}
                    onChange={e => setPessoaFisica(e.target.checked)}
                    name="pessoa_fisica"
                  />
                }
                label="Pessoa Física?"
              />
            </Grid>
            <Grid item lg={3}>
              <Controller
                as={TextField}
                fullWidth
                error={errors.cpf_cnpj ? true : false}
                label={pessoaFisica ? 'CPF' : 'CNPJ'}
                helperText={
                  errors.cpf_cnpj &&
                  ` ${pessoaFisica ? 'CPF' : 'CNPJ'} é um campo obrigatório`
                }
                name="cpf_cnpj"
                control={control}
                rules={{required: true}}
                defaultValue=""
              />
            </Grid>
            <Grid item lg={3}>
              <Controller
                as={TextField}
                fullWidth
                error={errors.rg_inscricao_estadual ? true : false}
                label={pessoaFisica ? 'RG' : 'Inscrição Estadual'}
                helperText={
                  errors.rg_inscricao_estadual &&
                  `${
                    pessoaFisica ? 'RG' : 'Inscrição Estadual'
                  } é um campo obrigatório`
                }
                name="rg_inscricao_estadual"
                control={control}
                rules={{required: true}}
                defaultValue=""
              />
            </Grid>
            <Grid item lg={3}>
              <Controller
                as={TextField}
                fullWidth
                error={errors.nome_razao_social ? true : false}
                label={pessoaFisica ? 'Nome' : 'Razão Social'}
                helperText={
                  errors.nome_razao_social &&
                  `${
                    pessoaFisica ? 'Nome' : 'Razão Social'
                  } é um campo obrigatório`
                }
                name="nome_razao_social"
                control={control}
                rules={{required: true}}
                defaultValue=""
              />
            </Grid>
            <Grid item lg={3}>
              <Controller
                as={TextField}
                fullWidth
                error={errors.apelido_nome_fantasia ? true : false}
                label={pessoaFisica ? 'Apelido' : 'Nome Fantasia'}
                helperText={
                  errors.apelido_nome_fantasia &&
                  `${
                    pessoaFisica ? 'Apelido' : 'Nome Fantasia'
                  } é um campo obrigatório`
                }
                name="apelido_nome_fantasia"
                control={control}
                rules={{required: true}}
                defaultValue=""
              />
            </Grid>
            <Grid item lg={3}>
              <Controller
                as={TextField}
                fullWidth
                error={errors.email ? true : false}
                label={'Email'}
                helperText={errors.email && `Email é um campo obrigatório`}
                name="email"
                control={control}
                rules={{required: true}}
                defaultValue=""
              />
            </Grid>
            <Grid item lg={3}>
              <Controller
                as={TextField}
                fullWidth
                error={errors.telefone_celular ? true : false}
                label={'Telefone Celular'}
                helperText={
                  errors.telefone_celular &&
                  `Telefone Celular é um campo obrigatório`
                }
                name="telefone_celular"
                control={control}
                rules={{required: true}}
                defaultValue=""
              />
            </Grid>
            <Grid item lg={3}>
              <Controller
                as={TextField}
                fullWidth
                error={errors.telefone_fixo ? true : false}
                label={'Telefone Fixo'}
                helperText={
                  errors.telefone_celular &&
                  `Telefone Fixo é um campo obrigatório`
                }
                name="telefone_fixo"
                control={control}
                rules={{required: true}}
                defaultValue=""
              />
            </Grid>
            <Grid item lg={3}>
              <Controller
                as={TextField}
                fullWidth
                type="date"
                error={errors.nascimento ? true : false}
                label={pessoaFisica ? 'Data de Nascimento' : 'Data de Fundação'}
                helperText={
                  errors.nascimento &&
                  `${
                    pessoaFisica ? 'Data de Nascimento' : 'Data de Fundação'
                  } é um campo obrigatório`
                }
                name="nascimento"
                control={control}
                rules={{required: true}}
                defaultValue={moment().format('YYYY-MM-DD')}
              />
            </Grid>
          </Grid>
        </FormPaper>
        <FormPaper title="Endereço">
          <Grid container spacing={24}>
            {!enderecoLoading ? (
              <>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="CEP"
                    name="cep"
                    value={endereco.cep}
                    placeholder="CEP"
                    onChange={e => onCepChange(e.target.value)}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="Rua"
                    name="logradouro"
                    value={endereco.logradouro}
                    placeholder="Rua"
                    onChange={e =>
                      setEndereco({...endereco, logradouro: e.target.value})
                    }
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="Número"
                    name="numero"
                    value={endereco.numero}
                    placeholder="Número"
                    onChange={e =>
                      setEndereco({...endereco, numero: e.target.value})
                    }
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    name="complemento"
                    value={endereco.complemento}
                    placeholder="Complemento"
                    onChange={e =>
                      setEndereco({...endereco, complemento: e.target.value})
                    }
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    name="bairro"
                    value={endereco.bairro}
                    placeholder="Bairro"
                    onChange={e =>
                      setEndereco({...endereco, bairro: e.target.value})
                    }
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    name="localidade"
                    value={endereco.localidade}
                    placeholder="Cidade"
                    onChange={e =>
                      setEndereco({...endereco, localidade: e.target.value})
                    }
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    fullWidth
                    label="Estado"
                    name="uf"
                    value={endereco.uf}
                    placeholder="Estado"
                    onChange={e =>
                      setEndereco({...endereco, uf: e.target.value})
                    }
                  />
                </Grid>
              </>
            ) : (
              <p>Buscando informações do endereço</p>
            )}
          </Grid>
        </FormPaper>
        <FormPaper title="Contas Bancárias">
          <Grid container spacing={24}>
            <Grid item lg={3}>
              <TextField
                fullWidth
                label="Banco"
                name="banco_nome"
                value={banco_nome}
                onChange={e => setBancoNome(e.target.value)}
              />
            </Grid>
            <Grid item lg={2}>
              <TextField
                fullWidth
                label={'Agencia'}
                name={`banco_agencia`}
                value={banco_agencia}
                onChange={e => setBancoAgencia(e.target.value)}
              />
            </Grid>
            <Grid item lg={2}>
              <TextField
                fullWidth
                label={'Conta'}
                name={`banco_conta`}
                value={banco_conta}
                onChange={e => setBancoConta(e.target.value)}
              />
            </Grid>
            <Grid item lg={2}>
              <TextField
                select
                fullWidth
                label={'Tipo da conta'}
                name={`banco_tipo_conta`}
                value={banco_tipo_conta}
                onChange={e => setBancoTipoConta(e.target.value)}>
                <MenuItem value="0">Conta Corrente</MenuItem>
                <MenuItem value="1">Conta Poupança</MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={2}>
              <Button
                variant="contained"
                color="primary"
                margin="dense"
                onClick={() => handleAddBancos()}>
                + Adicionar
              </Button>
            </Grid>
          </Grid>
          {bancos.map((banco, index) => {
            return (
              <>
                <Grid container spacing={24}>
                  <Grid item lg={2}>
                    <TextField
                      fullWidth
                      label="Banco"
                      name="nome"
                      value={bancos[index].nome}
                      onChange={e =>
                        handleChangeBanco(index, 'nome', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <TextField
                      fullWidth
                      label={'Agencia'}
                      name={`agencia`}
                      value={bancos[index].agencia}
                      onChange={e =>
                        handleChangeBanco(index, 'agencia', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <TextField
                      fullWidth
                      label={'Conta'}
                      name={`conta`}
                      value={bancos[index].conta}
                      onChange={e =>
                        handleChangeBanco(index, 'conta', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <TextField
                      select
                      fullWidth
                      label={'Tipo da conta'}
                      name={`tipo_conta`}
                      value={bancos[index].tipo_conta}
                      onChange={e =>
                        handleChangeBanco(index, 'tipo_conta', e.target.value)
                      }>
                      <MenuItem value="0">Conta Corrente</MenuItem>
                      <MenuItem value="1">Conta Poupança</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    style={{
                      marginTop: 10,
                    }}>
                    <IconButton onClick={() => handleDelete(index)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </>
            );
          })}

          <section />
        </FormPaper>
        <FormPaper title="Responsáveis">
          <Grid container spacing={24}>
            <Grid item lg={3}>
              <TextField
                fullWidth
                label="Nome"
                value={responsavel_nome}
                onChange={e => setResponsavelNome(e.target.value)}
              />
            </Grid>
            <Grid item lg={2}>
              <TextField
                fullWidth
                label="CPF"
                value={responsavel_cpf}
                onChange={e => setResponsavelCpf(e.target.value)}
              />
            </Grid>
            <Grid item lg={2}>
              <TextField
                fullWidth
                value={responsavel_telefone}
                label="Telefone"
                onChange={e => setResponsavelTelefone(e.target.value)}
              />
            </Grid>
            <Grid item lg={2}>
              <TextField
                fullWidth
                label={'Email'}
                value={responsavel_email}
                onChange={e => setResponsavelEmail(e.target.value)}
              />
            </Grid>
            <Grid item lg={2}>
              <Button
                variant="contained"
                color="primary"
                margin="dense"
                onClick={() => handleAddResponsaveis()}>
                + Adicionar
              </Button>
            </Grid>
          </Grid>
          {responsaveis.map((responsavel, index) => {
            return (
              <>
                <Grid container spacing={24}>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      label="Nome"
                      value={responsaveis[index].nome}
                      onChange={e =>
                        handleChangeResponsavel(index, 'nome', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <TextField
                      fullWidth
                      label="CPF"
                      value={responsaveis[index].cpf}
                      onChange={e =>
                        handleChangeResponsavel(index, 'cpf', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <TextField
                      fullWidth
                      label="Telefone"
                      value={responsaveis[index].telefone}
                      onChange={e =>
                        handleChangeResponsavel(
                          index,
                          'telefone',
                          e.target.value,
                        )
                      }
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <TextField
                      fullWidth
                      label="Email"
                      value={responsaveis[index].email}
                      onChange={e =>
                        handleChangeResponsavel(index, 'email', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    lg={2}
                    style={{
                      marginTop: 10,
                    }}>
                    <IconButton onClick={() => handleResponsavelDelete(index)}>
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </>
            );
          })}
          <section />
        </FormPaper>
        <FormPaper title="Logo da Empresa">
          <Grid container spacing={24}>
            <Grid item lg={6}>
              <input
                accept="image/*"
                id="image-upload"
                type="file"
                style={{display: 'none'}}
                onChange={e => handleFileChange(e)}
              />
              <label htmlFor="image-upload">
                <Button variant="contained" component="span">
                  <AddAPhoto />
                </Button>
              </label>
            </Grid>
            <Grid item lg={6}>
              {avatar ? (
                <Avatar
                  src={avatar}
                  style={{objectFit: 'cover'}}
                  width="200"
                  height="200"
                />
              ) : null}
            </Grid>
          </Grid>
        </FormPaper>
        <Grid container spacing={24} style={{marginTop: 20}}>
          <Grid item lg={12}>
            <Button type="submit" variant="contained" color="primary">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Main>
  );
}
