// @format
import React from 'react'
import PropTypes from 'prop-types'

// @material-ui
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'

// helpers
import {
  filtraStatus,
  formatCurrency,
  toDate,
} from '../../../../../../components/Helpers'

const styles = {
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 400,
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
}

function Transition(props) {
  return <Slide direction="up" {...props} />
}

class FullScreenDialog extends React.Component {
  state = {
    open: false,
  }

  componentDidMount() {
    console.log(this.props.data)
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Button
          style={{ position: 'absolute', right: 20, top: 70 }}
          variant="contained"
          color="primary"
          onClick={this.handleClickOpen}
        >
          Ver Negociação
        </Button>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={this.handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                Valor Negociado
                {this.props.data.recurso.negociacao
                  ? formatCurrency(
                      this.props.data.recurso.negociacao.valorAcordado
                    )
                  : null}
              </Typography>
              <Button color="inherit" onClick={this.handleClose}>
                fechar
              </Button>
            </Toolbar>
          </AppBar>
          <Grid container spacing={24}>
            <Grid item lg={6} style={{ padding: 40 }}>
              <Grid container spacing={24}>
                <Grid item lg={4}>
                  <TextField
                    label="Valor Acordado"
                    fullWidth
                    value={
                      this.props.data.recurso.negociacao
                        ? formatCurrency(
                            this.props.data.recurso.negociacao.valorAcordado
                          )
                        : null
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Juros da Negociação"
                    fullWidth
                    value={
                      this.props.data.recurso.negociacao
                        ? this.props.data.recurso.negociacao.juros
                        : null
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Quantidade de Parcelas"
                    fullWidth
                    value={
                      this.props.data.recurso.negociacao.parcelas
                        ? this.props.data.recurso.negociacao.parcelas.length - 1
                        : null
                    }
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Valor da Entrada"
                    fullWidth
                    value={formatCurrency(
                      this.props.data.recurso.negociacao.parcelas
                        ? this.props.data.recurso.negociacao.parcelas[0].valor
                        : null
                    )}
                  />
                </Grid>
                <Grid item lg={4}>
                  <TextField
                    label="Dia do Vencimento"
                    fullWidth
                    value={
                      this.props.data.recurso.negociacao
                        ? this.props.data.recurso.negociacao.dia_vencimento
                        : null
                    }
                  />
                </Grid>
                <Grid item lg={12} style={{ height: 500, overflow: 'scroll' }}>
                  {this.props.data.recurso.negociacao ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.data.recurso.html
                          ? this.props.data.recurso.html.termo
                          : null,
                      }}
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6}>
              {this.props.data.recurso.negociacao ? (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell width="100">Vencimento</TableCell>
                      <TableCell align="left">Valor</TableCell>
                      <TableCell align="left">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.props.data.recurso.negociacao.parcelas &&
                      this.props.data.recurso.negociacao.parcelas.map(row => (
                        <TableRow key={row.id}>
                          <TableCell component="th" scope="row">
                            {toDate(row.vencimento)}
                          </TableCell>
                          <TableCell align="left">
                            {formatCurrency(row.valor)}
                          </TableCell>
                          <TableCell align="left">
                            {filtraStatus(row.status)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              ) : null}
            </Grid>
          </Grid>
        </Dialog>
      </div>
    )
  }
}

FullScreenDialog.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FullScreenDialog)
