import styled from 'styled-components';
import bg from './img/bg.jpg';
import background from './img/background.jpg';
import paletaImg from './img/linha.jpg';
import logo from './img/positivelogo.png';

export const Roboto = 'Roboto';

export const DarkGreen = '#033c45';

export const Green = '#1ba191';

export const FlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  width: 60%;
  background-image: url(${bg});
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    min-height: 100px;
  }
`;

export const InfoContainer = styled.div`
  width: 50%;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
    min-height: 100px;
  }
`;

export const Paleta = styled.div`
  width: 100%;
  background-image: url(${paletaImg});
  height: 19px;
  background-position: center;
`;

export const Title = styled.h1`
  font-family: ${Roboto};
  font-size: 3.5em;
  width: 60%;
  padding: 40px 40px 0 40px;
  margin: 0;
  color: #1ba191;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const SubTitle = styled.p`
  padding: 0 40px 0 40px;
  color: #7d7d7d;
  font-size: 1.3em;
  font-family: ${Roboto};
  @media (max-width: 768px) {
    font-size: 1em;
  }
`;

export const InputContainer = styled.div`
  margin-top: 40px;
  padding: 0 40px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CpfButton = styled.button`
  width: 200px;
  font-family: ${Roboto};
  font-size: 1em;
  background-color: #1ba191;
  border-radius: 30px;
  margin-top: 20px;
  border: none;
  height: 50px;
  color: #fff;
  align-self: center;
  &:focus {
    border: none;
    outline: none;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 20% !important;
  min-height: 100vh;
  height: auto;
  background-color: ${DarkGreen};
`;

export const PositiveLogo = styled.img.attrs({
  src: logo
})`
  width: 70%;
  align-self: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const MeusAcordos = styled.h2`
  font-family: ${Roboto};
  font-size: 3em;
  margin: 0 0 40px 0;
  color: ${Green};
`;

export const MenuItem = styled.h4`
  font-family: ${Roboto};
  font-size: 1.3em;
  color: #fff;
  font-weight: 100;
  padding-left: 15%;
  padding-bottom: 0;
  margin: 20px 0 0 0;
`;

export const Body = styled.div`
  width: 80%;
  padding: 20px;
`;

export const AceitarHeader = styled.div`
  background-image: url(${background});
  width: 100%;
  height: 250px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Paper = styled.div`
  background: #fff;
  width: 80%;
  padding: 30px;
  border-radius: 5px;
  align-self: center;
  min-height: 400px;
  height: auto;
  -webkit-box-shadow: 0px 3px 39px 2px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 0px 3px 39px 2px rgba(0, 0, 0, 0.43);
  box-shadow: 0px 3px 39px 2px rgba(0, 0, 0, 0.43);
`;

export const DividaInfo = styled.div`
  display: flex;
  width: 80%;
  margin-top: -160px;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
`;

export const Valor = styled.div`
  color: #fff;
  font-size: 1.9em;
  padding: 10px;
  font-weight: 900;
  margin-bottom: 20px;
  font-family: ${Roboto};
`;

export const ValorContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ValorDescription = styled.div`
  color: #fff;
  font-family: 'Roboto';
  padding-left: 10px;
  margin: 0;
`;
