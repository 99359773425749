import React, { Fragment } from "react";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { DatePicker } from "material-ui-pickers";
import api from "../../config/Api";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddBox from "@material-ui/icons/AddBox";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { Field } from "redux-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import "./Fields.css";

// Importa as máscaras de campos
import { phoneMask, cpfMask } from "../../components/Mascaras";
import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-br");

export const renderSelectField = field => {
  const {
    meta: { touched, error }
  } = field;
  return (
    <TextField
      select
      label={field.label}
      fullWidth
      helpertext={touched ? error : ""}
      error={touched && error ? true : false}
      {...field.input} //capta as mudanças no input
    >
      {field.array.map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const renderField = field => {
  const {
    meta: { touched, error }
  } = field;
  return (
    <TextField
      {...field.input}
      type={field.type}
      placeholder={field.placeholder}
      helperText={touched ? error : ""}
      error={touched && error ? true : false}
      label={field.label}
      fullWidth
    />
  );
};

export const renderSearchField = field => {
  return (
    <input
      className="search-field"
      type="text"
      label={field.label}
      placeholder={field.label}
      required
      {...field.input}
    />
  );
};

const tipoContas = [
  {
    value: "0",
    label: "Conta Corrente"
  },
  {
    value: "1",
    label: "Conta Poupança"
  }
];

export const renderBancos = ({ fields, meta: { error, submitFailed } }) => (
  <Grid container spacing={24}>
    <Grid item lg={12} style={{ padding: "15px 40px 15px 40px" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => fields.push({})}
      >
        <AddBox fontSize="small" style={{ marginRight: 5 }} />
        Adicionar Banco
      </Button>
    </Grid>

    {fields.map((banco, index) => (
      <Grid
        container
        spacing={24}
        key={index}
        style={{ padding: "15px 40px 15px 40px" }}
      >
        <Grid item lg={3}>
          <Field
            name={`${banco}.nome`}
            type="text"
            component={renderField}
            label="Banco"
          />
        </Grid>

        <Grid item lg={3}>
          <Field
            name={`${banco}.tipo_conta`}
            type="number"
            component={renderSelectField}
            label="Tipo de Conta"
            array={tipoContas}
          />
        </Grid>
        <Grid item lg={2}>
          <Field
            name={`${banco}.agencia`}
            type="number"
            component={renderField}
            label="Agência"
          />
        </Grid>
        <Grid item lg={3}>
          <Field
            name={`${banco}.conta`}
            type="number"
            component={renderField}
            label="Número da Conta"
          />
        </Grid>
        <Grid item lg={1}>
          <IconButton
            onClick={() => {
              fields.remove(index);
              api.delete(`/bancos/${fields.get(index).id}`);
            }}
            aria-label="Delete"
            color="secondary"
            style={{ marginTop: 10 }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </Grid>
);

export const renderResponsaveis = ({
  fields,
  meta: { error, submitFailed }
}) => (
  <Grid container spacing={24}>
    <Grid item lg={12} style={{ padding: "15px 40px 15px 40px" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => fields.push({})}
      >
        <AddBox fontSize="small" style={{ marginRight: 5 }} />
        Adicionar Responsável
      </Button>
    </Grid>

    {fields.map((responsavel, index) => (
      <Grid
        container
        spacing={24}
        key={index}
        style={{ padding: "15px 40px 15px 40px" }}
      >
        <Grid item lg={3}>
          <Field
            name={`${responsavel}.nome`}
            type="text"
            component={renderField}
            label="Nome"
          />
        </Grid>

        <Grid item lg={3}>
          <Field
            name={`${responsavel}.cpf`}
            type="text"
            component={renderField}
            label="CPF"
            {...cpfMask}
          />
        </Grid>
        <Grid item lg={2}>
          <Field
            name={`${responsavel}.telefone`}
            type="text"
            component={renderField}
            label="Telefone"
            {...phoneMask}
          />
        </Grid>
        <Grid item lg={3}>
          <Field
            name={`${responsavel}.email`}
            type="text"
            component={renderField}
            label="Email"
          />
        </Grid>
        <Grid item lg={1}>
          <IconButton
            onClick={() => {
              fields.remove(index);
              api.delete(`/responsavels/${fields.get(index).id}`);
            }}
            aria-label="Delete"
            color="secondary"
            style={{ marginTop: 10 }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </Grid>
);

export const renderTextField = field => {
  const {
    meta: { touched, error }
  } = field;
  return (
    <TextField
      label={field.label}
      multiline
      fullWidth
      rowsMax="8"
      margin="normal"
      helperText={touched ? error : ""}
      error={touched && error ? true : false}
      {...field.input} //Capta as mudanças no input
    />
  );
};

export const renderDataField = field => {
  const {
    meta: { touched, error }
  } = field;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        error={touched && error ? true : false}
        keyboard
        helperText={touched ? error : ""}
        placeholder="Data"
        format="DD/MM/YYYY"
        label={field.label}
        {...field.input}
      />
    </MuiPickersUtilsProvider>
  );
};

export const renderParcelas = ({ fields, meta: { error, submitFailed } }) => (
  <Grid container spacing={24}>
    <Grid item lg={12} style={{ padding: "15px 40px 15px 40px" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => fields.push({})}
      >
        <AddBox fontSize="small" style={{ marginRight: 5 }} />
        Adicionar Parcela
      </Button>
    </Grid>

    {fields.map((parcela, index) => (
      <Grid
        container
        spacing={24}
        key={index}
        style={{ padding: "15px 40px 15px 40px" }}
      >
        <Grid item lg={5}>
          <Field
            name={`${parcela}.valor`}
            type="text"
            component={renderField}
            label="Valor"
          />
        </Grid>

        <Grid item lg={3}>
          <Field
            name={`${parcela}.vencimento`}
            type="text"
            component={renderDataField}
            label="Data de Vencimento"
          />
        </Grid>

        <Grid item lg={1}>
          <IconButton
            onClick={() => {
              fields.remove(index);
              api.delete(`/parcelas/${fields.get(index).id}`);
            }}
            aria-label="Delete"
            color="secondary"
            style={{ marginTop: 10 }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </Grid>
);

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

export const renderFileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: omitMeta,
  ...props
}) => {
  return (
    <Fragment>
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        id="flat-button-file"
        accept="application/pdf"
        {...inputProps}
        {...props}
      />
    </Fragment>
  );
};

export const renderSwitchField = field => {
  return (
    <FormControlLabel
      control={
        <Switch
          {...field.input}
          value={field.name}
          checked={field.input.value ? true : false}
        />
      }
      label={field.label}
    />
  );
};

export const renderAnexos = ({ fields, meta: { error, submitFailed } }) => (
  <Grid container spacing={24}>
    <Grid item lg={12} style={{ padding: "15px 40px 15px 40px" }}>
      <Button
        variant="contained"
        color="primary"
        onClick={() => fields.push({})}
      >
        <AddBox fontSize="small" style={{ marginRight: 5 }} />
        Adicionar Anexo
      </Button>
    </Grid>

    {fields.map((anexo, index) => (
      <Grid
        container
        spacing={24}
        key={index}
        style={{ padding: "15px 40px 15px 40px" }}
      >
        <Grid item lg={5}>
          <Field
            name={`${anexo}.arquivo`}
            component={renderFileInput}
            label="Arquivo"
          />
        </Grid>

        <Grid item lg={3}>
          <Field
            name={`${anexo}.nome`}
            type="text"
            component={renderField}
            label="Titulo"
          />
        </Grid>

        <Grid item lg={1}>
          <IconButton
            onClick={() => {
              fields.remove(index);
              api.delete(`/anexos/${fields.get(index).id}`);
            }}
            aria-label="Delete"
            color="secondary"
            style={{ marginTop: 10 }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    ))}
  </Grid>
);
