import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import api from '../../config/Api';
import swal from '@sweetalert/with-react';

import {withRouter} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';

// Custom Components
import Main from '../../components/Main';
import Bar from '../../components/Appbar';
import UsuarioList from './UsuarioList';
import Loading from '../../components/Loading';

// Icons
import Delete from '@material-ui/icons/Delete';
import Replay from '@material-ui/icons/Replay';

function TabContainer(props) {
  return <div>{props.children}</div>;
}

class Usuarios extends Component {
  state = {
    usuarios: [],
    desativados: [],
    usuario: {},
    loading: true,
    value: 0,
  };

  componentDidMount() {
    this.getUser();
    this.getData();
    this.getDesativados();
  }

  handleChange = (event, value) => {
    this.setState({value});
  };

  getUser() {
    const token = localStorage.getItem('usuario');
    const json = JSON.parse(token);
    this.setState({user: json.me}, () => this.checkAccess());
    return <Redirect to={{pathname: '/clientes'}} />;
  }

  checkAccess() {
    if (this.state.user.grupo_nome === 'gerente_executivo') {
      return null;
    } else {
      this.props.history.push('/clientes');
    }
  }

  getData(usuario = '') {
    api.get('/api/v1/usuario').then(resp =>
      this.setState({
        ...this.state,
        usuario,
        usuarios: resp.data.data,
        loading: false,
      }),
    );
  }

  getDesativados(usuario = '') {
    api.get('/api/v1/usuarios-desativados').then(resp =>
      this.setState({
        ...this.state,
        desativados: resp.data.data,
        loading: false,
      }),
    );
  }

  ativaUsuario = usuario => {
    swal({
      title: 'Você tem certeza?',
      text: 'Deseja reativar o usuário?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        api.put(`/api/v1/ativa-usuario/${usuario.id}`);
        let copia = [...this.state.desativados];
        let novo = copia.filter(item => item.id !== usuario.id);
        this.setState({
          desativados: novo,
          usuarios: [...this.state.usuarios, usuario],
        });

        swal('Usuário reativado com sucesso!', {
          icon: 'success',
        });
      } else {
        swal('Usuário não foi reativado!');
      }
    });
  };

  removeUsuario = usuario => {
    swal({
      title: 'Você tem certeza?',
      text: 'Depois de deletado este usuário ainda pode ser recuperado!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        api.delete(`/api/v1/usuario/${usuario.id}`);
        let copia = [...this.state.usuarios];
        let novo = copia.filter(item => item.id !== usuario.id);
        this.setState({
          usuarios: novo,
          desativados: [...this.state.desativados, usuario],
        });
        swal('Usuário deletado com sucesso!', {
          icon: 'success',
        });
      } else {
        swal('Usuário não foi deletado!');
      }
    });
  };

  render() {
    const {usuarios, loading, desativados} = this.state;
    return (
      <Main>
        <Bar title="Usuários" nome="Novo Usuário" link="novo-usuario" />

        <Paper>
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            centered>
            <Tab label="Ativos" />

            <Tab label="Desativados">
              <p>desativados</p>
            </Tab>
          </Tabs>
        </Paper>
        {this.state.value === 0 && (
          <TabContainer>
            <Loading loading={loading} />
            {usuarios.map((usuario, index) => {
              return (
                <UsuarioList
                  key={usuario.id}
                  nome={`${usuario.nome} ${usuario.sobrenome}`}
                  email={usuario.email}
                  unidade="Rio Branco"
                  link={`/novo-usuario`}
                  editar={`/editar-usuario/${usuario.id}`}>
                  <Button
                    mini
                    color="primary"
                    onClick={() => this.removeUsuario(usuario, index)}>
                    <Delete fontSize="small" />
                  </Button>
                </UsuarioList>
              );
            })}
          </TabContainer>
        )}
        {this.state.value === 1 && (
          <TabContainer>
            <Loading loading={loading} />
            {desativados.map((usuario, index) => {
              return (
                <UsuarioList
                  key={usuario.id}
                  nome={`${usuario.nome} ${usuario.sobrenome}`}
                  unidade="Rio Branco"
                  email={usuario.email}
                  telefone=""
                  link={`/novo-usuario`}
                  editar={`/editar-usuario/${usuario.id}`}>
                  <Button
                    mini
                    color="primary"
                    onClick={() => this.ativaUsuario(usuario, index)}>
                    <Replay fontSize="small" />
                  </Button>
                </UsuarioList>
              );
            })}
          </TabContainer>
        )}
      </Main>
    );
  }
}

export default withRouter(Usuarios);
