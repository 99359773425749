/*
 *
 *
 *
 */

export const toDate = date => {
	let data = new Date(date)
	return data.toLocaleDateString()
}

/*
 *  Utiliza a API nativa do Javascript de internacionalizacão
 *  para converter um determinado valor em formato de moeda.
 */
export const formatCurrency = value => {
	const formatter = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		minimumFractionDigits: 2,
	})

	return formatter.format(value)
}

// Filtra os status do Iugu

export const filtraStatus = status => {
	const obj = {
		pending: 'Pendente',
		paid: 'Pago',
		canceled: 'Canelado',
	}

	return obj[status]
}

export const filtraDividaStatus = status => {
	const obj = [
		{
			id: 1,
			name: 'A contactar',
		},
		{
			id: 2,
			name: 'Não Localizado',
		},
		{
			id: 3,
			name: 'Cumprindo Senteça',
		},
		{
			id: 4,
			name: 'Acão Judicial',
		},
		{
			id: 5,
			name: 'Quebra de Acordo',
		},
		{
			id: 6,
			name: 'Liquidado',
		},
		{
			id: 7,
			name: 'Não Localizado',
		},
		{
			id: 8,
			name: 'Em Análise',
		},
		{
			id: 9,
			name: 'Descumprindo Sentença',
		},
		{
			id: 10,
			name: 'Cumprindo Acordo',
		},
		{
			id: 11,
			name: 'Termo de Acordo Enviado',
		},
		{
			id: 12,
			name: 'Execução Judicial',
		},
		{
			id: 13,
			name: 'Ajuizar',
		},
	]
	const found = obj.find(element => element.id === status)
	return found.name
}

export const cpfMask = value => {
	return value
		.replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
		.replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const telefoneMask = value => {
	return value
		.replace(/\D/g, '')
		.replace(/(\d{2})(\d)/, '($1) $2 ')
		.replace(/(\d{3})(\d)/, '$1$2-')
}
