import React from 'react'

const ProgressBar = props => {
	const { bgcolor, completed } = props

	const containerStyles = {
		height: 20,
		backgroundColor: 'rgba(159, 173, 224, 0.3)',
		borderRadius: 50,
		marginBottom: 50,
	}

	const fillerStyles = {
		height: '100%',
		width: `${completed}%`,
		backgroundColor: bgcolor,
		borderRadius: 'inherit',
		textAlign: 'right',
	}

	const labelStyles = {
		padding: 5,
		color: 'white',
		fontWeight: 'bold',
	}

	return (
		<div style={containerStyles}>
			<div style={fillerStyles}>
				<span style={labelStyles}>{`${completed}%`}</span>
			</div>
		</div>
	)
}

export default ProgressBar
