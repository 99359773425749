import moment from "moment";
import {
  GET_REMESSA,
  GET_REMESSAS,
  ADD_REMESSA,
  UPDATE_REMESSA,
  GET_DIVIDAS
} from "./constants";

const initialState = {
  remessa: {
    dt_recebimento: moment(new Date(), "DD-MM-YYYY"),
    cliente_id: null,
    dividas: []
  },
  dividas: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_REMESSA:
      return { ...state, remessa: action.payload };
    case GET_REMESSAS:
      return { ...state, remessas: action.payload };
    case ADD_REMESSA:
      return { ...state };
    case UPDATE_REMESSA:
      return { ...state, remessa: action.payload };
    case GET_DIVIDAS:
      return { ...state, dividas: action.payload };
    default:
      return state;
  }
}
