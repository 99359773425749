import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "./Divida.css";

export const ParcelaItem = props => {
  return (
    <Grid className="parcela-item-container" container spacig={24}>
      <Grid item lg={3} xs={3}>
        <Typography variant="caption">Valor Original</Typography>
        <Typography variant="body1">{props.valorOriginal}</Typography>
      </Grid>
      <Grid item lg={3} xs={3}>
        <Typography variant="caption">Valor Corrigido</Typography>
        <Typography variant="body1">{props.valorCorrigido}</Typography>
      </Grid>
      <Grid item lg={3} xs={3}>
        <Typography variant="caption">Vencido Em</Typography>
        <Typography variant="body1">{props.dataVencimento}</Typography>
      </Grid>
    </Grid>
  );
};

export default ParcelaItem;
