import React, {Component, Fragment} from 'react';
import CpfBox from './CpfBox';
import './Termo.css';

export default class TermoAceite extends Component {
  state = {
    cpf: '',
    negociacoes: [],
  };

  handleChange = this.handleChange.bind(this);
  componentDidMount() {}

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  render() {
    return (
      <Fragment>
        <CpfBox change={this.handleChange} cpf={this.state.cpf} />
      </Fragment>
    );
  }
}
