import React, {Fragment} from 'react';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Create from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';

const styles = theme => ({
  listItem: {
    width: '100%',
    height: 64,
    background: '#fff',
    borderRadius: 5,
    marginTop: 20,
    padding: 15,
    boxShadow: '0px 2px 4.54px 1.46px rgba(0, 0, 0, 0.13)',
  },
});

export const UsuarioList = props => {
  const {classes} = props;
  return (
    <Fragment>
      <Grid container className={classes.listItem}>
        <Grid item lg={2}>
          <Typography variant="caption">Nome</Typography>
          <Typography variant="body1">{props.nome}</Typography>
        </Grid>
        <Grid item lg={3}>
          <Typography variant="caption">Email</Typography>
          <Typography variant="body1">{props.email}</Typography>
        </Grid>
        <Grid item lg={5}>
          <Typography variant="caption">Unidade</Typography>
          <Typography variant="body1">{props.unidade}</Typography>
        </Grid>
        <Grid item lg={2} align="right">
          {/* <Link to={`${props.link}`}>
            <Button
              mini
              color="primary"
              aria-label="Add"
              style={{ marginRight: 5 }}
            >
              <VisibilityIcon fontSize="small" />
            </Button>
          </Link> */}
          <Link to={`${props.editar}`}>
            <Button
              mini
              color="primary"
              aria-label="Add"
              style={{marginRight: 5}}>
              <Create fontSize="small" />
            </Button>
          </Link>
          {props.children}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withStyles(styles, {withTheme: true})(UsuarioList);
