import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { withRouter, Link } from 'react-router-dom'
import swal from '@sweetalert/with-react'

import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
// @material-ui/core import CircularProgress from '@material-ui/core/CircularProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'

// icons
import Visibility from '@material-ui/icons/Visibility'
import Delete from '@material-ui/icons/Delete'
import Receipt from '@material-ui/icons/Receipt'
import Inbox from '@material-ui/icons/Inbox'
import Create from '@material-ui/icons/Create'

// Services
import api from '../../config/Api'

// Custom Components
import Main from '../../components/Main'
import Bar from '../../components/Appbar'
import AppBar from '../../components/Appbar'
import Breadcrumb from './Breadcrumb'

export function Cliente({ match, history }) {
	const [localState, setState] = useState({})
	const [remessas, setRemessas] = useState([])
	const [current, setCurrent] = useState(1)
	const { id } = match.params

	useEffect(() => {
		getRemessas(1)
	}, [])

	async function getRemessas(page) {
		try {
			const remessas = await api.get(
				`/api/v1/pessoas/clientes/${id}/remessas?page=${page}`
			)
			setState({
				page: remessas.data.page,
				per_page: remessas.data.per_page,
				total: remessas.data.total,
				loading: false,
			})
			setRemessas(remessas.data.data)
		} catch (err) {
			console.warn(err)
		}
	}
	// Paginacao
	const onChange = (page) => {
		setState({ page: page })
		getRemessas(page)
		window.scrollTo(0, 0)
	}

	const goTo = (remessaId, remessa) => {
		history.push({
			pathname: `/cliente/${id}/remessa/${remessaId}`,
			query: remessa,
		})
	}

	const novaRemessa = () => {
		history.push(`/cliente/${id}/nova-remessa`)
	}
	const generatePdf = async () => {
		const lista = await api.get(`/api/v1/dividas/${id}`)
		swal(`http://app.positive.net.br/uploads${lista.data.url}`)
	}

	return (
		<Main>
			<AppBar
				redireciona={novaRemessa}
				color="#556daa"
				title="Remessas"
				icon={<Inbox />}
			>
				<IconButton onClick={generatePdf} mini style={{ color: '#fff' }}>
					<Receipt />
				</IconButton>
			</AppBar>
			<Paper style={{ padding: 20 }}>
				<List>
					{remessas.map((remessa) => {
						return (
							<>
								<ListItem onClick={() => goTo(remessa.id, remessa)} button>
									<ListItemAvatar>
										<Avatar>
											<Inbox />
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={remessa.numero}
										style={{ width: 300 }}
										secondary="Número"
									/>
									<ListItemText
										primary={remessa.descricao}
										style={{ width: 300 }}
										secondary="Descrição"
									/>
									<ListItemText
										primary={remessa.dividas}
										style={{ width: 300 }}
										secondary="Qtd de Dívidas"
									/>
									<ListItemText
										primary={moment(remessa.dt_recebimento).format(
											'DD/MM/YYYY'
										)}
										style={{ width: 300 }}
										secondary="Data de Recebimento"
									/>
									<ListItemSecondaryAction>
										<Link
											to={{
												pathname: `/cliente/${id}/remessa/${remessa.id}`,
												query: remessa,
											}}
										>
											<IconButton>
												<Visibility />
											</IconButton>
										</Link>
										<Link to={`/editar/cliente/${id}/remessa/${remessa.id}`}>
											<IconButton>
												<Create />
											</IconButton>
										</Link>
									</ListItemSecondaryAction>
								</ListItem>
								<Divider />
							</>
						)
					})}
				</List>
			</Paper>
			<Pagination
				onChange={onChange}
				current={localState.page}
				total={localState.total}
			/>
		</Main>
	)
}

export default withRouter(Cliente)
