import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
// import { Field } from "react-final-form";

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// const onSubmit = async values => {
//   await sleep(300);
//   window.alert(JSON.stringify(values, 0, 2));
// };

// const Error = ({ name }) => (
//   <Field
//     name={name}
//     subscribe={{ touched: true, error: true }}
//     render={({ meta: { touched, error } }) =>
//       touched && error ? <span>{error}</span> : null
//     }
//   />
// );

// const required = value => (value ? undefined : "Required");

class Debit extends React.Component {
  render() {
    // const { classes } = this.props;
    return <Fragment />;
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  }
});

export default withStyles(styles)(Debit);
