import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading({loading}) {
  return (
    <>
      {loading && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 500,
            fontFamily: 'Roboto',
          }}>
          <CircularProgress />
          <p>Carregando</p>
        </div>
      )}
    </>
  );
}
