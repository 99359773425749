import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { isAuthenticated } from '../config/Auth'

/*
 * Componente que protege as rotas. Caso o usuårio não possua
 * um token de acesso ele não poderá acessar a rota e
 * será redirecionado para a tela de login.
 */

export const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			isAuthenticated() ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{ pathname: '/login', state: { from: props.location } }}
				/>
			)
		}
	/>
)
