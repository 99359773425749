// @format
import React, {Component, Fragment} from 'react';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import PropTypes from 'prop-types';

// Services
import Api from '../../config/Api';

//Material-UI
import {withStyles} from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

// Custom RECRED components
import Menu from '../../components/Menu';
import Select from '../../components/Select';
import DividaList from './DividaList';

// Other Libraries
import MaskedInput from 'react-text-mask';
import {debounce} from 'lodash';

// Date Utils
import MomentUtils from '@date-io/moment';
import {DatePicker} from 'material-ui-pickers';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const sexo = [
  {
    id: 'm',
    name: 'Masculino',
  },
  {
    id: 'f',
    name: 'Feminino',
  },
];

function CPF(props) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function Domestico(props) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function Celular(props) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function TabContainer({children, dir}) {
  return (
    <Typography component="div" dir={dir} style={{padding: 8 * 3}}>
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

class Devedor extends Component {
  state = {
    devedor: {},
    dividas: [
      {
        id: '',
        devedor: {
          nome_razao_social: '',
        },
      },
    ],
    value: 0,
    endereco: {
      bairro: '',
      complemento: '',
      logradouro: '',
      numero: '',
      localidade: '',
      uf: '',
      cep: '',
    },
    nome_razao_social: '',
    sexo: '',
    rg_inscricao_estadual: '',
    cpf_cnpj: '',
    email: '',
  };
  componentDidMount() {
    this.getDevedor();
    this.getDividas();
  }
  handleTabChange = (event, value) => {
    this.setState({value});
  };

  handleChangeIndex = index => {
    this.setState({value: index});
  };

  changeCep = e => {
    this.setState({
      ...this.state,
      endereco: {
        ...this.state.endereco,
        cep: e,
      },
    });
    this.handleCep(e);
  };

  handleCep = debounce(text => {
    console.log(text);
    this.setState(
      {
        cep: text,
      },
      () => {
        Api.get(`/api/v1/cep/${text}`).then(resp =>
          this.setState({
            ...this.state,
            endereco: {
              ...this.state.endereco,
              ...resp.data,
            },
          }),
        );
      },
    );
  }, 1000);

  dateChange = (event, a) => {
    this.setState({nascimento: event.format('YYYY-MM-DD')});
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleEndereco = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      endereco: {
        ...this.state.endereco,
        [name]: value,
      },
    });
  };

  async getDividas() {
    const {match} = this.props;
    try {
      const dividas = await Api.get(`/api/v1/divida/${match.params.id}`);
      this.setState({dividas: dividas.data.data});
    } catch (err) {
      console.warn(err);
    }
  }

  async getDevedor() {
    const {match} = this.props;
    const request = await Api.get(
      `/api/v1/pessoas/devedores/${match.params.id}`,
    );
    this.setState({...this.state, ...request.data.data});
  }
  render() {
    const {classes, theme} = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <AppBar position="static" color="default">
              <Tabs
                value={this.state.value}
                onChange={this.handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth">
                <Tab label="Informações" />
                <Tab label="Dívidas em Aberto" />
                <Tab label="Dívidas Negociadas" />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={this.state.value}
              onChangeIndex={this.handleChangeIndex}>
              <TabContainer dir={theme.direction}>
                Informações
                <Grid container spacing={24} style={{padding: 20}}>
                  <Grid
                    item
                    lg={12}
                    style={{paddingBottom: 0, marginBottom: 0}}
                  />
                  <Grid item lg={4}>
                    <TextField
                      name="nome_razao_social"
                      fullWidth
                      onChange={this.handleChange}
                      id="standard-"
                      label="Nome"
                      value={this.state.nome_razao_social}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <FormControl>
                      <InputLabel
                        htmlFor="formatted-text-mask-input"
                        style={{paddingTop: 10, marginTop: 10}}>
                        CPF
                      </InputLabel>
                      <Input
                        fullWidth
                        onChange={this.handleChange}
                        value={this.state.cpf_cnpj}
                        margin="none"
                        name="cpf_cnpj"
                        inputComponent={CPF}
                        style={{paddingTop: 16}}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={2}>
                    <TextField
                      fullWidth
                      name="rg_inscricao_estadual"
                      onChange={this.handleChange}
                      id="standard-"
                      label="RG"
                      value={this.state.rg_inscricao_estadual}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        keyboard
                        placeholder="Data"
                        format="DD/MM/YYYY"
                        value={this.state.nascimento}
                        onChange={this.dateChange}
                        label="Nascimento"
                        margin="normal"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      id="standard-"
                      label="Email"
                      name="email"
                      onChange={this.handleChange}
                      value={this.state.email}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <Select
                      array={sexo}
                      value={this.state.sexo}
                      margin="normal"
                      name="sexo"
                      label="Sexo"
                      change={this.handleChange}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="formatted-text-mask-input"
                        style={{paddingTop: 10, marginTop: 10}}>
                        Telefone Celular
                      </InputLabel>
                      <Input
                        fullWidth
                        value={this.state.telefone_celular}
                        margin="none"
                        name="telefone_celular"
                        onChange={this.handleChange}
                        inputComponent={Celular}
                        style={{paddingTop: 16}}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={3}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="formatted-text-mask-input"
                        style={{paddingTop: 10, marginTop: 10}}>
                        Telefone Fixo
                      </InputLabel>
                      <Input
                        fullWidth={true}
                        value={this.state.telefone_fixo}
                        margin="none"
                        name="telefone_fixo"
                        onChange={this.handleChange}
                        inputComponent={Domestico}
                        style={{paddingTop: 16}}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12}>
                    <Typography
                      variant="h2"
                      style={{
                        textAlign: 'left',
                        fontSize: 24,
                        fontWeight: 500,
                        marginBottom: 0,
                        paddingBottom: 0,
                      }}>
                      Endereço
                    </Typography>
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      label="CEP"
                      name="cep"
                      onChange={e => this.changeCep(e.target.value)}
                      value={this.state.endereco.cep}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      name="logradouro"
                      label="Rua"
                      onChange={this.handleEndereco}
                      margin="normal"
                      value={this.state.endereco.logradouro}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      name="bairro"
                      label="Bairro"
                      margin="normal"
                      onChange={this.handleEndereco}
                      value={this.state.endereco.bairro}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      name="numero"
                      onChange={this.handleEndereco}
                      label="Número"
                      margin="normal"
                      value={this.state.endereco.numero}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      name="localidade"
                      onChange={this.handleEndereco}
                      label="Cidade"
                      margin="normal"
                      value={this.state.endereco.localidade}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      name="estado"
                      label="Estado"
                      onChange={this.handleEndereco}
                      margin="normal"
                      value={this.state.endereco.uf}
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      name="complemento"
                      onChange={this.handleEndereco}
                      label="Complemento"
                      margin="normal"
                      value={this.state.endereco.complemento}
                    />
                  </Grid>
                </Grid>
              </TabContainer>

              <TabContainer dir={theme.direction}>
                {this.state.dividas.map((divida, index) => (
                  <DividaList
                    key={index}
                    valor={divida.valor}
                    id={divida.id}
                    nome={divida.empresa}
                    link={`/cliente/${divida.pessoa_cliente_id}/remessa/${
                      divida.remessa_id
                    }/divida/${divida.id}`}
                  />
                ))}
              </TabContainer>
              <TabContainer dir={theme.direction}>Negociações</TabContainer>
            </SwipeableViews>
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
});

export default withStyles(styles, {withTheme: true})(Devedor);
