export const GET_DIVIDAS = "divida/GET_DIVIDAS";
export const GET_DIVIDA = "divida/GET_DIVIDA";
export const ADD_DIVIDA = "divida/ADD_DIVIDA";
export const UPDATE_DIVIDA = "divida/UPDATE_DIVIDA";
export const ADD_DEVEDOR = "divida/ADD_DEVEDOR";
export const GET_DEVEDOR = "divida/GET_DEVEDOR";
export const SEARCH_DEVEDOR = "divida/SEARCH_DEVEDOR";
export const ADD_CONTRATO = "divida/ADD_CONTRATO";
export const GET_CONTRATO = "divida/GET_CONTRATO";
export const ADD_ARQUIVO = "divida/ADD_ARQUIVO";
export const GET_ARQUIVO = "divida/GET_ARQUIVO";
export const GET_ARQUIVOS = "divida/GET_ARQUIVOS";
export const SEARCH_DEVEDORES = "divida/SERACH_DEVEDORES";
export const GET_DEVEDOR_DIVIDA = "divida/GET_DEVEDOR_DIVIDA";
export const GET_PARCELAS = "divida/GET_PARCELAS";
