import React from 'react'
import Alarm from '@material-ui/icons/Alarm'
import Person from '@material-ui/icons/Person'
import AttachFile from '@material-ui/icons/AttachFile'

import './Divida.css'

export const HistoricoItem = props => {
  return (
    <div className="historico-item">
      <p>{props.historico}</p>
      <div className="historico-item-info">
        {props.anexo ? (
          <a
            href={`http://app.positive.net${props.anexo}`}
            rel="noopener noreferrer"
            target="_blank"
            style={{ textDecoration: 'none', color: '#000' }}
          >
            <p className="historico-hora" style={{ cursor: 'pointer' }}>
              <AttachFile style={{ marginBottom: -4, fontSize: 19 }} />
              Anexo{' '}
            </p>
          </a>
        ) : null}
        <p className="historico-hora">
          <Alarm style={{ marginBottom: -4, fontSize: 19 }} />
          {props.data}{' '}
        </p>
        <p className="historico-atendente">
          <Person style={{ marginBottom: -4, fontSize: 19 }} /> {props.usuario}
        </p>
      </div>
    </div>
  )
}

export default HistoricoItem
