import React, { Fragment } from "react";
import HistoricoItem from "./HistoricoItem";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Send from "@material-ui/icons/Send";
import Attachment from "@material-ui/icons/Attachment";
import "./Divida.css";

const toDate = date => {
  let data = new Date(date);
  return data.toLocaleDateString();
};

export const Historico = props => {
  const { classes } = props;
  return (
    <Fragment>
      <div className="historico-bar">
        <h5>Histórico</h5>
      </div>
      <div className="historico">
        <div className="historico-content">
          {props.comunicacoes.map(historico => {
            return (
              <HistoricoItem
                key={historico.id}
                historico={historico.recurso.historico}
                data={toDate(historico.criado_em)}
                anexo={historico.recurso.url}
                usuario={historico.usuario}
              />
            );
          })}
        </div>
      </div>
      <div className="historico-footer">
        <form onSubmit={props.enviar}>
          <input
            accept="application/pdf"
            className={classes.input}
            onChange={props.fileChange.bind(this)}
            id="text-button-file"
            type="file"
          />
          <label htmlFor="text-button-file">
            <Button component="span" className={classes.button}>
              <Attachment />
            </Button>
          </label>
          <Button
            // onClick={props.enviar}
            type="submit"
            style={{
              position: "absolute",
              right: 0,
              height: 40,
              borderRadius: 0,
              background: "#dbdab3"
            }}
          >
            <Send style={{ marginRight: 6 }} />
            Enviar
          </Button>
          <input
            type="text"
            placeholder="Descreva o atendimento"
            onChange={props.change}
            value={props.historico}
            className="historico-input"
          />
        </form>
      </div>
    </Fragment>
  );
};

const styles = theme => ({
  button: {
    position: "absolute",
    right: 110,
    borderRadius: 0
  },
  input: {
    display: "none"
  }
});

export default withStyles(styles)(Historico);
