// @format
import axios from 'axios'
import { getToken } from './Auth'
export const url = 'https://app.positive.net.br/uploads'
export const rota =
  process.env.NODE_ENV === 'production'
    ? 'https://app.positive.net.br/uploads'
    : 'http://localhost:8500/uploads'

const baseUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://app.positive.net.br'
    : 'http://localhost:3000'

/*
 * Cria um instância do axios com o nome api e define
 * uma URL padrão.
 */
const api = axios.create({
  baseURL: baseUrl,
})

/*
 * Adiciona um interceptor com o header de autorização,
 * desta forma cada request enviará as credenciais
 * do usuário.
 */

api.interceptors.request.use(
  async config => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  error => Promise.reject(error)
)

api.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      localStorage.clear()
      window.location = '/login'
    }
    return error
  }
)

export default api
