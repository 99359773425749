import React, { useEffect } from 'react'
import api from '../../config/Api'
import axios from 'axios'
import InputMask from 'react-input-mask'
import withRouter from 'react-router-dom/withRouter'
import { useForm, Controller } from 'react-hook-form'
import { useStateMachine } from 'little-state-machine'
import moment from 'moment'

// Actions
import updateAction from './updateAction'

// Material Ui import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormHelperText from '@material-ui/core/FormHelperText'
import Button from '@material-ui/core/Button'

import Save from '@material-ui/icons/Save'

// Styled Components
import { Form } from './styles'

// Custom Components
import CadastroContainer from './CadastroContainer'
import FormPaper from '../../components/FormPaper'

export function StepOne({ history }) {
	const { control, register, handleSubmit, errors, setValue } = useForm()
	const { action, state } = useStateMachine(updateAction)

	useEffect(() => {
		register({ name: 'cep' }, { required: true })
	}, [])

	const onSubmit = async data => {
		data.cpf_cnpj = data.cpf_cnpj.replace(/\D/g, '')
		data.telefone_celular = data.telefone_celular.replace(/\D/g, '')
		data.telefone_fixo = data.telefone_fixo.replace(/\D/g, '')
		data.cep = data.cep.replace(/\D/g, '')
		data.endereco = {
			cep: data.cep,
			logradouro: data.logradouro,
			localidade: data.localidade,
			bairro: data.bairro,
			numero: data.numero,
			complemento: data.complemento,
			uf: data.uf,
		}
		delete data.cep
		delete data.logradouro
		delete data.bairro
		delete data.localidade
		delete data.uf
		delete data.complemento

		const request = await api.post('/api/v1/pessoas/devedores', data)
		if (request.status === 500) {
			alert('Ocorreu um erro, contate o Administrador.')
		}
		action({
			devedor: request.data.data,
			progress: 20,
			pessoa_devedor_id: request.data.data.id,
		})
		history.push('/cadastrar-divida/cadastrar-contrato')
	}

	const getCep = async e => {
		const cep = e.target.value.replace(/\D/g, '')
		setValue('cep', cep)
		if (cep.length === 8) {
			const {
				data: { logradouro, bairro, localidade, uf },
			} = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
			setValue('logradouro', logradouro)
			setValue('localidade', localidade)
			setValue('bairro', bairro)
			setValue('uf', uf)
		}
	}

	return (
		<CadastroContainer>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={24}>
					<Grid item lg={12} xs={12}>
						<h2 style={{ fontFamily: 'sans-serif' }}>Cadastrar novo devedor</h2>
					</Grid>
				</Grid>
				<FormPaper color="rgb(89, 98, 121)" title="Informações Pessoais">
					<Grid container spacing={24}>
						<Grid item lg={2} xs={12}>
							<Controller
								as={InputMask}
								control={control}
								name="cpf_cnpj"
								fullWidth
								rules={{ required: true }}
								mask="999.999.999-99"
								defaultValue=""
							>
								{() => (
									<TextField
										error={errors.cpf_cnpj ? true : false}
										helperText={
											errors.cpf_cnpj && ` CPF é um campo obrigatório`
										}
										rules={{ required: true }}
										fullWidth
										label="CPF"
									/>
								)}
							</Controller>
						</Grid>
						<Grid item lg={3} xs={12}>
							<Controller
								as={TextField}
								name="nome_razao_social"
								fullWidth
								error={errors.nome_razao_social ? true : false}
								rules={{ required: true }}
								control={control}
								label="Nome"
								defaultValue=""
								helperText={
									errors.nome_razao_social && 'Nome é um campo obrigatório.'
								}
							/>
						</Grid>
						<Grid item lg={2} xs={12}>
							<Controller
								as={TextField}
								name="rg_inscricao_estadual"
								fullWidth
								control={control}
								label="RG"
								defaultValue=""
							/>
						</Grid>

						<Grid
							item
							lg={2}
							xs={12}
							style={{ display: 'flex', flexDirection: 'column' }}
						>
							<InputLabel>Sexo</InputLabel>
							<Controller
								as={Select}
								error={errors.sexo ? true : false}
								control={control}
								rules={{ required: true }}
								name="sexo"
								defaultValue="m"
							>
								<MenuItem value="m">Masculino</MenuItem>
								<MenuItem value="f">Feminino</MenuItem>
							</Controller>
							{errors.sexo && (
								<FormHelperText>Sexo é um campo obrigatório.</FormHelperText>
							)}
						</Grid>
					</Grid>
				</FormPaper>
				<FormPaper color="rgb(89, 98, 121)" title="Contato">
					<Grid container spacing={24}>
						<Grid item lg={3} xs={12}>
							<Controller
								as={TextField}
								name="email"
								rules={{ required: true }}
								error={errors.email ? true : false}
								fullWidth
								control={control}
								label="Email"
								defaultValue=""
								helperText={errors.email && 'Email é um campo obrigatório.'}
							/>
						</Grid>
						<Grid item lg={3} xs={12}>
							<Controller
								as={InputMask}
								control={control}
								name="telefone_celular"
								fullWidth
								rules={{ required: true }}
								mask="(99) 9 9999-9999"
								defaultValue=""
							>
								{() => (
									<TextField
										error={errors.telefone_celular ? true : false}
										helperText={
											errors.telefone_celular &&
											` Telefone Celular é um campo obrigatório`
										}
										rules={{ required: true }}
										fullWidth
										label="Telefone Celular"
									/>
								)}
							</Controller>
						</Grid>
						<Grid item lg={3} xs={12}>
							<Controller
								as={InputMask}
								control={control}
								name="telefone_fixo"
								fullWidth
								mask="(99) 9999-9999"
								defaultValue=""
							>
								{() => (
									<TextField
										rules={{ required: true }}
										fullWidth
										label="Telefone Fixo"
									/>
								)}
							</Controller>{' '}
						</Grid>
					</Grid>
				</FormPaper>
				<FormPaper color="rgb(89, 98, 121)" title="Endereço">
					<Grid container spacing={24}>
						<Grid item lg={2}>
							<TextField
								name="cep"
								fullWidth
								label="CEP"
								error={errors.cep ? true : false}
								helperText={errors.cep && 'CEP é um campo obrigatório.'}
								onChange={e => getCep(e)}
							/>
						</Grid>
						<Grid item lg={3} xs={12}>
							<Controller
								as={TextField}
								name="logradouro"
								rules={{ required: true }}
								error={errors.logradouro ? true : false}
								fullWidth
								control={control}
								label="Rua"
								defaultValue=""
								helperText={errors.logradouro && 'Rua é um campo obrigatório.'}
							/>
						</Grid>
						<Grid item lg={3} xs={12}>
							<Controller
								as={TextField}
								name="bairro"
								rules={{ required: true }}
								error={errors.bairro ? true : false}
								fullWidth
								control={control}
								label="Bairro"
								defaultValue=""
								helperText={errors.bairro && 'Bairro é um campo obrigatório.'}
							/>
						</Grid>
						<Grid item lg={1} xs={12}>
							<Controller
								as={TextField}
								name="numero"
								rules={{ required: true }}
								error={errors.numero ? true : false}
								fullWidth
								control={control}
								label="Número"
								defaultValue=""
								helperText={errors.numero && 'Número é um campo obrigatório.'}
							/>
						</Grid>
						<Grid item lg={2} xs={12}>
							<Controller
								as={TextField}
								name="localidade"
								rules={{ required: true }}
								error={errors.localidade ? true : false}
								fullWidth
								control={control}
								label="Cidade"
								defaultValue=""
								helperText={
									errors.localidade && 'Cidade é um campo obrigatório.'
								}
							/>
						</Grid>
						<Grid item lg={1} xs={12}>
							<Controller
								as={TextField}
								name="uf"
								rules={{ required: true }}
								error={errors.uf ? true : false}
								fullWidth
								control={control}
								label="UF"
								defaultValue=""
								helperText={errors.localidade && 'UF é um campo obrigatório.'}
							/>
						</Grid>
						<Grid item lg={4} xs={12}>
							<Controller
								as={TextField}
								name="complemento"
								fullWidth
								control={control}
								label="Complemento"
								defaultValue=""
							/>
						</Grid>
					</Grid>
				</FormPaper>

				<Button
					fullWidth
					style={{ marginTop: 20, background: '#596279' }}
					type="submit"
					color="primary"
					variant="contained"
				>
					<Save style={{ marginRight: 10 }} />
					Salvar
				</Button>
			</Form>
		</CadastroContainer>
	)
}

export default withRouter(StepOne)
