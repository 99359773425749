import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import updateAction from '../../CadastroDivida/updateAction'
import api from '../../../config/Api'
import swal from '@sweetalert/with-react'
import {
	filtraDividaStatus,
	cpfMask,
	telefoneMask,
	formatCurrency,
} from '../../../components/Helpers'

// Custom Components
import AppBar from '../../../components/Appbar'
import Main from '../../../components/Main'

import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'

// @material-ui/core
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'

// icons
import Visibility from '@material-ui/icons/Visibility'
import Delete from '@material-ui/icons/Delete'
import Receipt from '@material-ui/icons/Receipt'
import ListIcon from '@material-ui/icons/List'

export function Remessa({ location, match, history }) {
	const { action } = useStateMachine(updateAction)
	const [dividas, setDividas] = useState([])
	const [current, setCurrent] = useState(1)
	const [total, setTotal] = useState('')
	const [teste, setTeste] = useState('')
	const { clienteId, remessaId } = match.params
	useEffect(() => {
		getDividas(1)
	}, [])

	const newDebt = () => {
		action({
			pessoa_cliente_id: parseInt(clienteId),
			remessa_id: parseInt(remessaId),
		})
		console.log(clienteId)
		history.push('/cadastrar-divida')
		console.log(remessaId)
	}

	async function getDividas(page) {
		const { clienteId, remessaId } = match.params
		const dividas = await api.get(
			`/api/v1/pessoas/clientes/${clienteId}/remessas/${remessaId}/dividas?page=${page}`
		)
		setDividas(dividas.data.data)
		setTotal(dividas.data.total)
		setCurrent(dividas.data.page)
	}

	// Paginacao
	const onChange = (page) => {
		setCurrent(page)
		getDividas(page)
		window.scrollTo(0, 0)
	}

	const vaiDivida = (id) => {
		history.push(`/cliente/${clienteId}/remessa/${remessaId}/divida/${id}`)
	}

	const generateExcel = async () => {
		swal(`https://positive-api.herokuapp.com/api/v1/talkeen/${remessaId}.csv`)
	}

	return (
		<Main>
			<AppBar
				redireciona={newDebt}
				color="#596279"
				title={`Dividas - ${location.query ? location.query.descricao : ''}`}
				icon={<Receipt />}
			>
				<IconButton onClick={generateExcel} mini style={{ color: '#fff' }}>
					<ListIcon />
				</IconButton>
			</AppBar>

			<Paper style={{ padding: 20 }}>
				<List>
					{dividas.map((divida) => {
						return (
							<>
								<ListItem button onClick={() => vaiDivida(divida.id)}>
									<ListItemAvatar>
										<Avatar>
											<Receipt />{' '}
										</Avatar>
									</ListItemAvatar>
									<ListItemText
										primary={divida.devedor.nome_razao_social}
										style={{ width: 300 }}
										secondary={cpfMask(divida.devedor.cpf_cnpj)}
									/>
									<ListItemText
										primary={formatCurrency(divida.valor)}
										style={{ width: 100 }}
										secondary="Valor"
									/>
									<ListItemText
										primary={telefoneMask(divida.devedor.telefone_celular)}
										secondary="Telefone Celular"
									/>
									<ListItemText
										primary={filtraDividaStatus(divida.status)}
										secondary="Status"
									/>
									<ListItemSecondaryAction>
										<Link
											to={`/cliente/${clienteId}/remessa/${remessaId}/divida/${
												divida.id
											}`}
										>
											<IconButton>
												<Visibility />
											</IconButton>
										</Link>
										<IconButton onClick={() => alert('oi')}>
											<Delete />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
								<Divider />
							</>
						)
					})}
				</List>
			</Paper>
			<Pagination onChange={onChange} current={current} total={total} />
		</Main>
	)
}

export default withRouter(Remessa)
