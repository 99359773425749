import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { uniqueId } from "lodash";
import Fab from "@material-ui/core/Fab";
import Visibility from "@material-ui/icons/Visibility";
import { ListContainer, Info, ButtonContainer } from "./styles";

const List = props => {
  return (
    <ListContainer>
      <Info>
        {props.lista.map(item => {
          return (
            <div key={uniqueId()}>
              <Typography variant="caption">{item.key}</Typography>
              <Typography variant="body1">{item.value}</Typography>
            </div>
          );
        })}

        <ButtonContainer>
          <Link
            to={{
              pathname: props.view,
              state: props.state
            }}
          >
            <Fab color="primary" style={{ fontSize: 5, width: 37, height: 20 }}>
              <Visibility size="small" />
            </Fab>
          </Link>
        </ButtonContainer>
      </Info>
    </ListContainer>
  );
};

export default List;
