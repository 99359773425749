// @format
import React from 'react';
import { uniqueId } from 'lodash';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

export const Selects = props => {
  return (
    <TextField
      select
      fullWidth
      onChange={props.change}
      label={props.label}
      value={props.value ? props.value : ''}
      helperText={props.helper ? props.helper : ''}
      name={props.name}
      margin={props.margin ? props.margin : 'normal'}
    >
      {props.array.map(option => (
        <MenuItem key={uniqueId()} value={option.id}>
          {option.name || option.nome || option.nome_razao_social}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default Selects;
