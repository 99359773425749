import React from 'react'
import api from '../../../../../config/Api'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default class FormDialog extends React.Component {
  state = {
    open: false,
    telefone: this.props.telefone,
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  salva = () => {
    this.props.reset()
    this.setState({ open: false })
  }

  render() {
    return (
      <div>
        <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          Resetar a negociação
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Resetar a negociação</DialogTitle>
          <DialogContent style={{ minWidth: 500 }}>
            <DialogContentText>
              Tem certeza que deseja resetar a negociação?.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancelar
            </Button>
            <Button onClick={this.salva} color="primary">
              Resetar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}
