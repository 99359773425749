// @format
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

// Icons
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import HomeIcon from '@material-ui/icons/Home';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import BusinessIcon from '@material-ui/icons/Business';
import Group from '@material-ui/icons/Group';
import Build from '@material-ui/icons/Build';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
//import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
//import InsertChartIcon from "@material-ui/icons/InsertChart";
//import GroupIcon from "@material-ui/icons/Group";
//import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";

// The router
//import Routes from "../config/Routes";
import {Link, withRouter} from 'react-router-dom';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: 'auto',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: 'auto',
  },
});

class MiniDrawer extends React.Component {
  state = {
    open: false,
    user: {},
  };

  componentDidMount() {
    this.getUser();
  }

  getUser() {
    const token = localStorage.getItem('usuario');
    const json = JSON.parse(token);
    this.setState({user: json.me});
  }

  handleDrawerOpen = () => {
    this.setState({open: true});
  };

  handleDrawerClose = () => {
    this.setState({open: false});
  };

  logOut = () => {
    localStorage.clear();
    this.props.history.push('/login');
  };

  render() {
    const {classes, theme} = this.props;

    return (
      <Fragment>
        <AppBar
          position="absolute"
          className={classNames(
            classes.appBar,
            this.state.open && classes.appBarShift,
          )}>
          <Toolbar
            style={{backgroundColor: '#033c45'}}
            disableGutters={!this.state.open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                this.state.open && classes.hide,
              )}>
              <MenuIcon />
            </IconButton>
            <Typography variant="title" color="inherit" noWrap>
              Positive
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(
              classes.drawerPaper,
              !this.state.open && classes.drawerPaperClose,
            ),
          }}
          open={this.state.open}>
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <Link to="/">
            <List>
              <ListItem button>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Início" />
              </ListItem>
            </List>
          </Link>
          <Link to="/clientes">
            <List>
              <ListItem button>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary="Clientes" />
              </ListItem>
            </List>
          </Link>
          <Link to="/devedores">
            <List>
              <ListItem button>
                <ListItemIcon>
                  <Group />
                </ListItemIcon>
                <ListItemText primary="Devedores" />
              </ListItem>
            </List>
          </Link>
          {this.state.user.grupo_nome === 'gerente_executivo' ? (
            <Link to="/usuarios">
              <List>
                <ListItem button>
                  <ListItemIcon>
                    <AssignmentInd />
                  </ListItemIcon>
                  <ListItemText primary="Usuários" />
                </ListItem>
              </List>
            </Link>
          ) : null}
          <Link to="/configuracoes">
            <List>
              <ListItem button>
                <ListItemIcon>
                  <Build />
                </ListItemIcon>
                <ListItemText primary="Configurações" />
              </ListItem>
            </List>
          </Link>

          <List>
            <ListItem onClick={this.logOut} button>
              <ListItemIcon>
                <PowerIcon />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </ListItem>
          </List>
        </Drawer>
      </Fragment>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, {withTheme: true})(MiniDrawer));
