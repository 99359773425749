import React, { Component, Fragment } from "react";
import { Menu, PositiveLogo, MenuItem } from "./styles";

class AcordoMenu extends Component {
	render() {
		return (
			<Fragment>
				<Menu>
					<PositiveLogo />
					<MenuItem>Meus Acordos</MenuItem>
					<MenuItem>Propostas de Acordo</MenuItem>
					<MenuItem>Carta de Quitação</MenuItem>
				</Menu>
			</Fragment>
		);
	}
}

export default AcordoMenu;
