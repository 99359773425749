import React from 'react'
import { Route, Switch } from 'react-router-dom'
// Componentes de rotas
import Login from '../pages/Login/Login'
import Clientes from '../pages/Clientes/Clientes'
import ClienteForm from '../pages/Clientes/ClienteForm'
import Cliente from '../pages/Clientes/Cliente'
import RemessaForm from '../pages/Clientes/Remessas/Form'
import Remessa from '../pages/Clientes/Remessas/Remessa'
import Usuarios from '../pages/Usuarios/Usuarios'
import UsuariosForm from '../pages/Usuarios/UsuariosForm'
import DividaView from '../pages/Clientes/Remessas/Debitos/DividaView'
import NegociacaoView from '../pages/Clientes/Remessas/Debitos/NegociacaoView'
import Teste from '../pages/Teste/Teste'
//import Devedores from "../pages/Clientes/Remessas/Debitos/Devedores";
import Devedores from '../pages/Devedores/Devedores'
import Devedor from '../pages/Devedores/Devedor'
import DevedorForm from '../pages/Devedores/DevedorForm'
import Debit from '../pages/Clientes/Remessas/Dividas/Debit'
import TermoAceite from '../pages/TermoAceite/TermoAceite'
import ListaAceite from '../pages/TermoAceite/ListaTermo'
import AceitarTermo from '../pages/TermoAceite/AceitarTermo'
import Grupos from '../pages/Grupos'
import GrupoForm from '../pages/Grupos/GrupoForm'
import Configuracoes from '../pages/Configuracoes/'
import Negociador from '../pages/Negociador'
import CadastroDivida from '../pages/CadastroDivida'
import StepOne from '../pages/CadastroDivida/StepOne'
import StepTwo from '../pages/CadastroDivida/StepTwo'
import StepThree from '../pages/CadastroDivida/StepThree'

// Componente PrivateRoute
import { PrivateRoute } from '../components/PrivateRoute'

const Routes = () => (
	<Switch>
		<Route exact path="/cadastrar-divida" component={CadastroDivida} />
		<Route
			exact
			path="/cadastrar-divida/cadastrar-devedor"
			component={StepOne}
		/>
		<Route
			exact
			path="/cadastrar-divida/cadastrar-contrato"
			component={StepTwo}
		/>
		<Route
			exact
			path="/cadastrar-divida/cadastrar-parcelas"
			component={StepThree}
		/>
		<Route exact path="/login" component={Login} />
		<Route exact path="/teste" component={Teste} />
		<Route exact path="/negociacao" component={NegociacaoView} />
		<Route exact path="/termo-de-acordo" component={TermoAceite} />
		<Route exact path="/termo-de-acordo/:cpf" component={ListaAceite} />
		<Route exact path="/aceitar-termo/" component={AceitarTermo} />

		<PrivateRoute exact path="/negociador" component={Negociador} />
		<PrivateRoute exact path="/configuracoes" component={Configuracoes} />
		<PrivateRoute exact path="/grupos" component={Grupos} />
		<PrivateRoute exact path="/adicionar-grupo" component={GrupoForm} />
		<PrivateRoute exact path="/editar-grupo/:id" component={GrupoForm} />
		<Route
			exact
			path="/cliente/:clienteId/remessa/:remessaId/teste"
			component={Teste}
		/>
		<PrivateRoute exact path="/novo-usuario" component={UsuariosForm} />
		<PrivateRoute exact path="/editar-usuario/:id" component={UsuariosForm} />
		<PrivateRoute exact path="/usuarios" component={Usuarios} />
		<PrivateRoute exact path="/devedores" component={Devedores} />
		<PrivateRoute exact path="/editar-devedor/:id" component={DevedorForm} />
		<PrivateRoute exact path="/visualizar-devedor/:id" component={Devedor} />
		<PrivateRoute exact path="/cliente/:id" component={Cliente} />
		<PrivateRoute
			exact
			path="/visualizar-cliente/:id"
			component={ClienteForm}
		/>
		<PrivateRoute exact path="/editar-cliente/:id" component={ClienteForm} />
		<PrivateRoute exact path="/novo-cliente" component={ClienteForm} />
		<PrivateRoute
			exact
			path="/cliente/:clienteId/remessa/:remessaId/nova-divida"
			component={Teste}
		/>
		<PrivateRoute
			exact
			path="/cliente/:clienteId/remessa/:remessaId/editar-divida/:dividaId"
			component={Teste}
		/>
		<PrivateRoute
			exact
			path="/cliente/:clienteId/remessa/:remessaId/novo-divida"
			component={Debit}
		/>
		<PrivateRoute
			exact
			path="/cliente/:clienteId/remessa/:remessaId/divida/:dividaId/"
			component={DividaView}
		/>
		<PrivateRoute
			exact
			path="/cliente/:clienteId/nova-remessa"
			component={RemessaForm}
		/>
		<PrivateRoute
			exact
			path="/editar/cliente/:clienteId/remessa/:remessaId"
			component={RemessaForm}
		/>
		<PrivateRoute
			exact
			path="/cliente/:clienteId/remessa/:remessaId"
			component={Remessa}
		/>
		<PrivateRoute path="/clientes" component={Clientes} />
		<PrivateRoute path="/" component={Clientes} />
	</Switch>
)

export default Routes
