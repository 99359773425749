import React, { Fragment } from "react";
// Material-ui
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import Api from "../../config/Api";

import { debounce } from "lodash";

// Redux e ReduxForm
import { withRouter } from "react-router-dom";

// Componentes da Recred
import Bar from "../../components/Appbar";
import Menu from "../../components/Menu";
import DevedorList from "../../components/DevedorList";

class Devedores extends React.Component {
  state = {
    devedor: "",
    text: "",
    devedores: []
  };
  componentDidMount() {}

  async searchDevedores(match) {
    try {
      const request = await Api.get(`/api/v1/pessoas/devedores?q=${match}`);
      this.setState({ devedores: request.data.data });
    } catch (e) {
      return console.warn(e);
    }
  }

  handleChange = debounce(text => {
    this.setState({ text });
    this.searchDevedores(text);
  }, 700);

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Bar title="Devedores" />
            <TextField
              style={{ marginTop: 20 }}
              label="Pesquisar devedores"
              id="simple-start-adornment"
              fullWidth
              onChange={e => this.handleChange(e.target.value)}
              placeholder="Digite o CPF"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                )
              }}
            />
            {this.state.devedores.map(devedor => {
              return (
                <DevedorList
                  key={devedor.id}
                  nome={devedor.nome_razao_social}
                  cpf={devedor.cpf_cnpj}
                  link={`/visualizar-devedor/${devedor.id}`}
                  editar={`/editar-devedor/${devedor.id}`}
                  celular={devedor.telefone_celular}
                  email={devedor.email}
                />
              );
            })}
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  }
});

export default withRouter(withStyles(styles, { withTheme: true })(Devedores));
