import React, { useState, useEffect } from 'react'
import api from '../../config/Api'
import { debounce } from 'lodash'
import withRouter from 'react-router-dom/withRouter'
import { useStateMachine } from 'little-state-machine'

// @material-ui/core
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'

// @material-ui/icons
import Person from '@material-ui/icons/Person'

// Actions
import updateAction from './updateAction'

// Custom Components
import CadastroContainer from './CadastroContainer'

// Styled Components
import {
	SearchInput,
	WarningIcon,
	WarningContainer,
	SearchContainer,
	SearchTitle,
	SearchSubTitle,
	WarningText,
	WarningButton,
} from './styles'

export function CadastroDivida({ history }) {
	const { action, state } = useStateMachine(updateAction)
	const [devedores, setDevedores] = useState([])
	const [error, setError] = useState('')
	const [code, setCode] = useState('')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		limpaEstado() // caso algum dado tenha ficado salvo na store ele vai ser reiniciado
	}, [])

	const onSubmit = data => {
		action(data)
		history.push('/cadastrar-divida/cadastrar-devedor')
	}

	const redireciona = () => {
		history.push('/cadastrar-divida/cadastrar-devedor')
	}

	const next = devedor => {
		const { id } = devedor
		action({
			pessoa_devedor_id: id,
			step: state.data.step + 1,
			progress: 20,
			devedor,
		})
		history.push('/cadastrar-divida/cadastrar-contrato')
		console.log(state)
	}

	const gotoDevedor = () => {
		history.push('/cadastrar-divida/cadastrar-devedor')
	}

	const pesquisaDevedor = debounce(async e => {
		try {
			setLoading(true)
			const data = await api.get(`/api/v1/pessoas/devedores?q=${e}`)
			setError(data.data.message)
			setDevedores(data.data.data)
			setCode(data.data.code)
			setLoading(false)
		} catch (error) {
			console.warn(error)
		}
	}, 500)

	const limpaEstado = () => {
		action({
			contrato_id: '',
			pessoa_devedor_id: '',
			parcelas: [],
			status: 0,
			valor: 0,
			step: 0,
			progress: 0,
			contratoNumero: '',
			devedor: {
				cpf_cnpj: '',
				telefone_celular: '',
			},
		})
	}

	return (
		<CadastroContainer>
			<SearchContainer>
				<SearchTitle>Procurar Devedor</SearchTitle>
				<SearchSubTitle>
					Insira o CPF ou o nome, depois clique no nome do devedor para
					prosseguir.
				</SearchSubTitle>
				<SearchInput
					onChange={e => pesquisaDevedor(e.target.value)}
					placeholder="Insira o CPF ou o nome"
				/>
			</SearchContainer>
			<Paper>
				{devedores.length !== 0 && (
					<List>
						{devedores.map(devedor => {
							return (
								<>
									<ListItem button onClick={() => next(devedor)}>
										<ListItemAvatar>
											<Avatar>
												<Person />
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={devedor.nome_razao_social}
											style={{ width: 300 }}
											secondary={devedor.cpf_cnpj}
										/>
										<ListItemText
											primary={devedor.telefone_celular}
											secondary="Telefone Celular"
										/>
										<ListItemSecondaryAction />
									</ListItem>
									<Divider />
								</>
							)
						})}
					</List>
				)}
			</Paper>
			{loading && <p>loading</p>}

			{code === 1 && (
				<WarningContainer>
					<WarningIcon />
					<WarningText>Nenhum devedor encontrado.</WarningText>
					<WarningButton onClick={gotoDevedor}>Cadastrar Devedor</WarningButton>
				</WarningContainer>
			)}
			{code === 0 && (
				<WarningContainer>
					<WarningIcon />
					<WarningText>
						O termo de pesquisa deve ter no mínimo 7 caracteres.
					</WarningText>
				</WarningContainer>
			)}
		</CadastroContainer>
	)
}

export default withRouter(CadastroDivida)
