import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "../../../../../components/Select";

const status = [
  {
    id: 1,
    name: "A contactar"
  },
  {
    id: 2,
    name: "Não Localizado"
  },
  {
    id: 3,
    name: "Cumprindo Senteça"
  },
  {
    id: 4,
    name: "Acão Judicial"
  },
  {
    id: 5,
    name: "Quebra de Acordo"
  },
  {
    id: 6,
    name: "Liquidado"
  },
  {
    id: 7,
    name: "Não Localizado"
  },
  {
    id: 8,
    name: "Em Análise"
  },
  {
    id: 9,
    name: "Descumprindo Sentença"
  },
  {
    id: 10,
    name: "Cumprindo Acordo"
  },
  {
    id: 11,
    name: "Termo de Acordo Enviado"
  },
  {
    id: 12,
    name: "Execução Judicial"
  },
  {
    id: 13,
    name: "Ajuizar"
  }
];

export default class FormDialog extends React.Component {
  state = {
    open: false,
    juros: "",
    multa: "",
    honorariosCobranca: "",
    honorariosAdvocaticios: "",
    status: 1
  };

  componentDidMount() {
    this.setState({ status: this.props.status });
    console.log(this.props.status);
  }

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.toggle}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Atualização da dívida
          </DialogTitle>
          <DialogContent>
            <Select
              change={this.handleChange.bind(this)}
              label="Status"
              array={status}
              name="status"
              value={this.state.status}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.toggle} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={this.props.atualiza.bind(this, this.state)}
              color="primary"
            >
              Atualizar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
