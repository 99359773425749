import React from 'react';
import './FormBar.css';

export const FormBar = (props) => {
  return (
    <div className='form-bar'>
      <h5 className='form-bar__title'>{props.nome}</h5>
    </div>
  );
};

export default FormBar;
