import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import api from "../../config/Api";

// Material-UI
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";

// Custom Components
import Menu from "../../components/Menu";
import FormPaper from "../../components/FormPaper";

class GrupoForm extends Component {
  state = {
    nome: "",
    ativo: false
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    if (id) {
      this.getGrupo();
    }
  }

  async getGrupo() {
    try {
      const { id } = this.props.match.params;
      const grupo = await api.get(`/api/v1/grupo/${id}`);
      this.setState({ ...this.state, ...grupo.data.data });
    } catch (err) {
      console.warn(err);
    }
  }

  async salvaGrupo() {
    try {
      const { id } = this.props.match.params;
      const obj = {
        nome: this.state.nome,
        ativo: this.state.ativo
      };
      if (id) {
        await api.put(`/api/v1/grupo/${id}`, obj);
        this.props.history.push("/grupos");
      } else {
        await api.post(`/api/v1/grupo`, obj);
        this.props.history.push("/grupos");
      }
    } catch (err) {
      console.warn(err);
    }
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <div className={classes.root}>
            <Menu />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <FormPaper title="Informações do Grupo">
                <Grid container spacing={24}>
                  <Grid item lg={4}>
                    <TextField
                      fullWidth
                      label="Nome"
                      name="nome"
                      value={this.state.nome}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.ativo}
                          onChange={this.handleChange.bind(this)}
                          name="ativo"
                          value="true"
                        />
                      }
                      label="Ativo?"
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <Link to="/grupos" style={{ textDecoration: "none" }}>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ marginRight: 10 }}
                      >
                        Voltar
                      </Button>
                    </Link>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.salvaGrupo.bind(this)}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </FormPaper>
            </main>
          </div>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  }
});

export default withStyles(styles)(GrupoForm);
