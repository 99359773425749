import React, { Fragment } from 'react'
import InputMask from 'react-input-mask'
import './dividas.css'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Search from '@material-ui/icons/Search'
import DevedorList from './DevedorList'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import Typography from '@material-ui/core/Typography'
import FormBar from '../../components/FormBar/FormBar'
import { debounce } from 'lodash'
import api from '../../config/Api'

const sexo = [
  {
    label: 'Masculino',
    value: 'm',
  },
  {
    label: 'Feminino',
    value: 'f',
  },
]

class Primeiro extends React.Component {
  state = {
    apelido_nome_fantasia: '',
    cpf_cnpj: '',
    rg_inscricao_estadual: '',
    bancos: [],
    email: '',
    endereco: {
      cep: '',
      logradouro: '',
      bairro: '',
      localidade: '',
      numero: '',
      complemento: '',
      uf: '',
    },
    nascimento: '',
    nome_razao_social: '',
    sexo: 0,
    telefone_celular: '',
    telefone_fixo: '',
    logradouro: '',
    tipo: 1,
    termo: '',
  }

  handleCep = debounce(text => {
    this.setState(
      {
        cep: text,
      },
      () => {
        api
          .get(`/api/v1/cep/${text}`)
          .then(resp =>
            resp.status === 200
              ? this.setState({ ...this.state, endereco: resp.data })
              : null
          )
      }
    )
  }, 500)

  handleInputChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleInputChange2(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    const endereco = this.state.endereco
    endereco[name] = value

    this.setState({
      endereco,
    })
  }

  render() {
    const { classes } = this.props
    return (
      <Fragment>
        <div className={classes.appBarSpacer} />
        {this.props.mostraForm === false ? (
          <form onSubmit={this.props.change.bind(this, this.state)}>
            <div className="primeiro-header">
              <img src={require('./img/devedor.png')} alt="" />
              <h2>Pesquise pelo Devedor</h2>
              <input
                className="search-field"
                type="text"
                label="Pesquisar Devedor"
                name="termo"
                placeholder="Pesquisar Devedor"
                required
                onChange={this.handleInputChange.bind(this)}
                value={this.state.termo}
              />
              <div style={{ marginTop: 10 }}>
                <Button type="submit" variant="contained" color="primary">
                  <Search />
                  Pesquisar
                </Button>
              </div>
            </div>
          </form>
        ) : null}

        {this.props.devedores.length !== 0 ? (
          this.props.devedores.map((devedor, index) => {
            return (
              <div key={devedor.id}>
                <DevedorList
                  nome={devedor.nome_razao_social}
                  cpf={devedor.cpf_cnpj}
                  devedorid={this.props.devedorId}
                  listaId={devedor.id}
                  rg={devedor.rg_inscricao_estadual}
                  seleciona={this.props.clique.bind(this, devedor.id)}
                />
              </div>
            )
          })
        ) : this.props.mostraForm ? (
          <div>
            <Grid container spacing={24}>
              <Grid item lg={12}>
                <Typography variant="h4" gutterBottom>
                  Cadastrar Novo Devedor
                </Typography>
                <Button onClick={this.props.escondeForm.bind(this)}>
                  Voltar a pesquisa
                </Button>
              </Grid>
            </Grid>
            <Paper elevation={1} style={{ borderRadius: '0' }}>
              <FormBar nome="Informações Pessoais" />
              <Grid container spacing={24} style={{ padding: 20 }}>
                <Grid item lg={3}>
                  <InputMask
                    mask="999.999.999-99"
                    fullWidth
                    margin="normal"
                    value={this.state.cpf_cnpj}
                    onChange={this.handleInputChange.bind(this)}
                  >
                    {inputProps => (
                      <TextField
                        {...inputProps}
                        label="CPF"
                        name="cpf_cnpj"
                        value={this.state.cpf_cnpj}
                        type="tel"
                      />
                    )}
                  </InputMask>
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    name="rg_inscricao_estadual"
                    margin="normal"
                    label="RG"
                    fullWidth
                    type="text"
                    onChange={this.handleInputChange.bind(this)}
                    placeholder="Rg"
                    value={this.state.rg_inscricao_estadual}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    name="nome_razao_social"
                    label="Nome"
                    type="text"
                    fullWidth
                    margin="normal"
                    onChange={this.handleInputChange.bind(this)}
                    placeholder="Nome"
                    value={this.state.nome_razao_social}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    name="apelido_nome_fantasia"
                    label="Apelido"
                    type="text"
                    fullWidth
                    margin="normal"
                    onChange={this.handleInputChange.bind(this)}
                    placeholder="Apelido"
                    value={this.state.apelido_nome_fantasia}
                  />
                </Grid>

                <Grid item lg={3}>
                  <TextField
                    name="email"
                    fullWidth
                    margin="normal"
                    label="Email"
                    type="text"
                    onChange={this.handleInputChange.bind(this)}
                    placeholder="Email"
                    value={this.state.email}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    select
                    label="Sexo"
                    fullWidth
                    name="sexo"
                    margin="normal"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.sexo}
                  >
                    {sexo.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    name="telefone_celular"
                    fullWidth
                    margin="normal"
                    label="Telefone Celular"
                    type="text"
                    onChange={this.handleInputChange.bind(this)}
                    placeholder="Telefone Celular"
                    value={this.state.telefone_celular}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    name="telefone_fixo"
                    fullWidth
                    margin="normal"
                    label="Telefone Fixo"
                    type="text"
                    onChange={this.handleInputChange.bind(this)}
                    placeholder="Telefone Fixo"
                    value={this.state.telefone_fixo}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    name="nascimento"
                    fullWidth
                    label="Nascimento"
                    margin="normal"
                    type="date"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.nascimento}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper elevation={1} style={{ borderRadius: '0' }}>
              <FormBar nome="Endereço" />
              <Grid container spacing={24} style={{ padding: 20 }}>
                <Grid item lg={3}>
                  <TextField
                    type="text"
                    name="cep"
                    label="CEP"
                    placeholder="CEP"
                    fullWidth
                    margin="normal"
                    onChange={e => this.handleCep(e.target.value)}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    type="text"
                    name="logradouro"
                    label="Rua"
                    placeholder="Rua"
                    fullWidth
                    margin="normal"
                    onChange={this.handleInputChange2.bind(this)}
                    value={this.state.endereco.logradouro}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    type="text"
                    name="bairro"
                    label="Bairro"
                    placeholder="Bairro"
                    fullWidth
                    margin="normal"
                    onChange={this.handleInputChange2.bind(this)}
                    value={this.state.endereco.bairro}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    type="text"
                    name="numero"
                    label="numero"
                    placeholder="numero"
                    fullWidth
                    margin="normal"
                    onChange={this.handleInputChange2.bind(this)}
                    value={this.state.endereco.numero}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    type="text"
                    name="localidade"
                    label="Cidade"
                    placeholder="Cidade"
                    fullWidth
                    onChange={this.handleInputChange2.bind(this)}
                    margin="normal"
                    value={this.state.endereco.localidade}
                  />
                </Grid>
                <Grid item lg={3}>
                  <TextField
                    type="text"
                    name="uf"
                    label="Estado"
                    placeholder="Estado"
                    fullWidth
                    margin="normal"
                    onChange={this.handleInputChange2.bind(this)}
                    value={this.state.endereco.uf}
                  />
                </Grid>

                <Grid item lg={3}>
                  <TextField
                    type="text"
                    name="complemento"
                    label="Complemento"
                    placeholder="Complemento"
                    fullWidth
                    margin="normal"
                    onChange={this.handleInputChange2.bind(this)}
                    value={this.state.endereco.complemento}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Button
              style={{ marginTop: 30 }}
              variant="contained"
              color="primary"
              onClick={this.props.envia.bind(this, this.state)}
            >
              <SaveIcon style={{ marginRight: 10 }} />
              Salva Devedor
            </Button>
          </div>
        ) : null}
      </Fragment>
    )
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
})

export default withStyles(styles)(Primeiro)
