import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Breadcrumbs from "@material-ui/lab/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
// import Link from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const styles = theme => ({
  root: {
    justifyContent: "center",
    flexWrap: "wrap",
    marginBottom: 10
  },
  link: {
    color: "#000",
    textDecoration: "none"
  },
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  }
});

function CustomSeparator(props) {
  const { classes } = props;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          arial-label="Breadcrumb"
        >
          <Link className={classes.link} to={`/cliente/${props.clienteId}`}>
            {props.nomeCliente}
          </Link>
          <Link
            color="inherit"
            className={classes.link}
            to={`/cliente/${props.clienteId}/remessa/${props.remessaId}`}
          >
            Remessa {props.codigoRemessa}
          </Link>
          <Typography color="textPrimary">Divida {props.dividaId}</Typography>
        </Breadcrumbs>
      </Paper>
    </div>
  );
}

CustomSeparator.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomSeparator);
