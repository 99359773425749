import { createStore, applyMiddleware, compose } from 'redux';
import Reducers from './reducers';

// Middleware
import thunk from 'redux-thunk'

// Middleware que faz a conexão das rotas com o redux
import createHistory from 'history/createBrowserHistory'
import { routerMiddleware } from 'react-router-redux'
export const history = createHistory()
const history_middleware = routerMiddleware(history)

// Habilita o Redux Dev Tools
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Adiciona os Middlewares
const Store = createStore(
    Reducers,
    composeEnhancer(applyMiddleware(history_middleware, thunk))
)

export default Store;