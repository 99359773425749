// @format
import React, { Fragment, Component } from "react";
import Bar from "../../components/Appbar";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Menu from "../../components/Menu";

class Devedor extends Component {
  state = {};

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Bar title="Negociador" />
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    overflow: "auto"
  }
});

export default withRouter(withStyles(styles, { withTheme: true })(Devedor));
