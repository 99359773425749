// @format
import React, { Fragment } from 'react'
import { uuid } from 'uuidv4'
import moment from 'moment'
import { Link } from 'react-router-dom'

// Services
import Api from '../../../../config/Api'

// Material-ui
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import Assignment from '@material-ui/icons/Assignment'
import CircularProgress from '@material-ui/core/CircularProgress'

// Custom Stylesheet
import './Divida.css'
import swal from '@sweetalert/with-react'

// Icons
import AssignmentInd from '@material-ui/icons/AssignmentInd'
import Work from '@material-ui/icons/Work'
import Call from '@material-ui/icons/Call'
import Timeline from '@material-ui/icons/Timeline'
import List from '@material-ui/icons/List'
import Refresh from '@material-ui/icons/Refresh'

// Componentes da Recred
import Arquivos from './Modais/Arquivos.js'
import AtualizacaoNew from './Modais/AtualizacaoNew'
import ModalDevedor from './Modais/ModalDevedor'
import Menu from '../../../../components/Menu'
import ParcelaItem from './ParcelaItem'
import Historico from './Historico'
import Breadcrumb from './Breadcrumb'
import RegrasRemessa from './Modais/RegrasRemessa'
import DividaBar from './DividaBar'
import StatusModal from './Modais/Status'
import NegociacaoModal from './Modais/VerNegociacao'
import Sms from './Modais/Sms'

class DividaView extends React.Component {
  state = {
    devedor: {
      endereco: {},
    },
    loading: false,
    modalNegociacao: false,
    status: '',
    divida: {
      valor: '',
      status: '',
    },
    arquivos: [],
    modalDevedor: false,
    comunicacoes: [],
    statusModal: false,
    remessa: {},
    contrato: {},
    atualizaModal: false,
    value: 0,
    nome_cliente: '',
    codigo_remessa: '',
    parcelas: [],
    negociacao: {
      id: '',
      recurso: {
        divida_parcelas: [],
        status: '',
        valorAcordado: '',
        atualizacao_divida: {
          divida_parcelas: [],
        },
      },
    },
  }

  // Component life cycles

  componentWillMount() {
    this.setState({ loading: true })
  }
  async componentDidMount() {
    await this.getRemessa()
    await this.getDivida()

    if (this.state.divida.negociacao_id) {
      await this.getNegociacao(this.state.divida.negociacao_id)
    }
  }

  // Click and handlers events

  // Formata data
  toDate = date => {
    let data = new Date(date)
    return data.toLocaleDateString()
  }

  toggleModal() {
    this.setState(state => ({
      atualizaModal: !state.atualizaModal,
    }))
  }

  toggleStatusModal() {
    this.setState(state => ({
      statusModal: !state.statusModal,
    }))
  }

  modalDevedor() {
    this.setState(state => ({
      modalDevedor: !state.modalDevedor,
    }))
  }

  handleChange = event => {
    const { match } = this.props
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      ...this.state,
      divida: {
        ...this.state.divida,
        [name]: value,
      },
    })
    const obj = {
      ...this.state.divida,
      status: value,
    }
    Api.put(
      `/api/v1/pessoas/clientes/${match.params.clienteId}/remessas/${
        match.params.remessaId
      }/dividas/${match.params.dividaId}`,
      obj
    )
  }

  // Acompanha as mudanças no input do histórico da dívida e as coloca no state
  handleHistoricoChange(e) {
    let historico = e.target.value
    this.setState({ historico })
  }

  // Acompanha as mudanças no input de anexo do histórico e insere no state
  handleHistoricoFileChange(e) {
    let arquivo = e.target.files[0]
    this.setState({ ...this.state, arquivo })
  }

  // Cria o item de historico, envia o objeto comunicacão com
  // o anexo e o histórico
  async sendHistorico(event) {
    event.preventDefault()
    const { match } = this.props

    try {
      let enviaArquivo = await this.sendArquivo()

      let recurso = {
        recurso: {
          pessoa_devedor_id: this.state.devedor.id,
          tipo_log: 1,
          historico: this.state.historico,
          divida_id: parseInt(match.params.dividaId),
          arquivo_id: this.state.arquivo ? enviaArquivo.data.id_arquivo : '',
        },
      }
      await Api.post(
        `/api/v1/comunicacoes/divida/${match.params.dividaId}`,
        recurso
      )

      await this.getDivida()

      return this.setState({ ...this.state, historico: '', arquivo: '' })
    } catch (err) {
      return console.warn(err)
    }
  }

  async sendArquivo() {
    const { match } = this.props
    let formData = new FormData()
    if (this.state.arquivo) {
      formData.append(`arquivo`, this.state.arquivo)
    } else {
      formData.append(`arquivo`, '')
    }
    formData.append(`origem`, 'comunicacao')
    formData.append(`nome`, 'arquivo')
    formData.append(`id_origem`, 0)

    const enviaArquivo = await Api.post(`/api/v1/arquivos`, formData)
    return enviaArquivo
  }

  abreNegociacao() {
    this.setState({
      modalNegociacao: !this.state.modalNegociacao,
    })
  }

  async atualizaStatus(state) {
    const { match } = this.props
    await this.setState({
      ...this.state,
      divida: { ...this.state.divida, ...state.status },
    })
    const status = state.status
    const obj = {
      ...this.state.divida,
      status,
    }
    await Api.put(
      `/api/v1/pessoas/clientes/${match.params.clienteId}/remessas/${
        match.params.remessaId
      }/dividas/${match.params.dividaId}`,
      obj
    )
    await this.setState({
      ...this.state,
      statusModal: false,
      divida: {
        ...this.state.divida,
        status,
      },
    })
  }

  /*
   *  Envia uma requisição com os parâmetros de atualização da dívida
   *  recebe a dívida atualizada, cria a negociacão e coloca a divida atualizada nela
   */

  async atualizaDivida(state) {
    // Recebe o state do modal de atualizacão e insere no estado do DividaView
    await this.setState({ ...this.state, ...state })
    const { match } = this.props
    try {
      const request = await Api.get(
        `/api/v1/dividas/${match.params.dividaId}/atualiza_valores?indice=${
          this.state.indice
        }&juros=${parseFloat(this.state.juros)}&multa=${parseFloat(
          this.state.multa
        )}&honorario_cobranca=${parseFloat(
          this.state.honorariosCobranca
        )}&honorario_advocaticio=${parseFloat(
          this.state.honorariosAdvocaticios
        )}`
      )

      /*
       *  Se não existir um negociacao_id na dívida este bloco irá, enviar um POST criando a negociação,
       *  receberá a resposta deste POST e inserirá o id da negociacão da divida usando o
       *  atributo negociacao_id.
       */
      console.log(this.state.divida.negociacao_id)
      if (this.state.divida.negociacao_id === null) {
        console.log('NAO TA PEGANDO ID')
        /*
         *  Desestruturação.
         */
        const { limite_parcelamento_empresa } = this.state.remessa
        /*
         *  Objeto que serå enviado para criar a negociação.
         */
        const recurso = {
          recurso: {
            negociacao: {
              uuid: uuid(),
              regras: {
                limite_parcelamento_empresa,
              },
              divida_id: this.state.divida.id,
            },
            atualizacao_divida: request.data,
            status: 'pendente',
            pessoa_devedor_id: this.state.divida.pessoa_devedor_id,
            parcelamento_maximo: '',
            divida_id: this.state.divida.id,
            apagar_parcela_divida: false,
          },
        }

        var criaNegociacao = await Api.post(`/api/v1/negociacoes`, recurso) // Cria a negociação.

        const divida = {
          contrato_id: this.state.divida.contrato_id,
          status: this.state.divida.status,
          negociacao_id: criaNegociacao.data.id, // Utiliza o id da resposta do criaNegociacao.
          descricao: this.state.divida.descricao,
          pessoa_devedor_id: this.state.divida.pessoa_devedor_id,
          remessa_id: this.state.divida.remessa_id,
          valor: this.state.divida.valor,
          id: this.state.divida.id,
        }

        // Atualiza a dívida inserindo o id da negociação.
        await Api.put(
          `/api/v1/pessoas/clientes/${match.params.clienteId}/remessas/${
            match.params.remessaId
          }/dividas/${match.params.dividaId}`,
          divida
        )

        /*
         *  Pega as informações da negociacão e as insere no state, utiliza o id que
         *  vem na resposta da requisicao criaNegociacao
         */

        await this.getNegociacao(criaNegociacao.data.id)
      } else {
        const {
          limite_desconto_cliente,
          limite_desconto_empresa,
          limite_parcelamento_cliente,
          limite_parcelamento_empresa,
        } = this.state.remessa
        const recurso = {
          ...this.state.negociacao.recurso,
          atualizacao_divida: request.data,
          divida_id: this.state.divida.id,

          // Valores da dívida atualizados.
        }
        console.log(recurso)
        console.log(this.state.negociacao)
        await Api.put(
          `/api/v1/negociacoes/${this.state.negociacao.id}`,
          recurso
        )
        // Quando já existe uma negociação, utilizar o id que está no state.
        await this.getNegociacao(this.state.negociacao.id)
      }

      return this.setState(
        {
          ...this.state,
          atualizaModal: !this.state.atualizaModal,
          divida: {
            ...this.state.divida,
            valor: this.formatCurrency(request.data.divida_atualizada),
            parcelas_atualizadas: request.data.divida_parcelas,
          },
        },
        console.log(this.state)
      )
    } catch (error) {
      return console.warn(error)
    }
  }

  async resetaNegociacao() {
    const recurso = {
      ...this.state.negociacao.recurso,
      negociacao: {
        uuid: uuid()
      },
      status: 'pendente',

      // Valores da dívida atualizados.
    }
    await Api.put(`/api/v1/negociacoes/${this.state.negociacao.id}`, recurso)
    // Quando já existe uma negociação, utilizar o id que está no state.
    await this.getNegociacao(this.state.negociacao.id)
  }

  async editaDevedor(devedor) {
    await console.log(devedor)
    devedor.cpf_cnpj = devedor.cpf_cnpj.replace(/\D/g, '')
    await Api.put(`/api/v1/pessoas/devedores/${devedor.id}`, devedor)
    this.getDivida()
    this.setState({ modalDevedor: false })
  }

  async salvaNegociacao(e) {
    const { limite_parcelamento_empresa } = this.state.remessa

    swal(
      'Negociação Fechada!',
      'Está negociação foi fechada. Um link para acessar o termo de aceite foi gerado e enviado para o email fulano@.... A negociação só vai ser considerada concretizada, após o aceite do termo e o pagamento da entrada!',
      'success'
    )
    const obj = {
      criado_em: this.state.negociacao.criado_em,
      criado_por: this.state.negociacao.criado_por,
      divida_id: this.state.divida.id,
      id: this.state.negociacao.id,
      status: 'aguardando_aceite',
      pessoa_devedor_id: this.state.divida.pessoa_devedor_id,
      atualizacao_divida: this.state.negociacao.recurso.atualizacao_divida,
      negociacao: {
        divida_id: this.state.divida.id,
        presencial: false,
        dia_vencimento: e.vencimento,
        parcelas_limite: limite_parcelamento_empresa,
        entrada: e.entrada,
        dataEntrada: e.dataEntrada,
        formaPagamentoEntrada: e.formaPagamentoEntrada,
        formaPagamentoParcela: e.formaPagamentoParcela,
        juros: e.juros,
        parcelas: e.parcelas,
        qtdParcelas: e.qtdParcelas,
        valorAcordado: e.valorAcordado,
      },
    }

    await Api.put(`/api/v1/negociacoes/${this.state.negociacao.id}`, obj)
    await this.setState({ modalNegociacao: false })
    console.log(JSON.stringify(obj))
  }

  async updateNegociacao(e) {
    const { limite_parcelamento_empresa } = this.state.remessa
    const obj = {
      criado_em: this.state.negociacao.criado_em,
      criado_por: this.state.negociacao.criado_por,
      divida_id: this.state.divida.id,
      id: this.state.negociacao.id,
      status: 'parcelas_criadas',
      pessoa_devedor_id: this.state.divida.pessoa_devedor_id,
      atualizacao_divida: this.state.negociacao.recurso.atualizacao_divida,
      negociacao: {
        divida_id: this.state.divida.id,
        presencial: false,
        dia_vencimento: e.vencimento,
        parcelas_limite: limite_parcelamento_empresa,
        entrada: e.entrada,
        dataEntrada: e.dataEntrada,
        formaPagamentoEntrada: e.formaPagamentoEntrada,
        formaPagamentoParcela: e.formaPagamentoParcela,
        juros: e.juros,
        parcelas: e.parcelas,
        qtdParcelas: e.qtdParcelas,
        valorAcordado: e.valorAcordado,
      },
    }

    await Api.put(`/api/v1/negociacoes/${this.state.negociacao.id}`, obj)
    console.log(JSON.stringify(obj))
  }

  /*
   *  Utiliza a API nativa do Javascript de internacionalizacão
   *  para converter um determinado valor em formato de moeda.
   */
  formatCurrency(value) {
    const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    })

    return formatter.format(value)
  }

  /* Cria uma requisição que pega os dados da remessa e coloca no state.
  /  Também pega o código da remessa e o nome do cliente para colcoar no breadcrumb.
  */
  async getRemessa() {
    let { match } = this.props

    try {
      const remessa = await Api.get(
        `/api/v1/pessoas/clientes/${match.params.clienteId}/remessas/${
          match.params.remessaId
        }`
      )
      const cliente = await Api.get(
        `/api/v1/pessoas/clientes/${match.params.clienteId}`
      )

      return this.setState({
        ...this.state,
        remessa: remessa.data.data,
        nome_cliente: cliente.data.data.nome_razao_social,
      })
    } catch (err) {
      return console.warn(err)
    }
  }

  // Faz 6 chamadas a api, requisitando as informações da Dívida, Devedor, Parcelas, Arquivos e Comunicações(Histórico)
  async getDivida() {
    const { match } = this.props
    try {
      const request = await Api.get(
        `/api/v1/pessoas/clientes/${match.params.clienteId}/remessas/${
          match.params.remessaId
        }/dividas/${match.params.dividaId}`
      )
      const contrato = await Api.get(
        `api/v1/pessoas/clientes/${match.params.clienteId}/contratos/${
          request.data.data.contrato_id
        }`
      )
      const devedores = await Api.get(
        `/api/v1/pessoas/devedores/${request.data.data.pessoa_devedor_id}`
      )
      const parcelas = await Api.get(
        `/api/v1/pessoas/clientes/${match.params.clienteId}/remessas/${
          match.params.remessaId
        }/dividas/${match.params.dividaId}/parcelas`
      )
      const arquivos = await Api.get(
        `/api/v1/arquivos?origem=contrato&id_origem=${
          request.data.data.contrato_id
        }`
      )
      const comunicacoes = await Api.get(
        `/api/v1/comunicacoes/divida/${match.params.dividaId}`
      )
      return await this.setState(
        {
          ...this.state,
          divida: request.data.data,
          valordadivida: request.data.data.valor,
          devedor: devedores.data.data,
          parcelas: parcelas.data.data,
          contrato: contrato.data.data,
          arquivos: arquivos.data.data,
          comunicacoes: comunicacoes.data.data,
          loading: false,
        },
        () => {
          // Cria a requisição para pegar os arquivos do historico/comunicações
          this.getComunicacoes()
        }
      )
    } catch (e) {
      return e
    }
  }

  async getNegociacao(negociacaoId) {
    const negociacao = await Api.get(`/api/v1/negociacoes/${negociacaoId}`)
    if (negociacao.data.success === false) {
      return ''
    } else {
      return this.setState(
        { ...this.state, negociacao: negociacao.data.data },
        () => console.log(this.state.negociacao)
      )
    }
  }

  /*
   *  Pega os historicos e executa um loop em cada item,
   *  verifica se possui um arquivo_id, se possuir ele
   *  puxa a url do arquivo e insere no objeto
   */
  async getComunicacoes() {
    const insereUrl = async (data, indice) => {
      const { arquivo_id } = data.recurso
      if (arquivo_id) {
        try {
          const request = await Api.get(`/api/v1/arquivos/${arquivo_id}`)
          const usuario = await Api.get(`/api/v1/usuario/${data.criado_por}`)
          let items = [...this.state.comunicacoes]
          let item = { ...items[indice] }
          item.recurso.url = request.data.data.url
          item.usuario = usuario.data.data.nome
          items[indice] = item
          return await this.setState({ ...this.state, comunicacoes: items })
        } catch (e) {
          return e
        }
      } else {
        try {
          const usuario = await Api.get(`/api/v1/usuario/${data.criado_por}`)
          let items = [...this.state.comunicacoes]
          let item = { ...items[indice] }
          item.usuario = usuario.data.data.nome
          items[indice] = item
          return await this.setState({ ...this.state, comunicacoes: items })
        } catch (e) {
          return e
        }
      }
    }
    const { match } = this.props
    const comunicacoes = await Api.get(
      `/api/v1/comunicacoes/divida/${match.params.dividaId}`
    )
    return await comunicacoes.data.data.forEach(insereUrl)
  }

  render() {
    const { classes, match } = this.props

    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} style={{ marginTop: 20 }} />
            {this.state.loading ? (
              <Fragment>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                  Carregando
                </div>
              </Fragment>
            ) : (
              <Fragment>
                <Breadcrumb
                  nomeCliente={this.state.nome_cliente}
                  clienteId={match.params.clienteId}
                  remessaId={match.params.remessaId}
                  codigoRemessa={this.state.remessa.numero}
                  dividaId={match.params.dividaId}
                />

                <Grid container spacing={24}>
                  <Grid item lg={12}>
                    <DividaBar
                      status={this.state.divida.status}
                      toggle={this.toggleStatusModal.bind(this)}
                    />
                  </Grid>
                  <div className="divida-view-bar">
                    <div className="divida-view-left-container">
                      {this.state.divida.status ? (
                        <StatusModal
                          open={this.state.statusModal}
                          toggle={this.toggleStatusModal.bind(this)}
                          atualiza={this.atualizaStatus.bind(this)}
                          status={this.state.divida.status}
                        />
                      ) : null}

                      <AtualizacaoNew
                        open={this.state.atualizaModal}
                        toggle={this.toggleModal.bind(this)}
                        atualiza={this.atualizaDivida.bind(this)}
                      />
                      <Tooltip title="Atualizar Dívida">
                        <Button
                          style={{
                            marginRight: 10,
                            position: 'absolute',
                            right: 0,
                            top: 20,
                            color: '#6b6b52',
                          }}
                          onClick={this.toggleModal.bind(this)}
                        >
                          <Refresh style={{ marginRight: 4 }} />
                        </Button>
                      </Tooltip>
                      {this.state.negociacao.id === '' ? null : (
                        <a
                          href={`http://app.positive.net.br/api/negociacao/memoria_calculo/${
                            this.state.negociacao.id
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Tooltip title="Extrato do Cálculo ">
                            <Button
                              style={{
                                marginRight: 10,
                                position: 'absolute',
                                right: 60,
                                top: 20,
                                color: '#6b6b52',
                              }}
                            >
                              <Assignment style={{ marginRight: 4 }} />
                            </Button>
                          </Tooltip>
                        </a>
                      )}

                      {this.state.negociacao.id ? (
                        <p className="divida-data-atualizacao">
                          Data da Atualização:{' '}
                          {this.toDate(
                            this.state.negociacao.recurso.atualizacao_divida
                              .atualizado_em
                          )}
                        </p>
                      ) : (
                        ''
                      )}
                      <h4 className="divida-title">
                        {this.state.negociacao.id
                          ? 'Valor da Dívida Atualizado'
                          : 'Valor da Dívida'}
                      </h4>
                      <div className="money-container">
                        <img
                          src={require('./img/money.png')}
                          alt="ícone de dinheiro"
                          className="divida-money-icon"
                        />
                        <h3 className="divida-money-value">
                          {this.state.negociacao.id
                            ? this.formatCurrency(
                                this.state.negociacao.recurso.atualizacao_divida
                                  .divida_atualizada
                              )
                            : this.formatCurrency(this.state.divida.valor)}
                        </h3>
                        {this.state.negociacao.id ? (
                          <p className="valororiginal">
                            Valor Original:{' '}
                            {this.formatCurrency(this.state.valordadivida)}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="divida-view-right-container">
                      {this.state.negociacao.id ? (
                        this.state.negociacao.recurso.status === 'pendente' ||
                        this.state.negociacao.recurso.status ===
                          'parcelas_criadas' ? (
                          <Link
                            to={{
                              pathname: `/negociacao`,
                              state: this.state,
                            }}
                          >
                            <Button
                              style={{
                                position: 'absolute',
                                right: 20,
                                top: 70,
                              }}
                              onClick={this.abreNegociacao.bind(this)}
                              disabled={this.state.negociacao.id ? false : true}
                              variant="contained"
                              color="primary"
                            >
                              <Work style={{ marginRight: 5 }} /> Negociar
                            </Button>
                          </Link>
                        ) : null
                      ) : null}

                      {this.state.negociacao.id &&
                        (this.state.negociacao.recurso.status ===
                          'acordo_aceito' ||
                        this.state.negociacao.recurso.status ===
                          'aguardando_aceite' ? (
                          <NegociacaoModal data={this.state.negociacao} />
                        ) : null)}
                      <div
                        style={{ position: 'absolute', right: 20, top: 120 }}
                      >
                        {this.state.negociacao.recurso.status !==
                          'pendente' && (
                          <Sms
                            reset={() => this.resetaNegociacao()}
                            telefone={this.state.devedor.telefone_celular}
                          />
                        )}
                      </div>

                      <p className="divida-devedor-nome">
                        <AssignmentInd style={{ marginBottom: -4 }} />
                        {this.state.devedor.nome_razao_social}
                      </p>
                      <p className="divida-devedor-nome">
                        <Call style={{ marginBottom: -4 }} />
                        {this.state.devedor.telefone_celular} |{' '}
                        {this.state.devedor.telefone_fixo}
                      </p>
                      <div className="divida-devedor-options">
                        {this.state.devedor.id ? (
                          <Fragment>
                            <ModalDevedor
                              devedor={this.state.devedor}
                              id={this.state.devedor.id}
                              endereco={this.state.devedor.endereco}
                              update={this.editaDevedor.bind(this)}
                              open={this.state.modalDevedor}
                              abrir={this.modalDevedor.bind(this)}
                            />
                          </Fragment>
                        ) : null}

                        <Arquivos contratoId={this.state.divida.contrato_id} />
                        <RegrasRemessa
                          clienteId={this.props.match.params.clienteId}
                          remessaId={this.props.match.params.remessaId}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid container spacing={24}>
                  <Grid item lg={12}>
                    {this.state.negociacao.recurso.atualizacao_divida ===
                    undefined ? (
                      <p>
                        Ocorreu um erro, por favor atualize a dívida novamente.
                      </p>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <div style={{ paddingBottom: 0, width: '100%', padding: 10 }}>
                    <h3 className="parcelas-title">
                      <List style={{ marginBottom: -5, fontSize: 30 }} />
                      Parcelas
                    </h3>
                  </div>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                  >
                    {this.state.negociacao.id &&
                    this.state.negociacao.recurso.atualizacao_divida
                      .divida_parcelas !== undefined
                      ? this.state.negociacao.recurso.atualizacao_divida.divida_parcelas.map(
                          parcela => {
                            return (
                              <ParcelaItem
                                key={parcela.id}
                                valorCorrigido={this.formatCurrency(
                                  parcela.memoria_calculo
                                    .valor_atualizado_com_honorarios
                                )}
                                valorOriginal={this.formatCurrency(
                                  parcela.valor_parcela
                                )}
                                dataVencimento={moment(
                                  parcela.vencimento
                                ).format('DD/MM/YYYY')}
                              />
                            )
                          }
                        )
                      : this.state.parcelas.map(parcela => {
                          return (
                            <ParcelaItem
                              key={parcela.id}
                              valorOriginal={this.formatCurrency(parcela.valor)}
                              valorCorrigido={0}
                              dataVencimento={moment(parcela.vencimento).format(
                                'DD/MM/YYYY'
                              )}
                            />
                          )
                        })}
                  </Grid>
                  <div
                    style={{
                      width: '100%',
                      padding: 10,
                      paddingBottom: 0,
                    }}
                  >
                    <h3 className="parcelas-title">
                      <Timeline style={{ marginBottom: -5, fontSize: 30 }} />
                      Histórico
                    </h3>
                  </div>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{ marginTop: 0, paddingTop: 0 }}
                  >
                    <Historico
                      arquivo={this.state.arquivo}
                      historico={this.state.historico}
                      enviar={this.sendHistorico.bind(this)}
                      fileChange={this.handleHistoricoFileChange.bind(this)}
                      change={this.handleHistoricoChange.bind(this)}
                      comunicacoes={this.state.comunicacoes}
                    />
                  </Grid>
                </Grid>
              </Fragment>
            )}
          </main>
        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    paddingLeft: 20,
    paddingRight: 20,
    height: '100vh',
    overflow: 'auto',
  },
})

export default withStyles(styles, { withTheme: true })(DividaView)
