import { GET_DEVEDOR, UPDATE_DEVEDOR } from "./constants";
import moment from "moment";

const initialState = {
  devedor: {
    cpf_cnpj: "",
    nascimento: moment(),
    endereco: {}
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DEVEDOR:
      return { ...state, devedor: action.payload };
    case UPDATE_DEVEDOR:
      return { ...state, cliente: action.payload };
    default:
      return state;
  }
}
