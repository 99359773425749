/*
* Funções de autenticação dos usuários
*/

// Define o nome do token que serå inserido no LocalStorage
export const TOKEN_KEY = "access_token";
export const REFRESH_TOKEN_KEY = "refresh_token";

// Verifica se o usuário está autenticado
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

// Pega o valor do token do LocalStorage
export const getToken = () => localStorage.getItem(TOKEN_KEY);

// Realiza o login. Salva o token do usuário no LocalStorage
export const login = (token, refreshToken) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

// Remove o token do usuário do LocalStorage causando seu logout
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};
