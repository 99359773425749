import React, { Fragment } from "react";

// Services
import api from "../../config/Api";

// Custom Components
import Menu from "../../components/Menu";
import swal from "@sweetalert/with-react";

// Material-UI
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

// Icons
import AccountCircle from "@material-ui/icons/AccountCircle";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

class UsuarioForm extends React.Component {
  state = {
    visiblePassword: false,
    visibleConfirmPassword: false,
    senha: "",
    password_confirmation: "",
    nome: "",
    sobrenome: "",
    email: ""
  };

  async componentDidMount() {
    try {
      let request = await api.get(`/api/v1/auth/me`);
      await this.setState({ ...this.state, ...request.data.me });
    } catch (err) {
      console.warn(err);
    }
  }

  async salvaUsuario() {
    if (this.state.senha && this.state.password_confirmation) {
      try {
        const obj = {
          email: this.state.email,
          nome: this.state.nome,
          sobrenome: this.state.sobrenome,
          senha: this.state.senha
        };
        await api.put(`/api/v1/usuario/${this.state.id}`, obj);
        await console.log(obj);
        swal(
          "Cadastro Atualizado!",
          "Suas informações foram atualizadas!",
          "success"
        );
        await this.props.history.push("/usuarios");
      } catch (err) {
        console.warn(err);
      }
    } else {
      const obj = {
        email: this.state.email,
        nome: this.state.nome,
        sobrenome: this.state.sobrenome
      };
      await api.put(`/api/v1/usuario/${this.state.id}`, obj);
      console.log(obj);
      swal(
        "Cadastro Atualizado!",
        "Suas informações foram atualizadas!",
        "success"
      );
      this.props.history.push("/");
    }
  }

  handleChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div style={{ marginTop: 100 }}>
              <Grid container spacing={24}>
                <Grid item lg={12}>
                  <AppBar position="static">
                    <Toolbar>
                      <IconButton color="inherit" aria-label="Menu">
                        <AccountCircle />
                      </IconButton>
                      <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.grow}
                      >
                        {this.props.match.path === "/novo-usuario"
                          ? "Adicionar Novo Usuário"
                          : "Editar Usuário"}
                      </Typography>
                    </Toolbar>
                  </AppBar>
                </Grid>
              </Grid>
              <Paper style={{ padding: 20 }}>
                <Grid container spacing={24}>
                  <Grid item lg={12} style={{ paddingBottom: 0 }}>
                    <Typography variant="h6" color="inherit">
                      Informações Básicas
                    </Typography>
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      onChange={this.handleChange}
                      value={this.state.email}
                      type="email"
                      label="Email"
                      name="email"
                      placeholder="Insira o email"
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      value={this.state.nome}
                      onChange={this.handleChange}
                      type="text"
                      label="Nome"
                      name="nome"
                      placeholder="Insira o nome"
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <TextField
                      fullWidth
                      value={this.state.sobrenome}
                      onChange={this.handleChange}
                      type="text"
                      label="Sobrenome"
                      name="sobrenome"
                      placeholder="Insira o sobrenome"
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.grow}
                    >
                      Senha
                    </Typography>
                  </Grid>

                  <Grid item lg={4}>
                    <TextField
                      fullWidth
                      type={this.state.visiblePassword ? "text" : "password"}
                      label="Senha"
                      name="senha"
                      value={this.state.senha}
                      onChange={this.handleChange}
                      error={
                        this.state.senha === this.state.password_confirmation ||
                        this.state.password_confirmation === ""
                          ? false
                          : true
                      }
                      helperText={
                        this.state.senha === this.state.password_confirmation ||
                        this.state.password_confirmation === ""
                          ? ""
                          : "As senhas não coincidem"
                      }
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                visiblePassword: !this.state.visiblePassword
                              })
                            }
                            position="end"
                          >
                            {this.state.visiblePassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={4}>
                    <TextField
                      fullWidth
                      type={
                        this.state.visibleConfirmPassword ? "text" : "password"
                      }
                      label="Confirme a Senha"
                      name="password_confirmation"
                      value={this.state.password_confirmation}
                      onChange={this.handleChange}
                      margin="normal"
                      error={
                        this.state.senha === this.state.password_confirmation ||
                        this.state.password_confirmation === ""
                          ? false
                          : true
                      }
                      helperText={
                        this.state.senha === this.state.password_confirmation ||
                        this.state.password_confirmation === ""
                          ? ""
                          : "As senhas não coincidem"
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({
                                visibleConfirmPassword: !this.state
                                  .visibleConfirmPassword
                              })
                            }
                            position="end"
                          >
                            {this.state.visibleConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={12}>
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ marginRight: 6 }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      onClick={this.salvaUsuario.bind(this)}
                      type="submit"
                      color="primary"
                      variant="contained"
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  grow: {
    flexGrow: 1
  }
});

export default withStyles(styles)(UsuarioForm);
