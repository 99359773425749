import { createTextMask } from "redux-form-input-masks";

export const phoneMask = createTextMask({
  pattern: "(99) 9 9999-9999"
});

export const dataMask = createTextMask({
  pattern: "99/99/9999"
});

export const inscricaoEstadualMask = createTextMask({
  pattern: "9999999999-9"
});

export const telefoneFixoMask = createTextMask({
  pattern: "(99) 9999-9999"
});

export const cnpjMask = createTextMask({
  pattern: "99.999.999/9999-99"
});

export const cepMask = createTextMask({
  pattern: "99.999-999"
});

export const cpfMask = createTextMask({
  pattern: "999.999.999-99"
});

export const rgMask = createTextMask({
  pattern: "99.999.999-9"
});
