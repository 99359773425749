import React, { useState } from 'react'
import swal from '@sweetalert/with-react'
import api from '../../config/Api'
import { withRouter } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import CadastroContainer from './CadastroContainer'
import moment from 'moment'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { formatCurrency } from '../../components/Helpers'
import { GeraParcelas, SaveButton } from './styles'
import updateAction from './updateAction'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

// @material-ui/icons
import Input from '@material-ui/icons/Input'
import SettingsBackRestore from '@material-ui/icons/SettingsBackupRestore'
import Save from '@material-ui/icons/Save'

export function StepThree({ history }) {
	const { action, state } = useStateMachine(updateAction)
	const [parcelas, setParcelas] = useState([])
	const [inicio, setInicio] = useState('')
	const [fim, setFim] = useState('')
	const [tipo, setTipo] = useState(false) // 0 - Parcelado  1 - Parcela Unica
	const [valor, setValor] = useState(0)
	const [valorTotal, setValorTotal] = useState(0)

	const handleValue = () => {
		if (tipo) {
			let start = moment(inicio)

			let parcela = {
				vencimento: start.format('YYYY-MM-DD'),
				valor: parseFloat(valor.replace(',', '.').replace(' ', '')),
			}
			setParcelas([parcela])
			console.log(inicio)
			console.log(valor)
			console.log(JSON.stringify(parcelas))
		} else {
			// Pega a data inicial e a data final
			let start = moment(inicio)
			let end = moment(fim)
			// Calcula a quantidade de meses
			//let resultado = end.diff(start, 'months')
			//		let meses = parseInt(resultado) + 1

			console.log(end.diff(start, 'months'))
			console.log(start.format('M'))
			console.log(end.format('M'))
			while (end > start || start.format('M') === end.format('M')) {
				console.log(start.format('M'))
				parcelas.push({
					vencimento: start.format('YYYY-MM-DD'),
					valor: parseFloat(valor.replace(',', '.').replace(' ', '')),
				})
				console.log(start.format('DD-MM-YYYY'))
				if (!tipo) {
					calculaValorPago(parcelas)
				}
				start.add(1, 'month')
			}
		}
		action({ parcelas: parcelas, progress: 80 })
	}

	const limparCampos = () => {
		setInicio('')
		setFim('')
		setValor(0)
		setParcelas([])
		setTipo(false)
		action({ progress: 50 })
	}

	const calculaValorPago = data => {
		const soma = parcelas.reduce((accumulator, currentValue) => {
			let pago = accumulator + parseFloat(currentValue.valor)
			setValorTotal(pago)
			console.log(pago)
			return pago
		}, 0)
	}

	const salvar = async () => {
		try {
			const {
				contrato_id,
				remessa_id,
				pessoa_cliente_id,
				pessoa_devedor_id,
			} = state.data

			const obj = {
				contrato_id,
				remessa_id,
				pessoa_cliente_id,
				pessoa_devedor_id,
				parcelas,
				status: 0,
				valor: tipo ? parcelas[0].valor : valorTotal,
			}

			const request = await api.post(
				`/api/v1/pessoas/clientes/${pessoa_cliente_id}/remessas/${remessa_id}/dividas`,
				obj
			)
			history.push(`/cliente/${pessoa_cliente_id}/remessa/${remessa_id}`)
			swal('Dívida cadastrada!', 'Dívida cadastrada com sucesso!', 'success')
			action({
				contrato_id: '',
				remessa_id: '',
				pessoa_cliente_id: '',
				pessoa_devedor_id: '',
				parcelas: [],
				status: 0,
				valor: 0,
				step: 0,
				progress: 0,
				contratoNumero: '',
				devedor: {
					cpf_cnpj: '',
					telefone_celular: '',
				},
			})
		} catch (error) {
			console.warn(error)
			alert(error.message)
		}
	}

	return (
		<CadastroContainer>
			<Grid container spacing={24}>
				<Grid item lg={6} style={styles.mid}>
					<FormControlLabel
						control={
							<Switch
								disabled={parcelas.length === 0 ? false : true}
								checked={tipo}
								onChange={e => setTipo(e.target.checked)}
								value="1"
							/>
						}
						label="Parcela Única"
					/>
					<Grid item lg={6} style={styles.padding}>
						<TextField
							fullWidth
							disabled={parcelas.length !== 0 ? true : false}
							helperText={tipo ? 'Data' : 'Inicio'}
							type="date"
							value={inicio}
							onChange={e => setInicio(e.target.value)}
						/>
					</Grid>
					{!tipo && (
						<Grid item lg={6} style={styles.padding}>
							<TextField
								fullWidth
								disabled={parcelas.length !== 0 ? true : false}
								helperText="Fim"
								type="date"
								value={fim}
								onChange={e => setFim(e.target.value)}
							/>
						</Grid>
					)}
					<Grid item lg={5} style={styles.padding}>
						<TextField
							disabled={parcelas.length !== 0 ? true : false}
							label="Valor da parcela"
							value={valor}
							onChange={e => setValor(e.target.value)}
						/>
					</Grid>
					{parcelas.length !== 0 && (
						<Grid item lg={12}>
							<GeraParcelas
								variant="contained"
								disabled={(inicio && fim && valor) || tipo ? false : true}
								fullWidth
								onClick={limparCampos}
							>
								<SettingsBackRestore style={styles.iconMargin} /> Limpar Campos
							</GeraParcelas>
						</Grid>
					)}
					{parcelas.length === 0 && (
						<Grid item lg={12}>
							<GeraParcelas
								variant="contained"
								disabled={
									(inicio && fim && valor) || (tipo && valor && inicio)
										? false
										: true
								}
								fullWidth
								onClick={handleValue}
							>
								<Input style={styles.iconMargin} /> Gerar Parcelas
							</GeraParcelas>
						</Grid>
					)}
					{parcelas.length !== 0 && (
						<Grid item lg={12}>
							<SaveButton onClick={salvar} fullWidth>
								<Save style={styles.iconMargin} />
								Salvar
							</SaveButton>
						</Grid>
					)}
				</Grid>
				<Grid item lg={6} style={{ paddingLeft: 10 }}>
					<Table style={styles.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>Vencimento</TableCell>
								<TableCell align="left">Valor</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{parcelas.map(row => (
								<TableRow key={row.vencimento}>
									<TableCell component="th" scope="row">
										{moment(row.vencimento).format('DD/MM/YYYY')}
									</TableCell>
									<TableCell align="left">
										{formatCurrency(row.valor)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Grid>
			</Grid>
		</CadastroContainer>
	)
}

const styles = {
	mid: {
		borderRight: '1px solid grey',
		minHeight: 500,
		height: 'auto',
	},
	padding: {
		paddingBottom: 10,
	},
	table: {
		minWidth: 650,
	},
	iconMargin: {
		marginRight: 10,
	},
}

export default withRouter(StepThree)
