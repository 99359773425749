// @format
import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import axios from 'axios';
import DetalhesAcordo from './DetalhesAcordo';
import AcordoMenu from './AcordoMenu';
import {formatCurrency} from '../../components/Helpers';

class AceitarTermo extends Component {
  state = {
    aceito: false,
    aceito_em: '',
    dados_acesso_cliente: '',
    recurso: this.props.history.location.state.recurso,
    valorPago: 0,
  };

  componentDidMount() {
    console.log(this.props.history.location.state);
    console.log(this.props.history.location.state);
    console.log(this.state);
    this.calculaValorPago();
  }

  handleInputChange = async event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // if (value === true) {
    //   const info = await axios.get(`http://ipinfo.io?token=a857462df0fe21`);
    //   await this.setState({
    //     ...this.state,
    //     [name]: value,
    //     recurso: {
    //       ...this.state.recurso,
    //       status: "acordo_aceito",
    //       termo: {
    //         ...this.state.recurso.termo,
    //         dados_acesso_cliente: info.data,
    //         aceito_em: moment().format("YYYY-MM-DD")
    //       }
    //     }
    //   });
    // } else {
    this.setState({[name]: value});
    // }
  };

  async testefun() {
    const info = await axios.get(`http://ipinfo.io?token=a857462df0fe21`);
    await this.setState({
      ...this.state,
      recurso: {
        ...this.state.recurso,
        status: 'acordo_aceito',
        termo: {
          ...this.state.recurso.termo,
          dados_acesso_cliente: info.data,
          aceito_em: moment().format('YYYY-MM-DD'),
        },
      },
    });
  }

  async handleSubmit() {
    try {
      await this.testefun();
      const envia = await axios.put(
        `/api/v1/negociacoes/${this.state.recurso.id}/aceite`,
        this.state.recurso,
      );

      // console.log("waiting");
      // await console.log(envia.data);
      await this.setState({...this.state, url_entrada: envia.data.fatura});
    } catch (err) {
      console.warn(err);
    }
  }

  async refresh() {
    try {
      const negociacao = await axios.get(
        `/api/v1/negociacoes/${this.state.recurso.id}`,
      );
      this.setState({...this.state, ...negociacao.data});
    } catch (err) {
      console.warn(err);
    }
  }

  async insereTransacao(transactionCode) {
    await this.setState({
      ...this.state,
      recurso: {
        ...this.state.recurso,
        negociacao: {
          ...this.state.recurso.negociacao,
          pagseguro: {
            transacao: transactionCode,
            status: 'a_confirmar',
          },
        },
      },
    });
    await axios.put(`/api/v1/negociacoes/${this.state.recurso.id}/aceite`, {
      recurso: this.state.recurso,
    });
  }

  calculaValorPago() {
    for (
      var i = 0, a = 0;
      i < this.state.recurso.negociacao.parcelas.length;
      i++
    ) {
      if (this.state.recurso.negociacao.parcelas[i].status === 'paid') {
        a = parseFloat(this.state.recurso.negociacao.parcelas[i].valor) + a;
        this.setState({...this.state, valorPago: a});
      }
    }
  }

  render() {
    const {classes} = this.props;
    const {recurso} = this.props.history.location.state;
    return (
      <div className={classes.aceiteWrapper}>
        <AcordoMenu />
        <div style={{width: '80%'}}>
          <DetalhesAcordo
            valorAcordado={recurso.negociacao.valorAcordado}
            qtdParcelas={recurso.negociacao.qtdParcelas}
            valorEntrada={recurso.negociacao.entrada}
            fatura={this.state.url_entrada}
            entrada={this.state.recurso.negociacao.parcelas[0].url}
            acordoStatus={this.state.recurso.status}
            dataEntrada={recurso.negociacao.dataEntrada}
            parcelas={recurso.negociacao.parcelas}
            change={this.handleInputChange}
            aceitar={this.handleSubmit.bind(this)}
            aceitou={this.state.aceito}
            valorPago={formatCurrency(this.state.valorPago)}
            conteudo={recurso.html.termo}
          />
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  aceiteWrapper: {
    width: '100%',
    display: 'flex',
  },
  termo: {
    padding: 15,
  },
});

export default withStyles(styles)(AceitarTermo);
