import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import createBrowserHistory from 'history/createBrowserHistory'
import { Router } from 'react-router-dom'
import { StateMachineProvider, createStore } from 'little-state-machine'

// Importa a Store
import Store from './store/configureStore'

// Redux
import { Provider } from 'react-redux'

// Create Little Machine Store
createStore({
	data: {
		step: 0,
		progress: 0,
		devedor: {
			nome_razao_social: '',
			cpf_cnpj: '',
			telefone_celular: '',
		},
	},
})

const history = createBrowserHistory()
ReactDOM.render(
	<StateMachineProvider>
		<Provider store={Store}>
			<Router history={history}>
				<App />
			</Router>
		</Provider>
	</StateMachineProvider>,
	document.getElementById('root')
)
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
