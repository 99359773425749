import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import AddCircle from '@material-ui/icons/AddCircle'

const styles = theme => ({
  root: {
    width: '100%',
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit,
      width: 'auto',
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
})

function SearchAppBar(props) {
  const { classes } = props
  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        style={{ background: props.color ? props.color : '#4e4e4e' }}
      >
        <Toolbar>
          {props.icon}
          <Typography
            className={classes.title}
            color="inherit"
            variant="h5"
            style={{ marginRight: 10, marginLeft: 10 }}
          >
            {props.title}
          </Typography>
          <Typography className={classes.title} color="inherit">
            {props.remessa}
          </Typography>
          <div className={classes.grow} />
          {props.link ? (
            <Link to={`/${props.link}`} style={{ textDecoration: 'none' }}>
              <IconButton mini style={{ color: '#fff' }}>
                {props.nome}
                <AddCircle />
              </IconButton>
            </Link>
          ) : null}
          {props.redireciona ? (
            <IconButton
              onClick={props.redireciona}
              mini
              style={{ color: '#fff' }}
            >
              {props.nome}
              <AddCircle />
            </IconButton>
          ) : null}
          {props.children}
        </Toolbar>
      </AppBar>
    </div>
  )
}

SearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SearchAppBar)
