// @format
import React from 'react';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import Api from '../../../../../config/Api';

// Material-UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Visibility from '@material-ui/icons/Visibility';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';

// Other Libraries
import MaskedInput from 'react-text-mask';
import {debounce} from 'lodash';

// Custom RECRED components
import Select from '../../../../../components/Select';

// Date Utils
import MomentUtils from '@date-io/moment';
import {DatePicker} from 'material-ui-pickers';
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const sexo = [
  {
    id: 'm',
    name: 'Masculino',
  },
  {
    id: 'f',
    name: 'Feminino',
  },
];

function CPF(props) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function Domestico(props) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function Celular(props) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        ' ',
        /\d/,
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
}))(props => {
  const {children, classes, onClose} = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit,
  },
}))(MuiDialogActions);

class CustomizedDialogDemo extends React.Component {
  state = {
    id: this.props.id,
    endereco: [],
    telefone_fixo: '',
  };

  componentDidMount() {
    this.setState({...this.state, ...this.props.devedor});
  }

  changeCep = e => {
    this.setState({
      ...this.state,
      endereco: {
        ...this.state.endereco,
        cep: e,
      },
    });
    this.handleCep(e);
  };

  handleCep = debounce(text => {
    console.log(text);
    this.setState(
      {
        cep: text,
      },
      () => {
        Api.get(`/api/v1/cep/${text}`).then(resp =>
          this.setState({
            ...this.state,
            endereco: {
              ...this.state.endereco,
              ...resp.data,
            },
          }),
        );
      },
    );
  }, 1000);

  dateChange = (event, a) => {
    this.setState({nascimento: event.format('YYYY-MM-DD')});
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  handleEndereco = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      ...this.state,
      endereco: {
        ...this.state.endereco,
        [name]: value,
      },
    });
  };

  render() {
    return (
      <div>
        <Tooltip title="Informações do Devedor">
          <Button onClick={this.props.abrir}>
            <Visibility />
          </Button>
        </Tooltip>
        <Dialog
          onClose={this.props.abrir}
          aria-labelledby="customized-dialog-title"
          open={this.props.open}
          fullWidth={true}
          maxWidth={`lg`}>
          <DialogTitle id="customized-dialog-title" onClose={this.props.abrir}>
            Informações do Devedor
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={24} style={{padding: 20}}>
              <Grid item lg={12} style={{paddingBottom: 0, marginBottom: 0}} />
              <Grid item lg={4}>
                <TextField
                  name="nome_razao_social"
                  fullWidth
                  onChange={this.handleChange}
                  id="standard-"
                  label="Nome"
                  value={this.state.nome_razao_social}
                  margin="normal"
                />
              </Grid>
              <Grid item lg={2}>
                <FormControl>
                  <InputLabel
                    htmlFor="formatted-text-mask-input"
                    style={{paddingTop: 10, marginTop: 10}}>
                    CPF
                  </InputLabel>
                  <Input
                    fullWidth
                    value={this.state.cpf_cnpj}
                    inputComponent={CPF}
                    margin="none"
                    name="cpf_cnpj"
                    onChange={this.handleChange}
                    style={{paddingTop: 16}}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={2}>
                <TextField
                  fullWidth
                  name="rg_inscricao_estadual"
                  onChange={this.handleChange}
                  id="standard-"
                  label="RG"
                  value={this.state.rg_inscricao_estadual}
                  margin="normal"
                />
              </Grid>
              <Grid item lg={3}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DatePicker
                    keyboard
                    placeholder="Data"
                    format="DD/MM/YYYY"
                    value={this.state.nascimento}
                    onChange={this.dateChange}
                    label="Nascimento"
                    margin="normal"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item lg={3}>
                <TextField
                  fullWidth
                  id="standard-"
                  label="Email"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                  margin="normal"
                />
              </Grid>
              <Grid item lg={3}>
                <Select
                  array={sexo}
                  value={this.state.sexo}
                  name="sexo"
                  margin="normal"
                  label="Sexo"
                  change={this.handleChange}
                />
              </Grid>
              <Grid item lg={3}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="formatted-text-mask-input"
                    style={{paddingTop: 10, marginTop: 10}}>
                    Telefone Celular
                  </InputLabel>
                  <Input
                    fullWidth
                    value={this.state.telefone_celular}
                    margin="none"
                    name="telefone_celular"
                    onChange={this.handleChange}
                    inputComponent={Celular}
                    style={{paddingTop: 16}}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={3}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="formatted-text-mask-input"
                    style={{paddingTop: 10, marginTop: 10}}>
                    Telefone Fixo
                  </InputLabel>
                  <Input
                    fullWidth={true}
                    value={this.state.telefone_fixo}
                    margin="none"
                    name="telefone_fixo"
                    onChange={this.handleChange}
                    inputComponent={Domestico}
                    style={{paddingTop: 16}}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={12}>
                <Typography
                  variant="h2"
                  style={{
                    textAlign: 'left',
                    fontSize: 24,
                    fontWeight: 500,
                    marginBottom: 0,
                    paddingBottom: 0,
                  }}>
                  Endereço
                </Typography>
              </Grid>
              <Grid item lg={3}>
                <TextField
                  fullWidth
                  label="CEP"
                  name="cep"
                  onChange={e => this.changeCep(e.target.value)}
                  value={this.state.endereco.cep}
                  margin="normal"
                />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  fullWidth
                  name="logradouro"
                  label="Rua"
                  onChange={this.handleEndereco}
                  margin="normal"
                  value={this.state.endereco.logradouro}
                />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  fullWidth
                  name="bairro"
                  label="Bairro"
                  margin="normal"
                  onChange={this.handleEndereco}
                  value={this.state.endereco.bairro}
                />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  fullWidth
                  name="numero"
                  onChange={this.handleEndereco}
                  label="Número"
                  margin="normal"
                  value={this.state.endereco.numero}
                />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  fullWidth
                  name="localidade"
                  onChange={this.handleEndereco}
                  label="Cidade"
                  margin="normal"
                  value={this.state.endereco.localidade}
                />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  fullWidth
                  name="estado"
                  label="Estado"
                  onChange={this.handleEndereco}
                  margin="normal"
                  value={this.state.endereco.uf}
                />
              </Grid>
              <Grid item lg={3}>
                <TextField
                  fullWidth
                  name="complemento"
                  onChange={this.handleEndereco}
                  label="Complemento"
                  margin="normal"
                  value={this.state.endereco.complemento}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.props.abrir}>
              Cancelar
            </Button>
            <Button
              onClick={this.props.update.bind(this, this.state)}
              color="primary">
              Atualizar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default CustomizedDialogDemo;
