import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckBoxOutlineBlank from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBox from "@material-ui/icons/CheckBox";

const styles = theme => ({
  listItem: {
    width: "100%",
    height: 64,
    background: "#fff",
    borderRadius: 5,
    marginTop: 20
  },
  listItem2: {
    width: "100%",
    height: 64,
    background: "#63f87c",
    borderRadius: 5,
    marginTop: 20
  }
});

export const DevedorList = props => {
  const { classes } = props;
  return (
    <div
      className={
        props.devedorid === props.listaId ? classes.listItem2 : classes.listItem
      }
    >
      <Grid container spacing={24}>
        <Grid item lg={3} style={{ marginLeft: 20 }}>
          <Typography variant="caption" align="left">
            Nome
          </Typography>
          <Typography variant="body1" gutterBottom align="left">
            {props.nome}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <Typography variant="caption" align="left">
            CPF
          </Typography>
          <Typography variant="body1" gutterBottom align="left">
            {props.cpf}
          </Typography>
        </Grid>
        <Grid item lg={2}>
          <Typography variant="caption" align="left">
            RG
          </Typography>
          <Typography variant="body1" gutterBottom align="left">
            {props.rg}
          </Typography>
        </Grid>

        <Grid item lg={2}>
          <Typography variant="caption" align="left" />
          <Typography variant="body1" gutterBottom align="left" />
        </Grid>
        <Grid item lg={2}>
          <Typography variant="body1" gutterBottom align="right">
            <Button
              mini
              onClick={props.seleciona.bind(this)}
              color="primary"
              aria-label="Add"
              style={{ marginRight: 5 }}
            >
              {props.devedorid === props.listaId ? (
                <CheckBox fontSize="small" />
              ) : (
                <CheckBoxOutlineBlank fontSize="small" />
              )}
              {/* <VisibilityIcon fontSize="small" /> */}
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
export default withStyles(styles)(DevedorList);
