import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

class Acordo extends React.Component {
  state = {
    open: false,
    scroll: 'paper'
  };

  handleClickOpen = scroll => () => {
    this.setState({ open: true, scroll });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Button
          color='primary'
          variant='contained'
          onClick={this.handleClickOpen('paper')}
          style={{ marginRight: 10 }}
        >
          Termo de Acordo
        </Button>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby='scroll-dialog-title'
        >
          <DialogTitle id='scroll-dialog-title'>Termo de Acordo</DialogTitle>
          <DialogContent>
            <div
              dangerouslySetInnerHTML={{
                __html: this.props.termo
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color='primary'>
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Acordo;
