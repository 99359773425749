// @format
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListAlt from '@material-ui/icons/ListAlt';
import Tooltip from '@material-ui/core/Tooltip';
import Api from '../../../../../config/Api';

class AlertDialog extends React.Component {
  state = {
    open: false,
    remessa: {
      limite_desconto_cliente: '',
      limite_desconto_empresa: '',
      limite_parcelamento_cliente: '',
      limite_parcelamento_empresa: '',
    },
  };

  componentDidMount() {
    this.getRemessa();
  }

  async getRemessa() {
    const request = await Api.get(
      `/api/v1/pessoas/clientes/${this.props.clienteId}/remessas/${
        this.props.remessaId
      }`,
    );
    this.setState({...this.state, remessa: request.data.data});
  }

  handleClickOpen = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  render() {
    return (
      <div>
        <Tooltip title="Regras da Remessa">
          <Button onClick={this.handleClickOpen}>
            <ListAlt />
          </Button>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            {'Limites de Desconto e Parcelamento'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Limite de desconto do cliente:{' '}
              {this.state.remessa.limite_desconto_cliente}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Limite de parcelamento do cliente:{' '}
              {this.state.remessa.limite_parcelamento_cliente}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Limite de desconto da RECRED:{' '}
              {this.state.remessa.limite_desconto_empresa}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Limite de parcelamento do RECRED:{' '}
              {this.state.remessa.limite_parcelamento_empresa}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
