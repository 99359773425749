import {
  GET_CLIENTE,
  GET_CLIENTES,
  ADD_CLIENTE,
  UPDATE_CLIENTE,
  GET_CEP
} from "./constants";
import moment from "moment";

const initialState = {
  clientes: [
    {
      nome_razao_social: ""
    }
  ],
  cliente: {
    remessas: [],
nascimento: moment().format("YYYY-MM-DD")
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTE:
      return { ...state, cliente: action.payload };
    case GET_CLIENTES:
      return { ...state, clientes: action.payload };
    case ADD_CLIENTE:
      return { ...state };
    case UPDATE_CLIENTE:
      return { ...state };
    case GET_CEP:
      let endereco = {
        id: state.endereco.id,
        bairro: action.payload.bairro,
        cep: action.payload.cep,
        logradouro: action.payload.logradouro,
        localidade: action.payload.localidade,
        uf: action.payload.uf,
        ibge: action.payload.ibge
      };
      return { ...state, endereco };
    default:
      return state;
  }
}
