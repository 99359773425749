import {
  GET_DIVIDA,
  GET_DIVIDAS,
  ADD_DIVIDA,
  UPDATE_DIVIDA,
  ADD_DEVEDOR,
  GET_DEVEDOR,
  SEARCH_DEVEDOR,
  ADD_ARQUIVO,
  ADD_CONTRATO,
  GET_ARQUIVO,
  GET_CONTRATO,
  GET_ARQUIVOS,
  SEARCH_DEVEDORES,
  GET_DEVEDOR_DIVIDA,
  GET_PARCELAS
} from "./constants";

const initialState = {
  divida: {
    id: null,
    pessoa_devedor_id: null,
    remessa_id: null,
    valor: null,
    criado_por: null,
    criado_em: null,
    atualizado_em: null,
    devedor: {}
  },
  parcelas: [],
  dividas: {
    devedor: {
      data: [{ id: 0 }]
    }
  },
  devedores: [],
  devedor: {
    nome_razao_social: ""
  },
  contrato: {
    data: {
      id: null
    }
  },
  arquivo: {},
  arquivos: {
    data: []
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_DIVIDA:
      return { ...state, divida: action.payload };
    case GET_DIVIDAS:
      return { ...state, dividas: action.payload };
    case ADD_DIVIDA:
      return {
        ...state,
        arquivos: { data: [] },
        contrato: { data: {} }
      };
    case UPDATE_DIVIDA:
      return { ...state, divida: action.payload };
    case ADD_DEVEDOR:
      return { ...state, devedor: action.payload };
    case GET_DEVEDOR:
      return { ...state, devedor: action.payload };
    case GET_DEVEDOR_DIVIDA:
      return { ...state, divida: { devedor: action.payload } };
    case SEARCH_DEVEDOR:
      return { ...state, devedor: action.payload };
    case ADD_CONTRATO:
      return { ...state, contrato: action.payload };
    case ADD_ARQUIVO:
      return { ...state, arquivo: action.payload };
    case GET_ARQUIVO:
      return { ...state, arquivo: action.payload };
    case GET_ARQUIVOS:
      return { ...state, arquivos: action.payload };
    case GET_CONTRATO:
      return { ...state, contrato: action.payload };
    case SEARCH_DEVEDORES:
      return { ...state, devedores: action.payload };
    case GET_PARCELAS:
      return { ...state, parcelas: action.payload };
    default:
      return state;
  }
}
