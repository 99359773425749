// @format
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Menu from './Menu'

const Main = ({ classes, children }) => {
	return (
		<>
			<div className={classes.root}>
				<Menu />
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<div style={{ marginBottom: 101 }}>{children}</div>
				</main>
			</div>
		</>
	)
}

const styles = theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing.unit * 3,
		height: 'calc(100vh-40px)',
		overflow: 'auto',
	},
})

export default withStyles(styles)(Main)
