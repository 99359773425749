import React from "react";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

class ClienteCard extends React.Component {
  state = {
    anchorEl: null
  };
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader
          avatar={
            <Avatar aria-label="Recipe" className={classes.avatar}>
              {this.props.nomeFantasia.charAt(0)}
            </Avatar>
          }
          action={
            <IconButton
              aria-owns={this.state.anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          }
          title={this.props.nomeFantasia}
          subheader={this.props.cnpj}
        />
        <CardContent>
          <Menu
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleClose}
          >
            <Link to={this.props.editar} className={classes.link}>
              <MenuItem onClick={this.handleClose}>Editar Informações</MenuItem>
            </Link>
            <Link
              to={`/visualizar-cliente/${this.props.id}`}
              className={classes.link}
            >
              <MenuItem onClick={this.handleClose}>
                Visualizar Informações
              </MenuItem>
            </Link>
            <Link
              className={classes.link}
              to={`/cliente/${this.props.id}/nova-remessa`}
            >
              <MenuItem onClick={this.handleClose}>Nova Remessa</MenuItem>
            </Link>
          </Menu>
          <div className={classes.cardImgContainer}>
            <img
              src={this.props.avatar}
              className={classes.cardLogo}
              alt=""
            />
          </div>

          <div className={classes.cardValuesContainer}>
            <div>
              <Typography color="textSecondary" align="center">
                {this.props.qtdRemessas}
              </Typography>
              <Typography color="textSecondary">Remessas</Typography>
            </div>
            <div>
              <Typography color="textSecondary" align="center">
                {this.props.qtdDividas}
              </Typography>
              <Typography color="textSecondary">Dívidas</Typography>
            </div>
            <div>
              <Typography color="textSecondary" align="center">
                {this.props.qtdNegociacoes}
              </Typography>
              <Typography color="textSecondary">Negociações</Typography>
            </div>
          </div>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Link to={this.props.visualizar} className={classes.link}>
            <Button size="small">Visualizar</Button>
          </Link>
        </CardActions>
      </Card>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex"
  },
  link: {
    textDecoration: "none",
    "&:focus": {
      background: "none",
      outline: "none"
    }
  },
  cardLogo: {
    width: 100,
    height: 100,
    objectFit: "cover",
    borderRadius: "50%",
    border: "1px solid grey",
    marginBottom: 45
  },
  cardImgContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 0
  },
  cardValuesContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  cardTitle: {
    marginBottom: 20,
    marginTop: 0
  },
  cardActions: {
    display: "flex",
    justifyContent: "center"
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: "100vh",
    overflow: "auto"
  },
  avatar: {
    backgroundColor: red[500]
  }
});

export default withStyles(styles, { withTheme: true })(ClienteCard);
