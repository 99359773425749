import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const styles = theme => ({
  listItem: {
    width: "100%",
    height: 64,
    background: "#fff",
    borderRadius: 5,
    marginTop: 20,
    padding: 15,
    overflow: "hidden;",
    border: "1px solid #e4e4e4"
  }
});

export const DividaList = props => {
  const { classes } = props;
  return (
    <Fragment>
      <Grid container className={classes.listItem}>
        <Grid item lg={4}>
          <Typography variant="caption">Empresa</Typography>
          <Typography variant="body1">{props.nome}</Typography>
        </Grid>
        <Grid item lg={2}>
          <Typography variant="caption">Valor da Dívida</Typography>
          <Typography variant="body1">R$ {props.valor}</Typography>
        </Grid>
        <Grid item lg={2}>
          <Typography variant="caption">Telefone</Typography>
          <Typography variant="body1">{props.telefone}</Typography>
        </Grid>
        <Grid item lg={4} align="right">
          <Link to={`${props.link}`}>
            <Button mini color="primary" aria-label="Add">
              <VisibilityIcon fontSize="small" />
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default withStyles(styles, { withTheme: true })(DividaList);
