// @format
import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "../../../../../components/Select";

const tipos = [
  {
    id: "igpm",
    name: "IGP-M"
  },
  {
    id: "ipca",
    name: "IPCA"
  },
  {
    id: "inpc",
    name: "INPC"
  }
];

export default class FormDialog extends React.Component {
  state = {
    open: false,
    juros: 0,
    multa: 0,
    honorariosCobranca: 0,
    honorariosAdvocaticios: 0,
    indice: "igpm"
  };

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.toggle}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Atualização da dívida
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <InputLabel htmlFor="adornment-amount">Juros</InputLabel>
              <Input
                id="adornment-amount"
                name="juros"
                type="number"
                value={this.state.juros}
                onChange={this.handleChange.bind(this)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <InputLabel htmlFor="adornment-amount">Multa</InputLabel>
              <Input
                id="adornment-amount"
                type="number"
                name="multa"
                value={this.state.multa}
                onChange={this.handleChange.bind(this)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <InputLabel htmlFor="adornment-amount">
                Honorários de Cobrança
              </InputLabel>
              <Input
                id="adornment-amount"
                type="number"
                name="honorariosCobranca"
                value={this.state.honorariosCobranca}
                onChange={this.handleChange.bind(this)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: 10 }}>
              <InputLabel htmlFor="adornment-amount">
                Honorários Advocatícios
              </InputLabel>
              <Input
                id="adornment-amount"
                type="number"
                name="honorariosAdvocaticios"
                value={this.state.honorariosAdvocaticios}
                onChange={this.handleChange.bind(this)}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </FormControl>
            <Select
              array={tipos}
              name="indice"
              change={this.handleChange.bind(this)}
              value={this.state.indice}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.toggle} color="primary">
              Cancelar
            </Button>
            <Button
              onClick={this.props.atualiza.bind(this, this.state)}
              color="primary"
            >
              Atualizar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
