import React, { Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloudDone from '@material-ui/icons/CloudDone'
import CloudDownload from '@material-ui/icons/CloudDownload'
import './dividas.css'
import api from '../../config/Api'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { rota } from '../../config/Api'

class Segundo extends React.Component {
  state = {
    ano: '',
    pessoa_cliente_id: '',
    pessoa_devedor_id: '',
    arquivos: [],
    arquivo: {
      nome: '',
      arquivo: '',
    },
  }

  componentDidMount() {
    //this.getAnexos();
    console.log(this.props.contratoId)
    console.log(this.state)
    console.log('oi')
  }

  salvaAnexo() {
    let formData = new FormData()
    formData.append(`arquivo`, this.state.arquivo.arquivo)
    formData.append(`origem`, 'contrato')
    formData.append(`nome`, this.state.arquivo.nome)
    formData.append(`id_origem`, this.props.contratoId)
    console.log(...formData)
    api.post(`/api/v1/arquivos`, formData).then(resp =>
      api
        .get(
          `/api/v1/arquivos?origem=contrato&id_origem=${this.props.contratoId}`
        )
        .then(resp =>
          this.setState({ ...this.state, arquivos: resp.data.data })
        )
        .then(resp =>
          this.setState({
            ...this.state,
            arquivo: { nome: '', arquivo: '', objectUrl: '' },
          })
        )
    )
  }

  getAnexos() {
    api
      .get(
        `/api/v1/arquivos?origem=contrato&id_origem=${this.props.contratoId}`
      )
      .then(resp => this.setState({ ...this.state, arquivos: resp.data.data }))
  }

  handleFileChange = e => {
    let arquivo = { ...this.state.arquivo }
    arquivo.arquivo = e.target.files[0]
    // Pega o url do arquivo que ainda não foi enviado para que possa ser exibido uma prévia
    arquivo.objectUrl = window.URL.createObjectURL(arquivo.arquivo)
    console.log(e.target.files[0].name)
    console.log(e.target.files[0].type)
    this.setState({ arquivo }, console.log(this.state))
  }

  handleInputChange2(event) {
    const target = event.target
    const value = target.value
    const name = target.name
    const arquivo = this.state.arquivo
    arquivo[name] = value

    this.setState({
      arquivo,
    })
  }

  mostraEstado() {
    console.log(this.state)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }
  render() {
    const { classes } = this.props
    return (
      <div>
        <div className={classes.container}>
          <img src={require('./img/pdf.png')} alt="" />
          {this.props.numero ? (
            <Fragment>
              <h4 className={classes.devedorTitulo}>
                Contrato Nº {this.props.numero}
              </h4>
              <p>Contrato adicionado com sucesso!</p>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                {/* <Grid container spacing={24}> */}
                {/* <Grid item lg={3}> */}

                <label
                  className={
                    this.state.arquivo.arquivo
                      ? 'custom-file-upload2'
                      : 'custom-file-upload'
                  }
                >
                  {this.state.arquivo.arquivo ? (
                    <CloudDone fontSize="large" />
                  ) : (
                    <CloudUploadIcon fontSize="large" />
                  )}
                  <input
                    onChange={this.handleFileChange.bind(this)}
                    type="file"
                    className="arquivo-upload"
                  />
                  <br />
                  {this.state.arquivo.arquivo
                    ? 'Arquivo Selecionado'
                    : 'Selecionar Arquivo'}
                </label>
                {this.state.arquivo.arquivo ? (
                  <p>{this.state.arquivo.arquivo.name}</p>
                ) : null}
                {/* <TextField
                  type="file"
                  name="arquivo"
                  onChange={this.handleFileChange.bind(this)}
                  label="Arquivo"
                  style={{ marginRight: 10 }}
                /> */}
                {/* </Grid> */}
                {/* <Grid item lg={3}> */}
                <TextField
                  style={{ marginRight: 10, marginBottom: 20 }}
                  name="nome"
                  type="text"
                  value={this.state.arquivo.nome}
                  onChange={this.handleInputChange2.bind(this)}
                  fullWidth
                  placeholder="Digite o nome do anexo"
                  label="Nome"
                />
                {/* </Grid> */}
                {/* <Grid item lg={2}> */}
                <Button
                  onClick={this.salvaAnexo.bind(this)}
                  style={{ width: 200 }}
                  variant="contained"
                >
                  Enviar Anexo
                </Button>
                {/* </Grid> */}
                {/* </Grid> */}
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <h4 className={classes.devedorTitulo}>Cadastre o contrato</h4>
              <TextField
                id="ano"
                label="Ano"
                style={{ marginTop: 0 }}
                margin="normal"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.ano}
                name="ano"
              />
              <Button
                type="button"
                onClick={this.props.salvaContrato.bind(this, this.state)}
                variant="contained"
                color="primary"
              >
                Adicionar
              </Button>
            </Fragment>
          )}
        </div>
        <div className="anexos-list" />
        {this.state.arquivos.length !== 0 ? (
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell align="right">Download</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.arquivos.map((arquivo, index) => (
                  <TableRow key={arquivo.id}>
                    <TableCell component="th" scope="row">
                      {arquivo.nome}
                    </TableCell>
                    <TableCell align="right">
                      <a
                        target="_blank"
                        className="remove-sublinha"
                        rel="noopener noreferrer"
                        href={`${rota}/${arquivo.arquivo}`}
                      >
                        <Button
                          variant="contained"
                          color="default"
                          className={classes.button}
                        >
                          Download
                          <CloudDownload style={{ marginLeft: 7 }} />
                        </Button>
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        ) : null}
        {/* <button onClick={this.props.salvaContrato.bind(this, this.state)}>
          Teste
        </button> */}
      </div>
    )
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  devedorTitulo: {
    color: '#575757',
    fontFamily: 'Roboto',
    fontSize: '2.4em',
    fontWeight: 700,
    marginTop: 10,
    marginBottom: 0,
  },
  button: {
    margin: theme.spacing.unit,
  },
  input: {
    display: 'none',
  },
})

export default withStyles(styles)(Segundo)
