import React, {Fragment} from 'react';
import api from '../../config/Api';
import Bar from '../../components/Appbar';
// import { uniqueId } from "lodash";
import Menu from '../../components/Menu';
import ClienteCard from '../../components/ClienteCard';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Redux
import {connect} from 'react-redux';
import {getClientes} from './actions';
import {bindActionCreators} from 'redux';

// Router
import {withRouter} from 'react-router-dom';

import {withStyles} from '@material-ui/core/styles';

class Clientes extends React.Component {
  state = {
    clientes: [],
    loading: true,
  };
  componentDidMount() {
    this.getClientes();
  }

  async getClientes() {
    try {
      const request = await api.get(`/api/v1/pessoas/clientes`);
      this.setState({
        ...this.state,
        clientes: request.data.data,
        loading: false,
      });
    } catch (e) {
      return e;
    }
  }

  render() {
    const {classes} = this.props;
    const {clientes, loading} = this.state;
    return (
      <Fragment>
        <div className={classes.root}>
          <Menu />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Bar title="Clientes" nome="Novo Cliente" link="novo-cliente" />
            {loading && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 500,
                  fontFamily: 'Roboto',
                }}>
                <CircularProgress />
                <p>Carregando</p>
              </div>
            )}
            <Grid container spacing={24}>
              {clientes.map((cliente, index) => (
                <Grid
                  item
                  lg={3}
                  sm={3}
                  md={3}
                  xl={2}
                  style={{marginTop: 40}}
                  key={index}>
                  <ClienteCard
                    nomeFantasia={cliente.nome_razao_social}
                    qtdRemessas={cliente.remessas}
                    qtdDividas={cliente.dividas}
                    qtdNegociacoes="0"
                    avatar={
                      cliente.avatar ? cliente.avatar : require(`./faao.png`)
                    }
                    editar={`/editar-cliente/${cliente.id}`}
                    visualizar={`/cliente/${cliente.id}`}
                  />
                </Grid>
              ))}
            </Grid>
          </main>
        </div>
      </Fragment>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  cardLogo: {
    width: 100,
    height: 100,
    objectFit: 'contain',
    borderRadius: '50%',
    border: '1px solid grey',
    marginBottom: 45,
  },
  cardImgContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 0,
  },
  cardValuesContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardTitle: {
    marginBottom: 20,
    marginTop: 0,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    height: '100vh',
    overflow: 'auto',
  },
});

export default withRouter(withStyles(styles, {withTheme: true})(Clientes));
