// @format
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {uniqueId} from 'lodash';
import styled from 'styled-components';
import {formatCurrency} from '../../components/Helpers';
import {
  AceitarHeader,
  Paper,
  DividaInfo,
  Valor,
  ValorContainer,
  ValorDescription,
} from './styles';
import Modal from './Modal';

const Text = styled.p`
  font-size: 1em;
  text-align: left;
  margin: 0;
`;

const AcordoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  font-family: 'Roboto', sans-serif;
  border-radius: 5px;
  background: #fff;
  padding-bottom: 60px;
`;

const Declaro = styled.section`
  display: flex;
  margin-top: 30px;
`;

const checaTipo = tipo => {
  if (tipo === 'E') {
    return 'Entrada';
  } else {
    return 'Parcela';
  }
};

export const DetalhesAcordo = props => {
  return (
    <AcordoContainer>
      <AceitarHeader />
      <DividaInfo>
        <div style={{alignSelf: 'flex-start'}}>
          <Modal conteudo={props.conteudo} />
        </div>
        <div style={{display: 'flex'}}>
          <ValorContainer>
            <ValorDescription>Valor Acordado</ValorDescription>
            <Valor>{formatCurrency(props.valorAcordado)} </Valor>
          </ValorContainer>
          <ValorContainer>
            <ValorDescription>Valor Pago</ValorDescription>
            <Valor>{props.valorPago}</Valor>
          </ValorContainer>
        </div>
      </DividaInfo>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Valor</TableCell>
              <TableCell align="left">Vencimento</TableCell>
              <TableCell align="left">Tipo</TableCell>
              <TableCell align="left">Ação</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.parcelas.map(parcela => {
              return (
                <TableRow key={uniqueId()}>
                  <TableCell component="th" scope="row">
                    {formatCurrency(parcela.valor)}
                  </TableCell>
                  <TableCell align="left">
                    {moment(parcela.vencimento).format('DD/MM/YYYY')}
                  </TableCell>
                  <TableCell align="left">{checaTipo(parcela.tipo)}</TableCell>
                  <TableCell align="left">
                    {parcela.url ? (
                      parcela.status === 'paid' ? (
                        <Button
                          size="small"
                          disabled={parcela.status === 'paid' ? true : false}
                          variant="contained"
                          color="primary">
                          Pago
                        </Button>
                      ) : (
                        <a
                          href={parcela.url}
                          style={{textDecoration: 'none'}}
                          target="_blank"
                          disabled
                          rel="noopener noreferrer">
                          <Button
                            size="small"
                            disabled={parcela.status === 'paid' ? true : false}
                            variant="contained"
                            color="primary">
                            Pagar
                          </Button>
                        </a>
                      )
                    ) : (
                      parcela.status
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          {props.acordoStatus === 'acordo_aceito' ? null : (
            <Fragment>
              <Declaro>
                <input type="checkbox" name="aceito" onClick={props.change} />
                <Text>
                  Declaro que li e aceito os termos do acordo e estou ciente de
                  suas implicações legais.
                </Text>
              </Declaro>
            </Fragment>
          )}
        </div>
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>
          {props.acordoStatus === 'acordo_aceito' ? null : (
            <Button
              disabled={props.aceitou === false ? true : false}
              color="primary"
              variant="contained"
              onClick={props.aceitar}>
              Aceitar Acordo
            </Button>
          )}

          {props.fatura || props.entrada ? (
            props.parcelas[0].status === 'paid' ? null : (
              <a
                href={props.fatura ? props.fatura : props.entrada}
                target="_blank"
                style={{textDecoration: 'none'}}
                rel="noopener noreferrer">
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  style={{marginTop: 20, backgroundColor: '#228B22'}}>
                  Pagar Entrada
                </Button>
              </a>
            )
          ) : null}
        </div>
      </Paper>
    </AcordoContainer>
  );
};

DetalhesAcordo.propTypes = {
  valorAcordado: PropTypes.string,
  qtdParcelas: PropTypes.string,
  valorEntrada: PropTypes.string,
  dataEntrada: PropTypes.string,
  parcelas: PropTypes.array,
  change: PropTypes.func,
  aceitar: PropTypes.func,
};

export default DetalhesAcordo;
