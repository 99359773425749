import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import ClientesReducer from "../pages/Clientes/reducers";
import RemessasReducer from "../pages/Clientes/Remessas/reducers";
import DividasReducer from "../pages/Clientes/Remessas/Debitos/reducers";
import DevedorReducer from "../pages/Devedores/reducers";
import { ADD_CLIENTE, UPDATE_CLIENTE } from "../pages/Clientes/constants";
import {
  ADD_REMESSA,
  UPDATE_REMESSA
} from "../pages/Clientes/Remessas/constants";
import {
  ADD_DIVIDA,
  GET_CONTRATO
} from "../pages/Clientes/Remessas/Debitos/constants";

const rootReducer = combineReducers({
  form: formReducer.plugin({
    debitosForm: (state, action) => {
      switch (action.type) {
        case ADD_DIVIDA:
          return undefined;
        case GET_CONTRATO:
          return undefined;
        default:
          return state;
      }
    },
    clienteForm: (state, action) => {
      switch (action.type) {
        case ADD_CLIENTE:
          return undefined;
        case UPDATE_CLIENTE:
          return undefined;
        default:
          return state;
      }
    },
    RemessaForm: (state, action) => {
      switch (action.type) {
        case ADD_REMESSA:
          return undefined;
        case UPDATE_REMESSA:
          return undefined;
        default:
          return state;
      }
    }
  }),
  clientes: ClientesReducer,
  remessas: RemessasReducer,
  dividas: DividasReducer,
  devedores: DevedorReducer
});

export default rootReducer;
